import { Text, Title } from '@repo/foundations';
import { useMemo } from 'react';
import { queryClient, useIntegrationList } from '../../api';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { Group, Stack } from '@mantine/core';

import type { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';

import { showNotification } from '@mantine/notifications';
import {
	apiQueryKey,
	useCancelAccessRequest,
	type DataAccessRequestOut,
} from '@repo/api-codegen';
import MultiSelectorTarget from '@repo/common/components/MultiSelector/MultiSelectorTarget';
import { EntityType } from '@repo/common/enums/entityType';
import { UserRole } from '@repo/common/enums/UserRole';
import { DateRenderer } from '../../components/CatalogView/TableView/DateRenderer';
import { DataAccessRequestApproveModal } from '../../components/DataAccessRequest/DataAccessRequestApproveModal/DataAccessRequestApproveModal';
import { DataAccessRequestIcon } from '../../components/DataAccessRequest/DataAccessRequestIcon/DataAccessRequestIcon';
import { DataAccessRequestRejectModal } from '../../components/DataAccessRequest/DataAccessRequestRejectModal/DataAccessRequestRejectModal';
import { DataAccessRequestStatusBadge } from '../../components/DataAccessRequest/DataAccessRequestStatusBadge/DataAccessRequestStatusBadge';
import { ErrorLogs } from '../../components/ErrorLogs/ErrorLogs';
import { closeAllModals, openModal } from '../../components/ModalManager';
import { SecodaEntityIcon } from '../../components/SecodaEntity';
import { closeSpotlight } from '../../components/Spotlight';
import {
	CreatedAtRender,
	LastUpdatedRender,
} from '../../components/TableV2/render';
import { useCheckIAMPermission } from '../../utils/authorization/roles';

export function useColumns(): ExtendedDataTableColumn<DataAccessRequestOut>[] {
	const { data: integrations } = useIntegrationList({});

	const columns = useMemo(
		() => [
			{
				accessor: 'created_by',
				title: 'Request',
				render: (record: DataAccessRequestOut) => {
					const integration = integrations?.results.find(
						(item) => item.id === record.integration_id
					);

					return (
						<Group role="link" spacing="sm" noWrap>
							<DataAccessRequestIcon
								user={record.created_by}
								status={record.status}
							/>
							<Stack spacing={0}>
								<Title size="sm" truncate>
									{integration?.name} access
								</Title>
								<Text size="xs" color="text/secondary/default">
									{record.created_by?.display_name} ·{' '}
									<DateRenderer
										value={record.created_at}
										relative
										color="text/secondary/default"
										size="xs"
										span
									/>
								</Text>
							</Stack>
						</Group>
					);
				},
			},
			{
				accessor: 'requested_resources',
				title: 'Resources',
				render: (record: DataAccessRequestOut) => (
					<MultiSelectorTarget
						selected={(record.requested_resources ?? [])?.map((resource) => ({
							label: resource.title || 'Untitled',
							value: resource.id,
							icon: (
								<SecodaEntityIcon
									entity={{
										...resource,
										integration: resource.integration_id ?? undefined,
										entity_type: resource.entity_type as EntityType,
										icon: resource.icon || undefined,
									}}
									size={20}
								/>
							),
						}))}
						iconType="react-node"
						property="entity"
						propertyLabel="resources"
						isMenuItemBadge={false}
						isViewerUser={false}
						permittedId={''}
						readOnly
						hideOnEmpty
					/>
				),
			},
			{
				accessor: 'request_text',
				title: 'Request comment',
				render: (record: DataAccessRequestOut) => (
					<Text
						size="sm"
						color="text/primary/default"
						style={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							maxWidth: '300px',
						}}
					>
						{record.requested_text || ''}
					</Text>
				),
			},
			{
				accessor: 'approved_text',
				title: 'Approval comment',
				render: (record: DataAccessRequestOut) => (
					<Text
						size="sm"
						color="text/primary/default"
						style={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							maxWidth: '300px',
						}}
					>
						{record.approved_text || ''}
					</Text>
				),
			},
			{
				accessor: 'rejected_text',
				title: 'Rejection comment',
				render: (record: DataAccessRequestOut) => (
					<Text
						size="sm"
						color="text/primary/default"
						style={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							maxWidth: '300px',
						}}
					>
						{record.rejected_text || ''}
					</Text>
				),
			},
			{
				accessor: 'duration',
				title: 'Duration',
				render: (record: DataAccessRequestOut) =>
					record.requested_expires_at || record.approved_expires_at ? (
						<DateRenderer
							value={
								record.approved_expires_at ??
								record.requested_expires_at ??
								undefined
							}
							color="text/primary/default"
							size="xs"
							span
						/>
					) : (
						<Text size="sm">Indefinite</Text>
					),
			},
			{
				accessor: 'status',
				title: 'Status',
				render: (record: DataAccessRequestOut) => (
					<DataAccessRequestStatusBadge status={record.status} />
				),
			},
			{
				accessor: 'created_at',
				title: 'Created at',
				render: (record: DataAccessRequestOut) => (
					<CreatedAtRender record={record} />
				),
			},
			{
				accessor: 'updated_at',
				title: 'Last updated',
				render: (record) => (
					<LastUpdatedRender record={record} field={'updated_at'} />
				),
			},
		],
		[integrations]
	);

	return columns;
}

export function useActions(): ICommandListItem<DataAccessRequestOut>[] {
	const canManageRequests = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN],
		v2Permission: 'Workspace.Manage',
	});

	const cancelAccessRequest = useCancelAccessRequest();

	const actions = useMemo(
		() => [
			{
				id: 'actions::cancel',
				title: 'Cancel',
				name: 'Cancel',
				iconName: 'x' as const,
				hotkey: '/cn',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: (selected: DataAccessRequestOut[]) =>
					selected.length === 1 && selected[0].status === 'approved',
				onClick: async (selected: DataAccessRequestOut[]) => {
					if (selected.length === 1) {
						try {
							await cancelAccessRequest.mutateAsync({
								pathParams: { requestId: selected[0].id },
								body: {
									cancelled_text: 'Cancelled by user',
								},
							});
							showNotification({
								title: 'Success',
								message: 'Successfully cancelled access request(s)',
								color: 'green',
							});
						} catch (error) {
							openModal({
								title: 'Data access request error',
								size: 'sm',
								children: (
									<ErrorLogs
										title="Error cancelling request"
										logs={[(error as { detail: string }).detail]}
									/>
								),
							});
						}
						queryClient.invalidateQueries(
							apiQueryKey('integration/data-access-requests/list')
						);
					}
				},
			},
			{
				id: 'actions::approve',
				title: 'Approve access',
				name: 'Approve access',
				iconName: null,
				hotkey: '/ap',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: (selected: DataAccessRequestOut[]) =>
					selected.length === 1 && selected[0].status === 'pending',
				onClick: async (selected: DataAccessRequestOut[]) => {
					if (selected.length === 1) {
						closeSpotlight('bulkActions');
						openModal({
							title: 'Approve access request',
							size: 480,
							children: (
								<DataAccessRequestApproveModal
									request={selected[0]}
									onClose={closeAllModals}
								/>
							),
						});
					}
				},
			},
			{
				id: 'actions::reject',
				title: 'Deny access',
				name: 'Deny access',
				iconName: null,
				hotkey: '/rj',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: (selected: DataAccessRequestOut[]) =>
					selected.length === 1 && selected[0].status === 'pending',
				onClick: async (selected: DataAccessRequestOut[]) => {
					if (selected.length === 1) {
						closeSpotlight('bulkActions');
						openModal({
							title: 'Reject access request',
							size: 480,
							children: (
								<DataAccessRequestRejectModal
									request={selected[0]}
									onClose={closeAllModals}
								/>
							),
						});
					}
				},
			},
		],
		[cancelAccessRequest]
	);

	return canManageRequests ? actions : [];
}
