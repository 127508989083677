import type { Node } from 'prosemirror-model';
import { useMemo } from 'react';
import { useMetricList, useSearch } from '../../../../../api';
import { buildResourceUrl } from '../../../../../utils/navigationUtils';
import { getQueryBlocks } from '../lib/getQueryBlocks';
import type { CreateGraphModalProps } from './ChartBlock/CreateGraphModal';
import { CreateGraphModal } from './ChartBlock/CreateGraphModal';

export interface CreateGraphModalWrapperProps
	extends Pick<CreateGraphModalProps, 'onClose' | 'onSave'> {
	doc: Node;
}

export function CreateGraphModalWrapper({
	onClose,
	onSave,
	doc,
}: CreateGraphModalWrapperProps) {
	const queryBlocks = getQueryBlocks(doc);
	const metricsIds = queryBlocks.map((qb) => qb.node.attrs.id);

	const { data } = useMetricList({
		filters: {
			hidden: false,
		},
	});

	const parentIds = (data?.results ?? [])
		.filter((m) => !!m.parent)
		.map((m) => m.parent) as string[];

	const { data: parentData } = useSearch({
		filterV2: {
			operator: 'in',
			operands: [],
			field: 'id',
			value: parentIds,
		},
		options: {
			enabled: !!parentIds.length,
		},
	});

	const metrics = useMemo(
		() =>
			(data?.results ?? []).map((metric) => {
				const parent = parentData?.results?.find((p) => p.id === metric.parent);
				return {
					...metric,
					inDoc: metricsIds.includes(metric.id),
					parentUrl: parent ? buildResourceUrl(parent) : undefined,
				};
			}),
		[data?.results, metricsIds, parentData?.results]
	);

	return (
		<CreateGraphModal metrics={metrics} onClose={onClose} onSave={onSave} />
	);
}
