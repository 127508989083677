import { Box, Stack, createStyles } from '@mantine/core';
import { useMemo } from 'react';
import type { IColumn } from '../../../../api';
import type {
	FetchModelInfiniteListHook,
	FetchModelList,
} from '../../../../api/factories/types';
import {
	fetchCatalogList,
	useCatalogInfiniteList,
} from '../../../../api/hooks/resourceCatalog';

import { TableV2 } from '../../../../components/TableV2';
import {
	CapitalizeTextRender,
	ColumnProfileRender,
} from '../../../../components/TableV2/render';
import { useGenericColumns } from '../../../../components/TableV2/SecodaEntity.hooks';
import type {
	ExtendedDataTableColumn,
	Render,
} from '../../../../components/TableV2/types';

import { EntityType } from '@repo/common/enums/entityType';
import { FilterOptionType } from '../../../../components/Filter';
import { useCatalogFilter } from '../../../../components/Filter/useCatalogFilter';
import { useActions } from './ChartsTab.hooks';

export function useColumns(): ExtendedDataTableColumn<IColumn>[] {
	const genericColumns = useGenericColumns({});

	const columns = useMemo(
		() => [
			{
				accessor: 'type',
				title: 'Type',
				width: 160,
				render: (record: IColumn) => (
					<CapitalizeTextRender<IColumn>
						record={record}
						field={'type'}
						field2={'native_type'}
					/>
				),
				filterOptionType: FilterOptionType.NATIVE_TYPE,
			},
			...genericColumns,
			{
				accessor: 'properties._profile.ntiles',
				title: 'Distribution',
				sortable: false,
				filtering: false,
				explicit: true,
				width: 160,
				render: ColumnProfileRender as unknown as Render<IColumn>,
			},
		],
		[genericColumns]
	);

	return columns;
}

const useStyles = createStyles(() => ({
	gridWrapper: {
		width: '100%',
		minHeight: 350,
		flexGrow: 1,
	},
	tableWrapper: {
		height: '100%',
	},
}));

interface ChartsTabProps {
	id: string;
}

const COLUMN_VISIBILITY = {
	catalogType: EntityType.chart,
	catalogServerType: EntityType.chart,
} as const;

const DEFAULT_SEARCH_PARAMS = {
	entity_type: EntityType.chart,
	calculate_children_count: true,
} as const;

const DEFAULT_SEARCH_PARAMS_NESTING = {
	entity_type: EntityType.chart,
	calculate_children_count: true,
} as const;

function ChartsTab({ id }: ChartsTabProps) {
	const columns = useColumns();
	const actions = useActions();

	const { classes } = useStyles();

	const defaultCatalogFilters = useMemo(
		() => ({
			operands: [
				{
					operands: [],
					field: 'parent_id' as const,
					operator: 'exact' as const,
					value: id,
				},
			],
		}),
		[id]
	);

	const filterOptions = useCatalogFilter();

	return (
		<Stack className={classes.gridWrapper}>
			<Box className={classes.tableWrapper}>
				<TableV2<IColumn>
					key={columns.length}
					withSearch
					withInteractiveHeader
					withCsvExport
					withServerCsvExport
					withCheckbox
					withActions={actions}
					columns={columns}
					withInfiniteScroll
					withFilterOptions={filterOptions}
					usePaginationList={
						useCatalogInfiniteList as FetchModelInfiniteListHook<IColumn>
					}
					fetchPaginationList={
						fetchCatalogList as unknown as FetchModelList<IColumn>
					}
					columnVisibility={COLUMN_VISIBILITY}
					defaultRequiredSearchParams={DEFAULT_SEARCH_PARAMS}
					defaultRequiredSearchParamsNesting={DEFAULT_SEARCH_PARAMS_NESTING}
					defaultRequiredCatalogFilters={defaultCatalogFilters}
					nestingFilter="parent_id"
				/>
			</Box>
		</Stack>
	);
}

export default ChartsTab;
