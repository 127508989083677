import { Anchor, createStyles, Divider, Radio, Space } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
	useGetAiCustomModel,
	useSetAiCustomModel,
	useWorkspaceAiFeedback,
} from '@repo/api-codegen';
import MarkdownRenderer from '@repo/common/components/MarkdownRenderer';
import {
	getToolDescription,
	getToolName,
} from '@repo/common/constants/aiTools';
import { Button, Text, TextArea, Title } from '@repo/foundations';
import { capitalize, toLower } from 'lodash-es';
import { useCallback, useState } from 'react';
import {
	useAgentTools,
	useAIPrivacySettings,
	useAuthUser,
	useDisableAgentTool,
	useEnableAgentTool,
	useGetAgentInstructions,
	useGetCustomDescriptionStyle,
	useToggleAIPrivacySetting,
	useUpdateAgentInstructions,
	useUpdateCustomDescriptionStyle,
} from '../../api';
import { useAIMemory } from '../../api/hooks/ai/useAIMemory';
import { useUpdateWorkspace } from '../../api/hooks/workspace/useUpdateWorkspace';
import type { IAgentTool } from '../../api/types';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import { useFeatureFlags } from '../../utils/featureFlags';
import { sentenceCase } from '../../utils/stringUtils';
import { EmptyState } from '../EmptyState';
import { openModal } from '../ModalManager';
import { AIPersonas } from './AIPersonas/AIPersonas';
import { AIGovernanceFilters } from './GovernanceFilters/AIGovernanceFilters';
import { UpgradeButton } from './UpgradeButton';
import {
	SettingsAction,
	SettingsHeader,
	SettingsSwitch,
} from './WorkspaceSettings';

function ToolsSettings({ aiMemory }: { aiMemory: boolean }) {
	const { data: tools } = useAgentTools();
	const { mutateAsync: enableAgentTool } = useEnableAgentTool();
	const { mutateAsync: disableAgentTool } = useDisableAgentTool();
	const memoryTools = {
		toolNames: ['add_memory', 'delete_memory'],
		tools: [] as IAgentTool[],
		uiName: 'add_memory',
	};
	const docsTools = {
		toolNames: ['get_secoda_docs'],
		tools: [] as IAgentTool[],
		uiName: 'get_secoda_docs',
	};
	const sqlTools = {
		toolNames: ['run_sql'],
		tools: [] as IAgentTool[],
		uiName: 'run_sql',
	};

	tools?.tools.map((tool) => {
		if (memoryTools.toolNames.includes(tool.name)) {
			memoryTools.tools.push(tool);
		}

		if (docsTools.toolNames.includes(tool.name)) {
			docsTools.tools.push(tool);
		}

		if (sqlTools.toolNames.includes(tool.name)) {
			sqlTools.tools.push(tool);
		}
	});

	const renderTool = (config: {
		tools: IAgentTool[];
		uiName: string;
		toolNames: string[];
	}) => {
		if (config.uiName === memoryTools.uiName && !aiMemory) return null;
		const tool = config.tools.find((tool) =>
			config.toolNames.includes(tool.name)
		);
		if (!tool) return null;

		return (
			<SettingsSwitch
				my="md"
				disabled={false}
				key={tool.name}
				title={getToolName(
					tool.name,
					capitalize(toLower(sentenceCase(tool.name)))
				)}
				checked={tool.enabled}
				description={getToolDescription(tool.name, tool.frontend_description)}
				onChange={() => {
					const action = !tool.enabled ? enableAgentTool : disableAgentTool;
					// Toggle UI first to avoid delay
					action({ tool });
					config.tools.forEach((toggle) => {
						if (toggle.name !== tool.name) {
							action({ tool: toggle });
						}
					});
				}}
			/>
		);
	};

	return (
		<>
			{[sqlTools, docsTools, memoryTools].map((config) => renderTool(config))}
		</>
	);
}

const useStyles = createStyles((theme) => ({
	textBox: {
		'& textarea': {
			resize: 'vertical',
		},
	},
	saveButton: {
		marginTop: theme.spacing.md,
		marginBottom: theme.spacing.md,
		display: 'block',
		marginLeft: 'auto',
	},
}));

function CustomInstructionsModal() {
	const { data: remoteInstructions } = useGetAgentInstructions();
	const { data: remoteDescriptionStyle } = useGetCustomDescriptionStyle();
	const { mutateAsync: updateAgentInstructions } = useUpdateAgentInstructions();
	const { mutateAsync: updateCustomDescriptionStyle } =
		useUpdateCustomDescriptionStyle();

	const [instructions, setInstructions] = useState<string>(
		remoteInstructions ?? ''
	);
	const [descriptionStyle, setDescriptionStyle] = useState<string>(
		remoteDescriptionStyle ?? ''
	);

	const { classes } = useStyles();

	const notifySuccessOnSave = useCallback((type: string) => {
		showNotification({
			title: `${type} saved`,
			message: '',
			autoClose: 1500,
		});
	}, []);

	const notifyErrorOnSave = useCallback(() => {
		showNotification({
			title: 'Something went wrong',
			message: 'Please enter the instructions again',
			autoClose: 1500,
		});
	}, []);

	return (
		<>
			<Title size="sm">Custom chat instructions</Title>
			<Text size="sm" mb="sm">
				Provide custom instructions to Secoda AI. These instructions will be
				injected into the prompt.
			</Text>
			<TextArea
				minRows={3}
				className={classes.textBox}
				value={instructions}
				onChange={(e) => setInstructions(e.target.value)}
			/>
			<Button
				onClick={async () => {
					try {
						await updateAgentInstructions({ instructions });
						notifySuccessOnSave('Secoda AI custom instructions');
					} catch {
						notifyErrorOnSave();
					}
				}}
				className={classes.saveButton}
			>
				Save instructions
			</Button>
			<Divider mb="lg" />
			<Title size="sm">Custom description instructions</Title>
			<Text size="sm" mb="sm">
				Custom instructions for Secoda AI when generating descriptions for your
				resources.
			</Text>
			<TextArea
				minRows={3}
				className={classes.textBox}
				value={descriptionStyle}
				onChange={(e) => setDescriptionStyle(e.target.value)}
			/>
			<Button
				onClick={async () => {
					try {
						await updateCustomDescriptionStyle({
							instructions: descriptionStyle,
						});
						notifySuccessOnSave('Secoda AI custom description instructions');
					} catch {
						notifyErrorOnSave();
					}
				}}
				className={classes.saveButton}
			>
				Save description style
			</Button>
		</>
	);
}

export function AIToolsSettings() {
	const { workspace } = useAuthUser();
	const { aiPersonas, allowXaiLlm, aiMemory } = useFeatureFlags();
	const { data: privacySettings } = useAIPrivacySettings();
	const { mutateAsync: toggleAIPrivacySetting } = useToggleAIPrivacySetting();
	const { mutateAsync: updateWorkspace } = useUpdateWorkspace(workspace.id);
	const { data: aiFeedback } = useWorkspaceAiFeedback({});
	const { data: customModel, refetch: refetchModel } = useGetAiCustomModel({});
	const { needsUpgrade, enableAi, isLoading } = useAiEnabled();
	const { mutateAsync: setCustomModel } = useSetAiCustomModel({
		onSuccess: () => {
			refetchModel();
		},
	});

	const { showMemoriesModal } = useAIMemory();

	const notifySuccessOnSave = useCallback((type: string) => {
		showNotification({
			title: `${type} saved`,
			message: '',
			autoClose: 1500,
		});
	}, []);

	const notifyErrorOnSave = useCallback(() => {
		showNotification({
			title: 'Something went wrong',
			message: 'Please enter the instructions again',
			autoClose: 1500,
		});
	}, []);

	const handleModelChange = useCallback(
		async (value: string) => {
			try {
				await setCustomModel({
					body: {
						model: value,
					},
				});
				notifySuccessOnSave('AI Model');
			} catch {
				notifyErrorOnSave();
			}
		},
		[setCustomModel, notifySuccessOnSave, notifyErrorOnSave]
	);

	const showCustomInstructionsModal = () => {
		const id = 'custom-instructions-modal';
		openModal({
			title: 'Custom instructions',
			id,
			children: <CustomInstructionsModal />,
		});
	};

	if (isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	if (needsUpgrade) {
		return (
			<EmptyState
				title="Upgrade to access Secoda AI"
				description="Use Secoda AI to easily access all your company data and find answers to common questions."
				illustrationName="upgrade"
				includeGoBack={false}
				stateHeight="50vh"
				size="lg"
				withActions={<UpgradeButton feature="Secoda AI" size="md" />}
			/>
		);
	}

	return (
		<div>
			<Space h="lg" />
			<Text size="lg" weight="bold">
				General
			</Text>
			<SettingsSwitch
				onChange={(e) =>
					updateWorkspace({
						data: { id: workspace.id, ai_search: e.target.checked },
					})
				}
				checked={workspace?.ai_search ?? false}
				title="Use Secoda AI"
				description={
					<>
						Use Secoda&apos;s Secoda AI and Ask AI. By enabling Secoda AI you
						agree to our{' '}
						<Anchor
							href="https://docs.secoda.co/policies/privacy-policy"
							target="_blank"
						>
							Privacy Policy
						</Anchor>{' '}
						and our usage of{' '}
						<Anchor
							href="https://docs.secoda.co/features/ai-assistant/security"
							target="_blank"
						>
							Open AI
						</Anchor>
						.
					</>
				}
			/>

			{enableAi && (
				<>
					<Divider my="lg" />
					<AIGovernanceFilters />
					<Divider my="lg" />
					<SettingsSwitch
						disabled={false}
						key={'ai_mask_pii'}
						title={'Mask PII content'}
						checked={privacySettings?.mask_pii_on_response_generation || false}
						description={
							'When querying resources, try to mask data marked as PII before being processed by Secoda AI.'
						}
						onChange={() => {
							const val = !privacySettings?.mask_pii_on_response_generation;
							toggleAIPrivacySetting({
								key: 'mask_pii_on_response_generation',
								value: val,
							});
						}}
					/>
					<Divider my="lg" />
					<SettingsHeader
						title="Large language model"
						description="Choose the LLM that Secoda AI will leverage to answer questions, write documentation, and other tasks."
					/>
					<Radio.Group
						value={customModel?.model || 'anthropic.claude-3-5-sonnet-latest'}
						onChange={handleModelChange}
						ml="xs"
					>
						<Radio value="openai.gpt-4o" label="OpenAI GPT 4o" my="xs" />
						<Radio
							value="anthropic.claude-3-5-sonnet-latest"
							label="Anthropic Claude Sonnet 3.5"
							my="xs"
						/>
						{allowXaiLlm && (
							<Radio value="xai.grok-beta" label="XAI Grok Beta" my="xs" />
						)}
					</Radio.Group>
					<Space h="5xl" />
					<Text size="lg" weight="bold">
						Tools
					</Text>
					<ToolsSettings aiMemory={aiMemory} />
					<Space h="5xl" />
					<Text size="lg" weight="bold">
						Personalization
					</Text>
					<SettingsAction
						title="Custom instructions"
						description="Add custom instructions for chat and descriptions. These settings are applied to all users."
						buttonText="Manage instructions"
						onButtonClick={showCustomInstructionsModal}
					/>
					{aiMemory && (
						<>
							<Divider my="lg" />
							<SettingsAction
								title="Memory"
								description="Secoda AI will become more helpful as you chat, picking up on details and preferences to tailor its responses to you."
								buttonText="Manage memories"
								onButtonClick={showMemoriesModal}
							/>
						</>
					)}
					{aiPersonas && (
						<>
							<Space h="5xl" />
							<Text size="lg" weight="bold">
								Personas
							</Text>
							<Text size="md" color="text/secondary/default">
								Create versions of Secoda AI for specific purposes.
							</Text>
							<AIPersonas />
						</>
					)}
					{aiFeedback && (
						<>
							<Space h="5xl" />
							<Text size="lg" weight="bold">
								AI tips
							</Text>
							<Text size="md" color="text/secondary/default">
								Automated tips to improve your workspace&apos;s AI readiness.
							</Text>
							<MarkdownRenderer>
								{aiFeedback?.documentation_suggestions || ''}
							</MarkdownRenderer>
							<MarkdownRenderer>
								{aiFeedback?.blind_spots || ''}
							</MarkdownRenderer>
						</>
					)}
					<Space h="5xl" />
				</>
			)}
		</div>
	);
}
