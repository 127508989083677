/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */

import {
	Avatar,
	// eslint-disable-next-line no-restricted-imports
	Badge,
	Box,
	// eslint-disable-next-line no-restricted-imports
	Button,
	createStyles,
	Group,
	rem,
	Tooltip,
	UnstyledButton,
	useMantineTheme,
} from '@mantine/core';
import { DatePicker, type DateValue } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { Prism } from '@mantine/prism';
import type {
	ActivityLogOut,
	DataQualityScore,
	LiteUser,
	TeamOut,
} from '@repo/api-codegen';
import { useApiListTeams } from '@repo/api-codegen';
import IconEmojiSelector from '@repo/common/components/IconEmojiSelector/IconEmojiSelector.tsx';
import MarkdownRenderer from '@repo/common/components/MarkdownRenderer';
import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip.tsx';
import { SELECTABLE_PROPERTY_OPTIONS } from '@repo/common/components/SelectableProperty/constants.ts';
import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector.js';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types.js';
import {
	colorGroupToFillMap,
	getUserInitialsFromName,
} from '@repo/common/components/UserAvatar/helpers.ts';
import { EntityType } from '@repo/common/enums/entityType';
import { pickColorType } from '@repo/common/utils';
import { getBreadCrumbsFromSearchMetadata } from '@repo/common/utils/breadcrumb.ts';
import type { IconNames } from '@repo/foundations';
import { Banner, Icon, IconButton, NumberInput, Text } from '@repo/foundations';
import type { SecodaThemeShades } from '@repo/theme/types';
import type { ColorNames } from '@repo/theme/utils.ts';
import { useUpdateEffect } from 'ahooks';
import dayjs from 'dayjs';
import { isEmpty } from 'lib0/object';
import {
	capitalize,
	filter,
	get,
	isArray,
	isNil,
	lowerCase,
	map,
	noop,
	size,
} from 'lodash-es';
import type { ReactNode } from 'react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import {
	useCurrentTeamMemberships,
	useTeamPermission,
} from '../../api/hooks/team/myMemberships.tsx';
import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList.ts';
import type {
	Automation,
	IBaseModel,
	ICollection,
	IIntegration,
	ILineage,
	ISearchMetadata,
	ISecodaEntity,
	ITag,
	IUser,
	IUserGroup,
} from '../../api/index.ts';
import {
	useAuthUser,
	useIntegrationPrefetched,
	useUpdateSecodaEntity,
} from '../../api/index.ts';
import {
	CUSTOM_PROPERTY_COLUMN_PREFIX,
	NEW_CUSTOM_PROPERTY_COLUMN_PREFIX,
} from '../../constants.ts';
import type { SecodaEntity } from '../../lib/models/index.ts';
import type { IntegrationStatusTones } from '../../pages/IntegrationsPage/constants.ts';
import { INTEGRATION_STATUS_MAPPING } from '../../pages/IntegrationsPage/constants.ts';
import { mapEntityTypeToIconString } from '../../utils/integrationLogo.tsx';
import { entityUrl } from '../../utils/navigationUtils.ts';
import { snakeCaseToTitleCase } from '../../utils/shared.utils.ts';
import { formatShortTimeDifference, getEpoch } from '../../utils/time.ts';
import { isJson } from '../../utils/utils.ts';
import DistributionPopup from '../CatalogView/TableView/components/DistributionPopup/index.ts';
import { DateRenderer } from '../CatalogView/TableView/DateRenderer.tsx';
import ColumnProfile from '../ColumnProfile/ColumnProfile.tsx';
import { DataQualityBadgeWithHover } from '../DataQualityScore/DataQualityBadgeWithHover.tsx';
import DescriptionEditor from '../DescriptionEditor';
import { CollectionSelector } from '../EntityModal/Metadata/CollectionSelector.tsx';
import { OwnerSelector } from '../EntityModal/Metadata/OwnerSelector.tsx';
import TagSelector from '../EntityModal/Metadata/TagSelector.tsx';
import {
	SelectableProperty,
	StaticProperty,
} from '../EntityPageLayout/EntityPropertySidebar/index.ts';
import IntegrationLogo from '../IntegrationLogo/index.tsx';
import { closeAllModals, closeModal, openConfirmModal } from '../ModalManager';
import { nFormatter } from '../SearchListItem/helpers.ts';
import { Popularity } from '../SearchListItem/template.tsx';
import { SynonymsSelector } from '../SynonymSelector/SynonymsSelector.tsx';
import { UserAvatar } from '../UserAvatar/index.ts';
import { ROW_HEIGHT } from './TableV2.styles.ts';

const useStyles = createStyles(
	(
		theme,
		{ level = 0, readOnly = false }: { level?: number; readOnly?: boolean }
	) => {
		const hoverFillColor = 'fill/transparent/hover';
		const activeFillColor = 'fill/transparent/active';
		const hoverIconColor: ColorNames = 'icon/primary/hover';
		const paddingY: number = theme.other.space[1.5];
		const activeIconColor: ColorNames = 'icon/primary/active';
		const boxShadow = '';
		const boxShadowOnActive = '';

		return {
			ellipsis: {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
			titleGroup: {
				flexWrap: 'nowrap',
				height: ROW_HEIGHT,
				marginLeft: level * 20,
			},
			container: {
				height: theme.other.space[7],
				width: theme.other.space[7],
				flexShrink: 0,
				padding: `${paddingY} 0`,
				marginRight: theme.spacing['xs'],
				position: 'relative',

				borderRadius: theme.other.space[2],

				boxShadow: boxShadow,
				transition: 'box-shadow 0.075s ease-in-out 0s',

				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				...(readOnly
					? {}
					: {
							'&:hover': {
								color: theme.other.getColor(hoverIconColor),
								backgroundColor: theme.other.getColor(hoverFillColor),
							},
							'&:active': {
								color: theme.other.getColor(activeIconColor),
								backgroundColor: theme.other.getColor(activeFillColor),
								boxShadow: boxShadowOnActive,
								paddingTop: undefined,
								paddingBottom: undefined,
							},
							'&:focus': {
								outline: `solid ${theme.other.getColor('border/emphasis/default')} 2px`,
								outlineOffset: rem(theme.other.space[0.25]),
							},
						}),
			},
		};
	}
);

export function CapitalizeTextRender<T extends ISecodaEntity | ILineage>({
	record,
	field,
	field2,
}: {
	record: T;
	field: keyof T;
	field2: keyof T;
}) {
	const { classes } = useStyles({});

	let value = get(record, field) ?? get(record, field2) ?? 'None';
	value = capitalize(value as string).replace('_', ' ');

	return (
		<Text
			size="sm"
			role="link"
			sx={{
				lineHeight: `${ROW_HEIGHT}px`,
			}}
			className={classes.ellipsis}
			color="text/primary/default"
		>
			{isEmpty(value) ? ' ' : value}
		</Text>
	);
}

export function BoldTextRender<T extends IBaseModel>({
	record,
	field,
}: {
	record: T;
	field: keyof T;
}) {
	const { classes } = useStyles({});

	return (
		<Text
			size="sm"
			role="link"
			weight="bold"
			className={classes.ellipsis}
			color="text/primary/default"
			sx={{
				lineHeight: `${ROW_HEIGHT}px`,
			}}
		>
			{isEmpty(get(record, field) as unknown as object)
				? 'Untitled'
				: (get(record, field) as ReactNode)}
		</Text>
	);
}

export function TextRender<T extends IBaseModel>({
	record,
	field,
}: {
	record: T;
	field: keyof T;
}) {
	const { classes } = useStyles({});

	const value = get(record, field);
	return (
		<Text
			size="sm"
			role="link"
			sx={{
				lineHeight: `${ROW_HEIGHT}px`,
			}}
			className={classes.ellipsis}
			color="text/primary/default"
		>
			{isEmpty(value as unknown as object) && typeof value !== 'number'
				? ''
				: (value as ReactNode)}
		</Text>
	);
}

export function TitleRender({
	record,
	modalId,
}: {
	record: {
		id: string;
		entity_type?: EntityType;
		title?: string;
		title_cased?: string;
		icon?: string;
		onExpandClick?: (id: string) => void;
		onLoadMore?: (id: string) => void;
		paramExpandedParentPrefix?: string;
		paramExpandedLevel?: number;
		paramExpanded?: boolean;
		isLastElement?: boolean;
	};
	modalId?: string;
}) {
	const {
		onExpandClick,
		id,
		paramExpanded,
		paramExpandedLevel,
		isLastElement,
		onLoadMore,
	} = record;
	const level = paramExpandedLevel ?? 0;

	const { isEditorUser } = useAuthUser();
	const readOnly = !isEditorUser;

	const { classes } = useStyles({ level, readOnly });

	const navigate = useNavigate();
	const theme = useMantineTheme();

	const [expanded, setExpanded] = useState(paramExpanded ?? false);

	const textRef = useRef<HTMLDivElement>(null);

	const handleNavigate = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			e.preventDefault();

			const url = entityUrl(record as unknown as SecodaEntity);

			if (modalId) {
				closeModal(modalId);
			}

			// If holding the `meta` key, open the entity in a new tab.
			if (e.metaKey) {
				window.open(url, '_blank');
			} else {
				navigate(url);
			}
		},
		[modalId, navigate, record]
	);

	const isEllipsisHidden = useCallback(
		(e: HTMLDivElement | null) => (e ? e.offsetWidth >= e.scrollWidth : false),
		[]
	);

	const { mutateAsync: updateEntity } = useUpdateSecodaEntity({});

	const handleIconChange = useCallback(
		async (value: string) => {
			await updateEntity({
				data: {
					id: record.id,
					['icon']: value,
				},
			});
		},
		[record, updateEntity]
	);

	const tooltipLabel = useMemo(
		() => record?.title_cased ?? record?.title,
		[record]
	);

	const entityTypesWithIcon = [
		EntityType.collection,
		EntityType.document,
		EntityType.dictionary_term,
		EntityType.glossary,
	];
	const defaultIcon = mapEntityTypeToIconString(record.entity_type);
	const shouldRenderDefaultIcon =
		!record.icon &&
		defaultIcon &&
		entityTypesWithIcon.includes(record.entity_type as EntityType);
	const shouldRenderIcon = record.icon && !record.icon.includes('.svg');

	return (
		<Group
			pos="relative"
			data-testid="title-render"
			spacing={0}
			className={classes.titleGroup}
		>
			<Box>
				{onExpandClick && (
					<Box
						pos={'absolute'}
						left={`-${theme.other.space[8]}`}
						top={theme.other.space[2]}
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
					>
						<Tooltip
							withinPortal
							label={expanded ? 'Hide children' : 'Show children'}
						>
							<IconButton
								style={{
									transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
									transition: 'transform 0.2s ease',
								}}
								iconName="chevronRight"
								variant="tertiary"
								onClick={() => {
									setExpanded((prev) => !prev);
									onExpandClick(id);
								}}
								data-testid="toggle-expand-button"
							/>
						</Tooltip>
					</Box>
				)}
				{(shouldRenderIcon || shouldRenderDefaultIcon) && (
					<Box
						className={classes.container}
						onClick={(e) => {
							!readOnly && e.stopPropagation();
							!readOnly && e.preventDefault();
						}}
					>
						<IconEmojiSelector
							value={record.icon ?? defaultIcon}
							onChange={handleIconChange}
							iconSize={15}
							emojiSize={15}
							entityType={record.entity_type}
							readOnly={readOnly}
						/>
					</Box>
				)}
			</Box>
			{/* If we are expandable, `navigate` is set to false in the coldef
			 and we need to handle clicks on the title directly. */}
			<Box
				display="flex"
				h="100%"
				w="100%"
				onClick={handleNavigate}
				sx={{
					overflow: 'hidden',
				}}
			>
				<Tooltip
					maw={theme.other.space[90]}
					sx={{
						overflowWrap: 'break-word',
						whiteSpace: 'normal',
					}}
					zIndex={1000}
					withinPortal
					position="top"
					pos="absolute"
					openDelay={1000}
					label={tooltipLabel}
					hidden={
						isEmpty(record?.title_cased ?? record?.title) ||
						isEllipsisHidden(textRef.current)
					}
				>
					<Text
						size="sm"
						role="link"
						ref={textRef}
						sx={{
							lineHeight: `${ROW_HEIGHT}px`,
						}}
						className={classes.ellipsis}
						color="text/primary/default"
						fw="bold"
					>
						{record?.title_cased ?? record?.title ?? 'Untitled'}
					</Text>
				</Tooltip>
			</Box>
		</Group>
	);
}

export function ReadOnlyFormSubmissionStatusRender(record: { status: string }) {
	const status = record?.status;
	return (
		<Badge color={status?.toLowerCase() === 'approved' ? 'green' : 'gray'}>
			{status}
		</Badge>
	);
}

export function ReadOnlyOwnerRender({
	record,
}: {
	record: {
		user?: IUser | LiteUser | undefined | null;
	};
}) {
	const user = record?.user;
	const { classes } = useStyles({});

	if (!user) {
		return null;
	}

	return (
		<Group spacing="xs">
			<UserAvatar size="xxs" user={user as IUser} />
			<Text size="sm" className={classes.ellipsis}>
				{user?.display_name}
			</Text>
		</Group>
	);
}

export function ReadOnlyOwnersRender({ owners }: { owners?: IUser[] }) {
	const { classes } = useStyles({});

	return (
		<Group spacing={'xs'} noWrap>
			<Avatar.Group spacing="xs">
				{map(owners, (user) => (
					<UserAvatar size="sm" user={user} />
				))}
			</Avatar.Group>
			<Text size="sm" className={classes.ellipsis}>
				{size(owners) === 1 && owners?.[0].display_name}
				{size(owners) > 1 && `${owners?.length} people`}
			</Text>
		</Group>
	);
}

export function ReadOnlyGroupsRender({ groups }: { groups?: IUserGroup[] }) {
	const { classes } = useStyles({});

	const display_name = groups?.[0]?.name;
	const theme = useMantineTheme();
	const colorGroup = pickColorType(display_name);
	const { fillStart, fillEnd, textColor } = colorGroupToFillMap(colorGroup);
	const initials = getUserInitialsFromName(display_name) || '';
	const iconSize = 24;

	return (
		<Group spacing={'xs'} noWrap>
			<Avatar.Group spacing="xs">
				{map(groups, () => (
					<Avatar
						size={iconSize}
						h={iconSize}
						w={iconSize}
						mih={iconSize}
						miw={iconSize}
						sx={{
							backgroundImage: theme.fn.gradient({
								from: fillStart,
								to: fillEnd,
								deg: 180,
							}),
							color: textColor,
						}}
					>
						{initials}
					</Avatar>
				))}
			</Avatar.Group>
			<Text size="sm" className={classes.ellipsis}>
				{size(groups) === 1 && groups?.[0].name}
				{size(groups) > 1 && `${groups?.length} groups`}
			</Text>
		</Group>
	);
}

export function ReadOnlyOwnersIdsRender<T>({
	record,
	accessor,
}: {
	record: T;
	accessor: keyof T;
}) {
	const { activeUsers } = useExtendedUserList();
	const owners =
		activeUsers?.filter((user) => get(record, accessor)?.includes(user.id)) ??
		[];

	return <ReadOnlyOwnersRender owners={owners as IUser[]} />;
}

export function UserRender<T>({
	record,
	accessor,
	esAccessor,
}: {
	record: T;
	accessor: keyof T;
	esAccessor?: keyof T;
}) {
	const value = get(record, accessor) ?? get(record, esAccessor ?? accessor);

	const { activeUsers } = useExtendedUserList();
	const owners = activeUsers?.filter((user) => value === user.id) ?? [];

	return <ReadOnlyOwnersRender owners={owners} />;
}

export function UsersAndGroupsRender<T>({
	record,
	userAccessor,
	userEsAccessor,
	groupAccessor,
	groupEsAccessor,
}: {
	record: T;
	userAccessor: keyof T;
	userEsAccessor: keyof T;
	groupAccessor: keyof T;
	groupEsAccessor: keyof T;
}) {
	const userValue = get(record, userAccessor) ?? get(record, userEsAccessor);
	const groupValue = get(record, groupAccessor) ?? get(record, groupEsAccessor);

	const { activeUsers, userGroups } = useExtendedUserList();
	const owners = activeUsers?.filter((user) => userValue === user.id) ?? [];
	const groups = userGroups?.filter((group) => groupValue === group.id) ?? [];

	return (
		<>
			{groups.length > 0 ? (
				<ReadOnlyGroupsRender groups={groups} />
			) : (
				<ReadOnlyOwnersRender owners={owners} />
			)}
		</>
	);
}

export function ColumnProfileRender(record: {
	title: string;

	search_metadata: {
		type: string;
		isPrimaryKey: boolean;
	};

	type: string;

	properties: {
		_profile: {
			count: number;
			max: number;
			median: number;
			unique: number;
			percent_filled: number;
			min: number;
			mean: number;
			ntiles: {
				is_numeric: boolean;
				left: number;
				right: number;
				label: string;
				frequency: number;
			}[];
		};
	};
}) {
	const [opened, { close, open }] = useDisclosure(false);

	if (!record.properties?._profile) {
		return null;
	}

	return (
		<>
			{opened && (
				<DistributionPopup
					fieldName={record.title}
					opened={opened}
					onClose={close}
					profileData={record.properties._profile}
					columnType={get(record, 'search_metadata.type')}
					isPrimaryKey={get(record, 'search_metadata.is_pk') ?? false}
				/>
			)}
			<Group onClick={open}>
				<ColumnProfile data={record?.properties?._profile.ntiles} />
			</Group>
		</>
	);
}

export type TagRenderProps<T> = {
	onChange?: (id: string) => (value: string[]) => void;
	field?: string;
	record: T;
	label?: string;
	hideOnEmpty?: boolean;
	customPropertyIdFilter?: string;
};

export function TagRender<
	T extends { id: string; integration?: string; tags?: string[] | ITag[] },
>({
	onChange,
	field = 'tags',
	record,
	label,
	hideOnEmpty = true,
	customPropertyIdFilter,
}: TagRenderProps<T>) {
	const theme = useMantineTheme();
	const { write } = useTeamPermission();
	const { id, integration: integrationId } = record;

	const { data: integration } = useIntegrationPrefetched({
		id: integrationId!,
		options: { enabled: !!integrationId },
	});

	const maintainTags = !!integration?.credentials.import_tags_preference;

	const tagIds = useMemo(() => {
		// Handle the case where the field value is a stringified array, which is the
		// case for custom properties.
		const fieldValue = isJson(get(record, field))
			? JSON.parse(get(record, field))
			: get(record, field);
		const tagsList = Array.isArray(fieldValue) ? fieldValue : [fieldValue];
		return (tagsList ?? [])
			.map((tag) => (typeof tag === 'object' ? tag?.id : tag))
			.filter((tag: string | undefined | null) => !isNil(tag)) as string[];
	}, [field, record]);

	const noTags = maintainTags && tagIds.length === 0;

	return (
		<Box ml={`-${theme.spacing['2xs']}`}>
			<TagSelector
				customPropertyIdFilter={customPropertyIdFilter}
				label={label}
				key={tagIds.join('-')}
				hideOnEmpty={hideOnEmpty}
				field={field}
				readOnly={!write || isNil(onChange) || noTags}
				onChange={onChange?.(id)}
				placeholder={
					!write || noTags ? `No ${label ?? field}` : `Add ${label ?? field}`
				}
				forceVariant="tertiary"
				initialValue={tagIds}
				maintainTags={maintainTags}
				entityIntegrationId={integrationId}
				entityIntegrationName={integration?.name}
			/>
		</Box>
	);
}

export function ParentRenderer({
	record,
}: {
	record: { search_metadata?: ISearchMetadata };
}) {
	const { search_metadata: searchMetadata } = record;
	const breadcrumbs = getBreadCrumbsFromSearchMetadata(searchMetadata);

	const { classes, theme } = useStyles({});

	const textRef = useRef<HTMLDivElement>(null);

	const isEllipsisHidden = useCallback(
		(e: HTMLDivElement | null) => (e ? e.offsetWidth >= e.scrollWidth : false),
		[]
	);

	return (
		<Tooltip
			maw={theme.other.space[90]}
			sx={{
				overflowWrap: 'break-word',
				whiteSpace: 'normal',
			}}
			withinPortal
			hidden={isEllipsisHidden(textRef.current)}
			label={breadcrumbs.join(' / ')}
		>
			<Text size="sm" ref={textRef} className={classes.ellipsis}>
				{breadcrumbs.join(' / ')}
			</Text>
		</Tooltip>
	);
}

export function OwnerRender<
	T extends Pick<
		ISecodaEntity,
		'owners' | 'owners_groups' | 'id' | 'integration'
	>,
>({
	label = 'owners',
	field = 'owners',
	ownerGroupField = 'owners_groups',
	record,
	onChangeUserOwners,
	onChangeGroupOwners,
	hideOnEmpty = true,
}: {
	label?: string;
	field?: string;
	ownerGroupField?: string;
	record: T;
	onChangeUserOwners?: (id: string) => (value: string[]) => void;
	onChangeGroupOwners?: (id: string) => (value: string[]) => void;
	hideOnEmpty?: boolean;
}) {
	const theme = useMantineTheme();
	const { write } = useTeamPermission();
	const { members } = useCurrentTeamMemberships();
	const { id, integration: integrationId } = record;

	// Handle the case where the field value is a stringified array, which is the
	// case for custom properties.

	const _owners =
		(isJson(get(record, field))
			? JSON.parse(get(record, field))
			: get(record, field)) ?? [];
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const owners = isArray(_owners) ? _owners : [];

	// Handle the case where the field value is a stringified array, which is the
	// case for custom properties.

	const _owners_groups =
		(isJson(get(record, ownerGroupField))
			? JSON.parse(get(record, ownerGroupField))
			: get(record, ownerGroupField)) ?? [];
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const owners_groups = isArray(_owners_groups) ? _owners_groups : [];

	const { data: integration } = useIntegrationPrefetched({
		id: integrationId!,
		options: { enabled: !!integrationId },
	});
	const maintainOwners = !!integration?.credentials.import_owners_preference;

	const withUserOwners = !isNil(onChangeUserOwners);
	const withUserGroupOwners = !isNil(onChangeGroupOwners);

	const ownerIds = useMemo(() => {
		const userIds =
			owners
				?.map((user) => (typeof user === 'object' ? (user as IUser).id : user))
				.filter((owner) => !isNil(owner)) ?? [];

		const userGroupIds =
			owners_groups
				?.map((group) =>
					typeof group === 'object' ? (group as IUserGroup).id : group
				)
				.filter((owner) => !isNil(owner)) ?? [];

		return [...userIds, ...(withUserGroupOwners ? userGroupIds : [])];
	}, [owners, owners_groups, withUserGroupOwners]);

	const readOnly =
		!write || !(withUserOwners || withUserGroupOwners) || maintainOwners;
	return (
		<Box ml={`-${theme.spacing['2xs']}`}>
			<OwnerSelector
				propertyLabel={label}
				property={field}
				groupUsersBySections={
					members
						? [
								{
									label: 'Current team users',
									value: filter(
										members?.map((membership) => membership?.id) ?? [],
										(el) => !isNil(el)
									),
								},
							]
						: undefined
				}
				forceVariant="tertiary"
				hideOnEmpty={hideOnEmpty}
				initialValue={ownerIds}
				maintainOwners={maintainOwners}
				entityIntegrationId={integrationId}
				entityIntegrationName={integration?.name}
				key={ownerIds?.join('-')}
				onChangeUserOwners={onChangeUserOwners?.(id)}
				onChangeGroupOwners={onChangeGroupOwners?.(id)}
				placeholder={readOnly ? 'No owners' : 'Add owner'}
				readOnly={readOnly}
			/>
		</Box>
	);
}

export function RelativeTimeRender<T>({
	record,
	field,
	isFuture = false,
}: {
	record: T;
	field: string;
	isFuture?: boolean;
}) {
	const { classes } = useStyles({});
	const datetimeValue = get(record, field);
	const theme = useMantineTheme();

	if (!datetimeValue) {
		return null;
	}

	const currentTime = dayjs();
	const targetTime = dayjs(datetimeValue);

	if (isFuture && targetTime.isBefore(currentTime)) {
		return null;
	}

	return (
		<Tooltip
			maw={theme.other.space[90]}
			sx={{
				overflowWrap: 'break-word',
				whiteSpace: 'normal',
			}}
			withinPortal
			label={dayjs(datetimeValue).format('YYYY-MM-DD HH:mm:ss')}
		>
			<Text size="sm" className={classes.ellipsis}>
				{datetimeValue && formatShortTimeDifference(datetimeValue, true)}
			</Text>
		</Tooltip>
	);
}

export function ReadOnlyBadgeRender<T>({
	record,
	field,
	options,
	nilOption,
}: {
	record: T;
	field: string;
	options: {
		color: string;
		option: string | boolean | number;
		label?: string;
	}[];
	nilOption: {
		color: string;
		option: string | boolean | number;
		label?: string;
	};
}) {
	function lowerCaseIfString(el: string | boolean | number) {
		return typeof el === 'string' ? lowerCase(el) : el;
	}

	const color =
		options.find(
			(option) =>
				lowerCaseIfString(option.option) ===
				lowerCaseIfString(get(record, field))
		)?.color ?? nilOption.color;

	return (
		<Badge color={color}>
			{get(record, field)
				? (
						options.find(
							(option) =>
								lowerCaseIfString(option.option) ===
								lowerCaseIfString(get(record, field))
						)?.label ?? capitalize(get(record, field))
					)?.replace(/_/g, ' ')
				: capitalize(nilOption.label).replace(/_/g, ' ')}
		</Badge>
	);
}

export function ReadOnlyBadgeWithToneRender<T>({
	record,
	field,
	options,
	nilOption,
}: {
	record: T;
	field: string;
	options: {
		tone: IntegrationStatusTones;
		label: string;
		option: string;
		iconName?: IconNames;
	}[];
	nilOption: {
		tone: IntegrationStatusTones;
		label: string;
		option: string;
		iconName?: IconNames;
	};
}) {
	const fieldValue = get(record, field);
	const matchedOption = options.find((option) => option.option === fieldValue);

	if (!fieldValue || !matchedOption) {
		const nilLabel = nilOption.label
			? capitalize(nilOption.label).replace(/_/g, ' ')
			: '';
		return <Badge variant={nilOption.tone}>{nilLabel}</Badge>;
	}

	const { tone, label, iconName } = matchedOption;
	const formattedLabel = (label ?? capitalize(fieldValue as string)).replace(
		/_/g,
		' '
	);

	return (
		<Badge variant={tone} leftSection={iconName && <Icon name={iconName} />}>
			{formattedLabel}
		</Badge>
	);
}

export function IntegrationStatusRender({ record }: { record: IIntegration }) {
	const options = useMemo(
		() =>
			Object.entries(INTEGRATION_STATUS_MAPPING).map(([key, value]) => ({
				tone: value.tone,
				label: value.label,
				option: key,
				iconName: value.iconName,
			})),
		[]
	);

	const nilOption = useMemo(
		() => ({
			tone: INTEGRATION_STATUS_MAPPING.pending.tone,
			label: INTEGRATION_STATUS_MAPPING.pending.label,
			option: 'pending',
		}),
		[]
	);

	if (record.type === 'slack') {
		return null;
	}

	return (
		<ReadOnlyBadgeWithToneRender
			record={record}
			field="status"
			options={options}
			nilOption={nilOption}
		/>
	);
}

export function SelectorWithIconRender<T extends IBaseModel>({
	record,
	accessor,
	esAccessor,
	nilOption,
	onChange,
}: {
	record: T;
	accessor: string;
	esAccessor?: string;
	nilOption: string | boolean;
	onChange?: (id: string) => (value: any) => void;
}) {
	const theme = useMantineTheme();
	const { write } = useTeamPermission();
	const { id } = record;

	return (
		<Box ml={`-${theme.spacing['2xs']}`}>
			<SingleSelector
				variant="tertiary"
				searchable
				readOnly={!write || isNil(onChange)}
				property={accessor}
				placeholder={`No ${accessor}`}
				options={SELECTABLE_PROPERTY_OPTIONS[accessor]}
				onChange={onChange?.(id)}
				isViewerUser={!write}
				initialSelected={
					(record?.[accessor as keyof T] ??
						record?.[esAccessor as keyof T] ??
						nilOption) as string
				}
				iconType={esAccessor === 'question_status' ? 'badge' : 'tabler'}
				hideOnEmpty
			/>
		</Box>
	);
}

export function BadgeRender<T extends IBaseModel>({
	record,
	field,
	badgeOptions,
	nilOption,
	onChange,
}: {
	record: T;
	field: string;
	badgeOptions: {
		color: string;
		option: string | boolean;
		label?: string;
		textColor?: string;
		fontSize?: string;
	}[];
	nilOption: {
		color: string;
		option: string | boolean;
		label?: string;
		textColor?: string;
		fontSize?: string;
	};
	onChange?: (id: string) => (value: any) => void;
}) {
	const { id } = record;
	const { write } = useTeamPermission();
	const theme = useMantineTheme();

	const options = useMemo(
		() =>
			badgeOptions.map((option) => ({
				value: option.option,
				label: option.label!,
				color: option.color as SecodaThemeShades,
				textColor: option.textColor as SecodaThemeShades,
				fontSize: option.fontSize,
			})),
		[badgeOptions]
	);

	return (
		<Box ml={`-${theme.spacing['2xs']}`}>
			<SingleSelector
				readOnly={!write || isNil(onChange)}
				hideOnEmpty
				searchable
				variant="tertiary"
				iconType="badge"
				isViewerUser={!write}
				placeholder={`No ${field === 'published' ? 'status' : field}`}
				onChange={onChange?.(id)}
				initialSelected={
					(record?.[field as keyof T] as string) ?? nilOption.option
				}
				property={field}
				options={options}
			/>
		</Box>
	);
}

export function TeamRender({
	record,
	onChange,
	optionsFilter = () => true,
	...rest
}: {
	record: {
		id: string;
		teams: ({ id: string } | string)[];
	};
	optionsFilter?: (id: TeamOut) => boolean;
	onChange?: (id: string) => (value: unknown) => void;
	placeholder?: string;
}) {
	const { data } = useApiListTeams(
		{},
		{
			select: (data) => data.results.filter(optionsFilter),
		}
	);

	return (
		<SelectableProperty
			{...rest}
			type="multi"
			value="teams"
			iconType="emoji"
			isViewerUser={isNil(onChange)}
			onChange={onChange?.(record.id)}
			selected={record.teams as string[]}
			options={data?.map((team) => ({
				value: team.id,
				label: team.name,
				icon: team.icon,
			}))}
		/>
	);
}

export function CollectionRender<T extends ISecodaEntity>({
	record,
	onChange,
}: {
	record: T;
	onChange?: (id: string) => (value: string[]) => void;
}) {
	const { id, collections } = record;

	const { write } = useTeamPermission();

	const theme = useMantineTheme();

	const collectionIds = useMemo(
		() =>
			collections
				.map((collection) =>
					typeof collection === 'object'
						? (collection as ICollection)?.id
						: collection
				)
				.filter((collection) => !isNil(collection)) as string[],
		[collections]
	);

	return (
		<Box ml={`-${theme.spacing['2xs']}`}>
			<CollectionSelector
				key={collectionIds?.join('-')}
				hideOnEmpty
				forceVariant="tertiary"
				onChange={onChange?.(id)}
				initialValue={collectionIds}
				readOnly={!write || isNil(onChange)}
				placeholder={!write ? 'No collections' : 'Add to collection'}
			/>
		</Box>
	);
}

export function StatusRender(record: { status: string | boolean | string[] }) {
	const theme = useMantineTheme();
	const { write } = useTeamPermission();

	return (
		<Box ml={`-${theme.spacing['2xs']}`}>
			<SelectableProperty
				readOnly={!write}
				placeholder={!write ? 'No status' : 'Add status'}
				selected={record?.status}
				type="single"
				value="status"
				iconType="tabler"
				isViewerUser={!write}
			/>
		</Box>
	);
}

const useDescriptionStyles = createStyles(
	(theme, { disabled }: { disabled: boolean }) => {
		const { write } = useTeamPermission();
		return {
			hidden: {
				visibility: 'hidden',
			},
			wrapper: {
				height: `${ROW_HEIGHT - 16}px`,
				marginLeft: `-${theme.spacing.xs}`,
				borderRadius: theme.radius.sm,
				paddingLeft: theme.spacing.xs,
				paddingRight: theme.spacing.xs,
				// Necessary to hide the overflow of the markdown renderer.
				overflow: 'hidden',
				maxWidth: '100%',
				'&:hover': {
					backgroundColor: !write
						? undefined
						: theme.other.getColor('fill/transparent/hover'),
				},
			},
			markdown: {
				lineHeight: `${theme.other.typography.lineHeight.text.xl}`,
				height: `${theme.other.space[7]}px`,
				width: '100%',
				p: {
					lineHeight: `${theme.other.typography.lineHeight.text.xl}`,
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				},
			},
			descriptionEditor: {
				backgroundColor: disabled
					? theme.other.getColor('surface/secondary/default')
					: theme.other.getColor('fill/transparent/default'),
				padding: '1rem',
				width: '100%',
				borderRadius: theme.radius.sm,
				border: `${theme.other.space[0.25]}px solid ${theme.other.getColor(
					'border/input/default'
				)}`,
				'&:focus-within, &:focus': {
					outline: '1px solid #0066CC',
				},
			},
			noDescription: {
				color: theme.other.getColor('text/secondary/default'),
			},
		};
	}
);

const NO_WRAP_STYLE: React.CSSProperties = { textWrap: 'nowrap' };

export function DescriptionRender<T extends ISecodaEntity>({
	record,
	label,
	field,
	onChange,
}: {
	record: T;
	field: string;
	label?: string;
	onChange?: (id: string) => (value: string) => void;
}) {
	// eslint-disable-next-line no-param-reassign
	label = label ?? field;
	const { id, integration: integrationId, title } = record;

	// This can include custom properties under the path `properties.custom.` so
	// we must use `get` here.
	const description = get(record, field);

	const { isAdminUser } = useAuthUser();
	const { write } = useTeamPermission();

	const { data: integration } = useIntegrationPrefetched({
		id: integrationId!,
		options: { enabled: !!integrationId },
	});

	const frozenDescription =
		!!integration?.credentials.use_native_descriptions &&
		!field.startsWith(CUSTOM_PROPERTY_COLUMN_PREFIX) &&
		!field.startsWith(NEW_CUSTOM_PROPERTY_COLUMN_PREFIX);

	const { classes: descriptionStyles, theme } = useDescriptionStyles({
		disabled: frozenDescription,
	});

	const [inputValue, setInputValue] = useState<string>(description ?? '');

	// If an optimistic update is made, we need to update the input value.
	useUpdateEffect(() => {
		setInputValue(description ?? '');
	}, [description]);

	const ref = useRef<string>(description ?? '');
	const descriptionRef = useRef<HTMLDivElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const updateRef = useCallback((value: string) => {
		ref.current = value;
	}, []);

	const isEllipsisHidden = useCallback(
		(parent: HTMLDivElement | null, child: HTMLDivElement | null) =>
			parent && child ? parent.offsetWidth - 16 > child.offsetWidth : false,
		[]
	);

	const saveDescription = useCallback(() => {
		setInputValue(ref.current);
		onChange?.(id)(ref.current);
		closeAllModals();
	}, [id, onChange]);

	const handleModal = useCallback(() => {
		if (!onChange) {
			return;
		}

		openConfirmModal({
			withCloseButton: true,
			withinPortal: false,
			closeOnClickOutside: true,
			labels: {
				confirm: frozenDescription ? null : 'Save',
				cancel: frozenDescription ? null : 'Cancel',
			},
			confirmProps: {
				onClick: () => {
					saveDescription();
				},
			},
			cancelProps: {
				color: 'gray',
				onClick: () => {
					closeAllModals();
				},
			},
			title: (
				<Group spacing="2xs">
					<Text size="md" weight="bold">
						Edit {label} of
					</Text>
					<Text size="md" weight="semibold">
						{title}
					</Text>
				</Group>
			),
			children: (
				<Group>
					<Group className={descriptionStyles.descriptionEditor}>
						{frozenDescription && !inputValue ? (
							<Text
								style={NO_WRAP_STYLE}
								className={descriptionStyles.noDescription}
								color="text/secondary/default"
								size="sm"
							>
								No {label}
							</Text>
						) : (
							<DescriptionEditor
								entityId={id}
								value={inputValue}
								withAi={field === 'description'}
								placeholder={`Add ${label}...`}
								onChange={updateRef}
								save={saveDescription}
								readOnly={frozenDescription}
								autoFocus
							/>
						)}
					</Group>
					{frozenDescription && (
						<Banner
							isInsideCard
							message={
								isAdminUser ? (
									<Group spacing="xs">
										<Text>
											{description
												? `${capitalize(field)} comes from ${integration?.name}. To maintain in Secoda, go to integration settings.`
												: `No ${label} set in ${integration?.name}. To maintain in Secoda, go to integration settings.`}
										</Text>
										<Link to={`/integrations/${integrationId}/preferences`}>
											<Button onClick={() => closeAllModals()}>
												{integration?.name} settings
											</Button>
										</Link>
									</Group>
								) : (
									<Text>Description comes from {integration?.name}.</Text>
								)
							}
							onDismiss={noop}
							tone="info"
						/>
					)}
				</Group>
			),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		onChange,
		frozenDescription,
		field,
		title,
		descriptionStyles.descriptionEditor,
		descriptionStyles.noDescription,
		inputValue,
		id,
		updateRef,
		saveDescription,
		isAdminUser,
		description,
		integration?.name,
		integrationId,
	]);

	return (
		<Tooltip
			withinPortal
			multiline
			maw={theme.other.space[90]}
			sx={{
				overflowWrap: 'break-word',
				whiteSpace: 'normal',
			}}
			label={inputValue}
			hidden={
				isEmpty(inputValue) ||
				isEllipsisHidden(wrapperRef.current, descriptionRef.current)
			}
		>
			<Box
				ref={wrapperRef}
				sx={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{write && (
					<UnstyledButton
						className={descriptionStyles.wrapper}
						onClick={handleModal}
					>
						{(isEmpty(inputValue) || isNil(inputValue)) && (
							<Text
								style={NO_WRAP_STYLE}
								className={descriptionStyles.hidden}
								color="text/secondary/default"
								size="sm"
							>
								{frozenDescription ? `No ${label}` : `Add ${label}`}
							</Text>
						)}
						{!isEmpty(inputValue) && (
							<MarkdownRenderer
								ref={descriptionRef}
								className={descriptionStyles.markdown}
								inline
								lineClamp={1}
								fontSize="small"
							>
								{inputValue}
							</MarkdownRenderer>
						)}
					</UnstyledButton>
				)}
				{!write && !isEmpty(inputValue) && (
					<MarkdownRenderer
						ref={descriptionRef}
						className={descriptionStyles.markdown}
						inline
						lineClamp={1}
					>
						{inputValue}
					</MarkdownRenderer>
				)}
			</Box>
		</Tooltip>
	);
}

export function PopularityRender({
	record,
}: {
	record: {
		entity_type: string;
		external_usage: number;
	};
}) {
	const theme = useMantineTheme();
	const { entity_type: entityType, external_usage: externalUsage } = record;

	if (isNil(externalUsage) || externalUsage === 0) {
		return null;
	}

	if (entityType === EntityType.column) {
		return null;
	}

	return (
		<Group spacing={theme.spacing.xs}>
			<Popularity level={Math.log10(externalUsage)} />
			<Text size="sm" color="text/primary/default">
				{nFormatter(externalUsage, 1)}{' '}
				{entityType === EntityType.table ? 'queries' : 'views'}
			</Text>
		</Group>
	);
}

export function RenderWithIntegrationLogo({
	textContent,
	integrationType,
	integrationId,
	forceTitleCase = true,
	onClick = undefined,
}: {
	textContent?: string;
	integrationType?: string;
	integrationId?: string;
	forceTitleCase?: boolean;
	onClick?: () => void;
}) {
	const { classes, theme } = useStyles({});

	const handleClick = (e: React.MouseEvent) => {
		if (onClick) {
			e.preventDefault();
			e.stopPropagation();
			onClick();
		}
	};

	return (
		<Group
			display="flex"
			noWrap
			spacing={theme.spacing.xs}
			onClick={handleClick}
		>
			<IntegrationLogo
				integrationId={integrationId}
				integrationType={integrationType}
				width={theme.other.space[4]}
				height={theme.other.space[4]}
			/>
			<Text
				size="sm"
				sx={{
					lineHeight: `${theme.other.space[4]}px`,
				}}
				className={classes.ellipsis}
			>
				{forceTitleCase
					? snakeCaseToTitleCase(textContent ?? '')
					: (textContent ?? '')}
			</Text>
		</Group>
	);
}

export function IntegrationRender({
	record,
}: {
	record: { integration: string };
}) {
	const { integration: integrationId } = record;
	const { data: integration } = useIntegrationPrefetched({ id: integrationId });

	if (!integration) {
		return null;
	}

	return (
		<RenderWithIntegrationLogo
			integrationId={integrationId}
			integrationType={integration.type}
			textContent={integration.name}
		/>
	);
}

export function MonitorResourceRender({
	record,
}: {
	record: {
		target?: string;
		display_metadata?: {
			target?: {
				integration?: string;
				target_title?: string;
				target_url?: string;
				integration_name?: string;
				integration_url?: string;
				label?: string;
				integration_type?: string;
			};
		};
	};
}) {
	const navigate = useNavigate();
	const dm = record.display_metadata?.target || {};

	if (record.target) {
		return (
			<RenderWithIntegrationLogo
				forceTitleCase={false}
				integrationType={dm.integration_type ?? ''}
				textContent={dm.target_title ?? ''}
			/>
		);
	} else {
		return (
			<RenderWithIntegrationLogo
				forceTitleCase={false}
				integrationType={dm.integration_type ?? ''}
				textContent={dm.integration_name ?? ''}
			/>
		);
	}
}

export function PiiIntegrationRender(record: { integration_name: string }) {
	return (
		<RenderWithIntegrationLogo
			integrationType={record.integration_name ?? ''}
			textContent={record.integration_name ?? ''}
		/>
	);
}

export function QueryResourceRender(record: {
	related_entities?: {
		id?: string | null | undefined;
		integration_id?: string | null | undefined;
		title_full?: string | null | undefined;
		title?: string | null | undefined;
		entity_type?: string | null | undefined;
	}[];
}) {
	return (
		<StaticProperty
			label={`${record.related_entities?.length ?? 0} Resources`}
			value={
				(record.related_entities || []).map((entity) => ({
					label: entity.title_full || entity.title,
					value: entity.id,
					icon: (
						<IntegrationLogo
							height={18}
							width={18}
							entityType={(entity?.entity_type as EntityType) ?? ''}
							integrationId={entity?.integration_id ?? ''}
						/>
					),
				})) as SelectablePropertyItem[]
			}
			type="tables"
			forceInline
		/>
	);
}

function DateRender({ value }: { value: string | undefined }) {
	return <DateRenderer value={value} size="xs" />;
}

export function CreatedAtRender({
	record,
}: {
	record: { created_at?: string | undefined };
}) {
	return <DateRender value={record?.created_at} />;
}

export function LoggedAtRender({
	record,
}: {
	record: { logged_at?: string | undefined };
}) {
	return <DateRender value={record?.logged_at} />;
}

const useSqlRenderStyles = createStyles((theme) => ({
	prismRoot: {
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		borderRadius: 8,
		padding: 5,
	},
	prismScrollArea: {
		backgroundColor: 'transparent',
		marginRight: 5,
	},
	prismCode: {
		backgroundColor: 'transparent !important',
		minHeight: 90,
		'text-wrap': 'balance',
	},
}));

export function SQLRender(record: { sql?: string | undefined | null }) {
	const { classes } = useSqlRenderStyles();

	// Only render first 100 characters of the SQL query
	const sql = record.sql || '';

	const truncatedSql = sql.length > 100 ? `${sql.slice(0, 100)}...` : sql;

	return (
		<RichTooltip
			title="SQL"
			withinPortal
			body={
				<Box
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Prism
						classNames={{
							root: classes.prismRoot,
							code: classes.prismCode,
							scrollArea: classes.prismScrollArea,
						}}
						language="sql"
						trim
					>
						{sql}
					</Prism>
				</Box>
			}
		>
			<Text size="sm" truncate>
				{truncatedSql}
			</Text>
		</RichTooltip>
	);
}

export function CostRender({
	record,
}: {
	record: {
		estimated_cost?: number | undefined | null;
	};
}) {
	const { estimated_cost } = record;

	return (
		<Text size="sm" color="text/primary/default">
			{estimated_cost ? `$${estimated_cost.toFixed(2)}` : 'N/A'}
		</Text>
	);
}

export function RuntimeRender({
	record,
}: {
	record: { runtime?: number | undefined | null };
}) {
	const { runtime } = record;

	return (
		<Text size="sm" color="text/primary/default">{`${runtime || 0} ms`}</Text>
	);
}

export function LastUpdatedRender<T>({
	record,
	field,
}: {
	record: T;
	field: keyof T;
}) {
	return <DateRender value={get(record, field)} />;
}

export function DurationRender<T>({
	record,
	field1,
	field2,
}: {
	record: T;
	field1: keyof T;
	field2: keyof T;
}) {
	const { classes } = useStyles({});

	const createdAt = get(record, field1);
	const finishedAt = get(record, field2);

	if (createdAt && finishedAt) {
		const durationSeconds = Math.abs(
			getEpoch(createdAt) - getEpoch(finishedAt)
		);

		const minutes = Math.floor(durationSeconds / 60);
		const seconds = durationSeconds % 60;

		return (
			<Text
				role="link"
				size="sm"
				sx={{
					lineHeight: `${ROW_HEIGHT}px`,
				}}
				className={classes.ellipsis}
				color="text/primary/default"
			>
				{`${minutes > 0 ? `${minutes}m` : ''} ${seconds}s`}
			</Text>
		);
	}

	return null;
}

export function SQLTitleRender(record: {
	title?: string | undefined | null;
	dialect?: string | undefined | null;
}) {
	const { classes } = useStyles({});
	const queryTitle = capitalize(record.title || `${record.dialect} Query`);

	return (
		<Text
			size="sm"
			sx={{
				lineHeight: `${ROW_HEIGHT}px`,
			}}
			className={classes.ellipsis}
			color="text/primary/default"
			fw={600}
		>
			{queryTitle}
		</Text>
	);
}

export function DataQualityRender<T>({
	record,
}: {
	record: T & { dqs?: DataQualityScore };
}) {
	const ref = useRef<HTMLDivElement>(null);

	return <DataQualityBadgeWithHover dqs={record?.dqs} ref={ref} />;
}

export function TotalRunTimeRender(record: {
	total_runtime?: number | undefined | null;
}) {
	const { total_runtime } = record;

	const textWithUnit = useMemo(() => {
		if (total_runtime) {
			if (total_runtime < 1000) {
				return `${total_runtime.toFixed(0)} ms`;
			} else if (total_runtime < 60000) {
				return `${(total_runtime / 1000).toFixed(0)} s`;
			} else if (total_runtime < 3600000) {
				return `${(total_runtime / 60000).toFixed(2)} min`;
			} else {
				return `${(total_runtime / 3600000).toFixed(2)} h`;
			}
		}

		return 'N/A';
	}, [total_runtime]);

	return (
		<Text size="sm" color="text/primary/default">
			{textWithUnit}
		</Text>
	);
}

export function AverageRunTimeRender(record: {
	average_runtime?: number | undefined | null;
}) {
	const { average_runtime } = record;

	const textWithUnit = useMemo(() => {
		if (average_runtime) {
			if (average_runtime < 1000) {
				return `${average_runtime.toFixed(0)} ms`;
			} else if (average_runtime < 60000) {
				return `${(average_runtime / 1000).toFixed(0)} s`;
			} else {
				return `${(average_runtime / 60000).toFixed(2)} min`;
			}
		}

		return 'N/A';
	}, [average_runtime]);

	return (
		<Text size="sm" color="text/primary/default">
			{textWithUnit}
		</Text>
	);
}

export function TotalRunsRender(record: {
	total_runs?: number | undefined | null;
}) {
	const { total_runs } = record;

	const textWithUnit = useMemo(() => {
		if (total_runs) {
			return `${total_runs}`;
		}

		return 'N/A';
	}, [total_runs]);

	return (
		<Text size="sm" color="text/primary/default">
			{textWithUnit}
		</Text>
	);
}

export function ReadOnlyQueryFrequentUsersRender(record: {
	frequent_users?: LiteUser[] | undefined | null;
}) {
	if (!record.frequent_users) {
		return null;
	}

	const { frequent_users } = record;
	return <ReadOnlyOwnersRender owners={frequent_users as unknown as IUser[]} />;
}

export function DateTimeRender({
	record,
	field,
}: {
	record: Automation;
	field: string;
}) {
	const datetimeValue = get(record, field);

	if (
		!datetimeValue ||
		(record.status === 'disabled' && field === 'next_run_at')
	) {
		return null;
	}

	return (
		<Text size="sm" style={{ whiteSpace: 'nowrap' }}>
			{dayjs(datetimeValue).format('MMM D, YYYY [at] h:mm A')}
		</Text>
	);
}

export function SynonymsRender({
	synonyms,
	onChange,
}: {
	synonyms: Array<string> | undefined | null;
	onChange?: (value: Array<string>) => void;
}) {
	return <SynonymsSelector synonyms={synonyms ?? []} onChange={onChange} />;
}

export function DatePickerRender<T extends ISecodaEntity>({
	record,
	field,
	label,
	onChange,
}: {
	record: T;
	field: string;
	label?: string;
	onChange?: (id: string) => (value: string) => void;
}) {
	// eslint-disable-next-line no-param-reassign
	label = label ?? field;
	const { id } = record;
	const value = get(record, field) as DateValue;
	const { write } = useTeamPermission();

	const { classes: descriptionStyles } = useDescriptionStyles({
		disabled: !write,
	});

	const localValueRef = useRef(dayjs(value).toDate() as DateValue);
	const [displayValue, setDisplayValue] = useState(
		dayjs(value).toDate() as DateValue
	);

	const handleSave = useCallback(() => {
		onChange?.(id)(localValueRef.current?.toISOString() ?? '');
		setDisplayValue(localValueRef.current);
		closeAllModals();
	}, [id, localValueRef, onChange]);

	const handleModal = useCallback(() => {
		if (!onChange) return;

		openConfirmModal({
			withCloseButton: true,
			withinPortal: false,
			closeOnClickOutside: true,
			onConfirm: handleSave,
			onCancel: () => {
				closeAllModals();
				localValueRef.current = value;
			},
			labels: {
				confirm: 'Save',
				cancel: 'Cancel',
			},
			title: (
				<Group spacing="2xs">
					<Text size="md" weight="bold">
						Edit {label}
					</Text>
				</Group>
			),
			children: (
				<DatePicker
					defaultValue={value}
					onChange={(newValue) => {
						newValue && (localValueRef.current = newValue);
					}}
				/>
			),
		});
	}, [handleSave, label, onChange, value]);

	return (
		<UnstyledButton
			className={descriptionStyles.wrapper}
			onClick={write ? handleModal : undefined}
		>
			<Text
				style={NO_WRAP_STYLE}
				className={descriptionStyles.markdown}
				size="sm"
			>
				{dayjs(displayValue).format('MMM D, YYYY')}
			</Text>
		</UnstyledButton>
	);
}

export function NumberRender<T extends ISecodaEntity>({
	record,
	field,
	label,
	onChange,
}: {
	record: T;
	field: string;
	label?: string;
	onChange?: (id: string) => (value: number) => void;
}) {
	// eslint-disable-next-line no-param-reassign
	label = label ?? field;
	const { id } = record;
	const { write } = useTeamPermission();
	const value = get(record, field) as number;

	const { classes: descriptionStyles } = useDescriptionStyles({
		disabled: !write,
	});

	const localValueRef = useRef(value);
	const [displayValue, setDisplayValue] = useState(value);

	const handleSave = useCallback(() => {
		onChange?.(id)(localValueRef.current);
		setDisplayValue(localValueRef.current);
		closeAllModals();
	}, [id, localValueRef, onChange]);

	const handleModal = useCallback(() => {
		if (!onChange) return;

		openConfirmModal({
			withCloseButton: true,
			withinPortal: false,
			closeOnClickOutside: true,
			onConfirm: handleSave,
			onCancel: () => {
				closeAllModals();
				localValueRef.current = value;
			},
			labels: {
				confirm: 'Save',
				cancel: 'Cancel',
			},
			title: (
				<Group spacing="2xs">
					<Text size="md" weight="bold">
						Edit {label}
					</Text>
				</Group>
			),
			children: (
				<NumberInput
					defaultValue={displayValue}
					onChange={(newValue: number | undefined) => {
						newValue && (localValueRef.current = newValue);
					}}
				/>
			),
		});
	}, [displayValue, handleSave, label, onChange, value]);

	return (
		<UnstyledButton
			className={descriptionStyles.wrapper}
			onClick={write ? handleModal : undefined}
		>
			<Text
				style={NO_WRAP_STYLE}
				className={descriptionStyles.markdown}
				size="sm"
			>
				{displayValue}
			</Text>
		</UnstyledButton>
	);
}

export function ActivityLogResourceRender({
	record,
}: {
	record: ActivityLogOut;
}) {
	const { resource_id, resource_type } = record;

	if (!resource_id || !resource_type) {
		return null;
	}

	return <div>{resource_id}</div>;
}

export function ActivityLogEventRender({ record }: { record: ActivityLogOut }) {
	const eventBlocks = record.event_blocks;

	if (!eventBlocks) {
		return null;
	}

	return (
		<Text>
			{eventBlocks.map((block) => {
				if (block.type === 'text' && block.style === 'normal') {
					return <Text key={block.value}>{block.value}</Text>;
				}

				if (block.type === 'text' && block.style === 'bold') {
					return (
						<Text key={block.value} weight="bold">
							{block.value}
						</Text>
					);
				}

				return null;
			})}
		</Text>
	);
}
