import { Group } from '@mantine/core';
import type { FilterView } from '@repo/common/components/Filter/types';
import { Button, Icon, Title } from '@repo/foundations';
import { isNil, size } from 'lodash-es';
import { toJS } from 'mobx';
import { useCallback, useEffect, useMemo } from 'react';
import type { SearchFilterV2Store } from '../../Filter/store';
import { FilterViewModal } from '../../Filter/Views/FilterViewModal';
import { FilterViews } from '../../Filter/Views/FilterViews';
import { useFilterViews } from '../../Filter/Views/useFilterViews';
import { closeAllModals, openModal } from '../../ModalManager';
import { useNavBar } from '../../PrivateRoute/NavBarContext';

interface UseFilterViewsNavbarProps {
	showFilterViews: boolean;
	searchFilterV2Store: SearchFilterV2Store;
}

export function useFilterViewsNavbar({
	showFilterViews,
	searchFilterV2Store,
}: UseFilterViewsNavbarProps) {
	const { views } = useFilterViews();

	const handleOpenFilterViewModal = useCallback(
		(view: FilterView | null) => {
			openModal({
				title: view ? 'Edit view' : 'Save view',
				children: (
					<FilterViewModal
						filterOptions={searchFilterV2Store.filterOptions}
						onClose={(newView?: FilterView | null) => {
							if (newView) {
								searchFilterV2Store.setFilterView(newView);
							}
							closeAllModals();
						}}
						view={view ?? searchFilterV2Store.view}
						selectedFilters={toJS(searchFilterV2Store.values)}
						selectedSort={toJS(searchFilterV2Store.tableSort)}
					/>
				),
			});
		},
		[searchFilterV2Store]
	);

	const { setLeftNode, setRightNode } = useNavBar();

	const rightNode = useMemo(() => {
		if (
			showFilterViews &&
			searchFilterV2Store.valuesDiffersFromViewValues &&
			(isNil(searchFilterV2Store.view) || searchFilterV2Store.view?.isOwner)
		) {
			return (
				<Group spacing={'xs'}>
					<Button
						size="md"
						variant="tertiary"
						onClick={() => {
							searchFilterV2Store.setValues(searchFilterV2Store.viewValues);
							searchFilterV2Store.setTableSort(
								searchFilterV2Store.viewTableSort
							);
						}}
					>
						Cancel
					</Button>
					<Button
						size="md"
						onClick={() => handleOpenFilterViewModal(searchFilterV2Store.view)}
					>
						Save view
					</Button>
				</Group>
			);
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		handleOpenFilterViewModal,
		searchFilterV2Store,
		searchFilterV2Store.valuesDiffersFromViewValues,
		showFilterViews,
	]);

	const leftNode = useMemo(() => {
		if (showFilterViews && size(views) > 0) {
			return (
				<FilterViews
					handleEdit={handleOpenFilterViewModal}
					onChange={searchFilterV2Store.setFilterView}
					value={searchFilterV2Store.view}
					renderTarget={(view, _, toggle) => (
						<Button
							variant="tertiary"
							onClick={toggle}
							data-testid="search-view-button"
						>
							<Title weight="semibold" size="sm">
								{view ? view.label : 'All results'}
							</Title>
							<Icon
								color="icon/primary/default"
								name={'selector'}
								iconPadding={0}
							/>
						</Button>
					)}
				/>
			);
		}
		return null;
	}, [
		handleOpenFilterViewModal,
		searchFilterV2Store.setFilterView,
		searchFilterV2Store.view,
		showFilterViews,
		views,
	]);

	useEffect(() => {
		setLeftNode(leftNode);
		setRightNode(rightNode);
	}, [leftNode, rightNode, setLeftNode, setRightNode]);
}
