import { EntityType } from '@repo/common/enums/entityType';
import { Icon } from '@repo/foundations';
import { filter } from 'lodash-es';
import type { Location } from 'react-router';
import type { ISecodaEntity } from '../../api';
import type { TreeNodeBase } from './components/TreeNode';

export const isActive = (href: string[], location: Location, exact = false) =>
	filter(href, (h) => {
		if (h === '/' && location.pathname === '/') {
			return true;
		}
		if (h !== '/') {
			if (exact) {
				return location.pathname === h;
			}

			return location.pathname.includes(h);
		}
		return false;
	}).length > 0;

export type CommonEntityTreeNode = TreeNodeBase & {
	entity: Pick<
		ISecodaEntity,
		'id' | 'entity_type' | 'icon' | 'title' | 'title_cased'
	>;
};

export const getEntityIconByType = (type: EntityType) => {
	switch (type) {
		case EntityType.table:
			return <Icon name="table" color="icon/primary/default" />;
		case EntityType.column:
			return <Icon name="columns" color="icon/primary/default" />;
		case EntityType.dashboard:
			return <Icon name="presentationAnalytics" color="icon/primary/default" />;
		case EntityType.chart:
			return <Icon name="chartBar" color="icon/primary/default" />;
		case EntityType.job:
			return <Icon name="clock" color="icon/primary/default" />;
		case EntityType.collection:
			return <Icon name="folder" color="icon/primary/default" />;
		case EntityType.dictionary_term:
			return <Icon name="book" color="icon/primary/default" />;
		case EntityType.metric:
			return <Icon name="chartHistogram" color="icon/primary/default" />;
		case EntityType.document:
			return <Icon name="file" color="icon/primary/default" />;
		case EntityType.event:
			return <Icon name="click" color="icon/primary/default" />;
		case EntityType.question:
			return <Icon name="messageCircleQuestion" color="icon/primary/default" />;
		case EntityType.schema:
			return <Icon name="schema" color="icon/primary/default" />;
		case EntityType.database:
			return <Icon name="database" color="icon/primary/default" />;
		default:
			return <Icon name="folder" color="icon/primary/default" />;
	}
};
