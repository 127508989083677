import {
	createStyles,
	Flex,
	Skeleton,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { EntityType } from '@repo/common/enums/entityType';
import { Button, Text } from '@repo/foundations';
import { useAuthUser, useUser } from '../../../../../../../api';
import { useQuestionList } from '../../../../../../../api/hooks/question';
import { useTemplateList } from '../../../../../../../api/hooks/templates';
import type { IQuestion } from '../../../../../../../api/types';
import { entityModalStore } from '../../../../../../../components/EntityModal/store';
import { UserAvatar } from '../../../../../../../components/UserAvatar';
import { WidgetType } from '../../../../../../../interfaces';
import { WIDGET_CONTENT_HEIGHT } from '../../../constants';
import type { IWidgetItemProps, QuestionsWidgetTypes } from '../../../types';

const useRowStyles = createStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',

		gap: theme.spacing.sm,
		padding: theme.spacing.sm,
		borderRadius: theme.other.space[2],

		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
	},
}));

function QuestionRow({ question }: { question: IQuestion }) {
	const { classes } = useRowStyles();
	// The "asker" of a question if the first "owner"
	const ownerId = question.owners?.[0];
	const timeAgo = dayjs(question.created_at).fromNow(false);
	const { title, children_count: childrenCount } = question;
	const repliesText =
		childrenCount > 0 ? `${childrenCount} replies` : 'No replies';
	const { data: user, isLoading } = useUser({
		id: ownerId,
		options: {
			enabled: !isNil(ownerId),
		},
	});

	if (isLoading) {
		return (
			<Flex className={classes.container}>
				<Skeleton />
			</Flex>
		);
	}

	return (
		<UnstyledButton
			className={classes.container}
			component={Link}
			to={`/questions/${question.id}`}
		>
			{user ? <UserAvatar size="md" user={user} /> : <Flex w="28px" />}
			<Flex direction="column">
				<Text
					size="sm"
					color="text/primary/default"
					weight="bold"
					style={{ whiteSpace: 'unset' }}
				>
					{title}
				</Text>
				<Flex gap="3xs">
					<Text size="xs" color="text/secondary/default">
						{timeAgo}
					</Text>
					<Text size="xs" color="text/secondary/default">
						·
					</Text>
					<Text size="xs" color="text/secondary/default">
						{repliesText}
					</Text>
				</Flex>
			</Flex>
		</UnstyledButton>
	);
}

export type QuestionsWidgetProps = IWidgetItemProps;

function QuestionsWidget({ widget }: QuestionsWidgetProps) {
	const { user } = useAuthUser();
	const widgetType = widget.type as QuestionsWidgetTypes;

	const questionFilters: Record<string, string> = {};

	if (widgetType === WidgetType.QUESTIONS_BOOKMARKED) {
		questionFilters.bookmarked_by__id = user.id;
	}

	const { data: defaultTemplates } = useTemplateList({
		filters: {
			is_default: true,
		},
		options: {
			// Disable suspense, in case the default template is not found. We do not
			// want to trigger a suspense fallback error.
			suspense: false,
		},
	});

	const { data: questions } = useQuestionList({
		options: {
			suspense: false,
			enabled: widgetType === WidgetType.QUESTIONS_BOOKMARKED,
		},
		filters: questionFilters,
	});

	const questionsToDisplay: Array<IQuestion> =
		widgetType === WidgetType.QUESTIONS_BOOKMARKED
			? (questions?.results ?? [])
			: (widget.data?.results ?? []);

	const handleAskAQuestion = useCallback(() => {
		entityModalStore.open({
			type: EntityType.question,
			template: defaultTemplates?.results?.[0],
			metadata: {
				owners: [user.id],
			},
		});
	}, [defaultTemplates?.results, user]);

	if (!questionsToDisplay || questionsToDisplay.length <= 0) {
		return (
			<Stack spacing="md" h={WIDGET_CONTENT_HEIGHT} justify="center">
				<Flex gap={0} align="center" direction="column">
					<Text size="sm" weight="bold">
						No one has asked your team any questions
					</Text>
					<Text size="sm">Be the first to ask your team a question.</Text>
				</Flex>
				<Flex justify="center">
					<Button
						leftIconName="messageCircleQuestion"
						onClick={handleAskAQuestion}
					>
						Ask your team a question
					</Button>
				</Flex>
			</Stack>
		);
	}

	return (
		<Flex gap="none" h={WIDGET_CONTENT_HEIGHT} direction="column">
			{questionsToDisplay.map((question) => (
				<QuestionRow key={question.id} question={question} />
			))}
		</Flex>
	);
}

export default QuestionsWidget;
