import type { Filter } from '@repo/api-codegen';
import { FILTER_OPTIONS_DIVIDER } from '@repo/common/components/Filter/constants';
import type {
	AddedFilterResult,
	FilterOption,
	FilterValue,
	FilterView,
	TopLevelOperatorType,
} from '@repo/common/components/Filter/types';
import {
	FilterDropdownType,
	FilterOptionType,
	SortValue,
} from '@repo/common/components/Filter/types';
import {
	getApiCatalogFilterFromFilterValues,
	legacyFilterToFilterValue,
	parseFilterValuesFromLocalStorage,
} from '@repo/common/components/Filter/utils';
import type { ApiCatalogSort } from '@repo/common/interfaces/params';
import type { DataTableSortStatus } from '@repo/mantine-datatable';
import { isEmpty, isEqual, size } from 'lodash-es';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { createContext } from 'react';
import { trackEvent } from '../../utils/analytics';

export interface SearchFilterV2StoreOptions {
	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[];
	initialValues?: FilterValue[];
	preferencesLocalStorageKey?: string | null;
	urlParamToSyncFilters?: string | null;
	excludeItems?: { [key in FilterOptionType]?: string[] };
	onFilterChanged?: (values: FilterValue[]) => void;
	onSortChanged?: (sort?: DataTableSortStatus) => void;
	initialSort?: DataTableSortStatus | undefined;
}

export class SearchFilterV2Store {
	// The sort value used on the search page.
	sort: SortValue;

	// Allow sorting by any arbitrary 'column' on a table.
	tableSort: DataTableSortStatus | undefined;

	// The sort value from the current view.
	viewTableSort: DataTableSortStatus | undefined;

	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[];

	values: FilterValue[];

	// Not used for rendering, but for keeping track of the previous view values
	// so we can revert to them if (1) the view is reset or if we should show
	// the save button.
	viewValues: FilterValue[] = [];

	preferencesLocalStorageKey: string | null;

	view: FilterView | null = null;

	topLevelOperator: TopLevelOperatorType = 'and';

	urlParamToSyncFilters: string | null = null;

	catalogFilter: Filter | undefined;

	// This is used to restrict the filters that are shown in the filter bar. It
	// is used to prevent the user from adding filters that are not supported by
	// the table.
	excludeItems: { [key in FilterOptionType]?: string[] };

	// This is called when the filter values are changed by an user's action.
	// This should not be called when the filter values are changed by a view selection
	onFilterChanged?: (values: FilterValue[]) => void;

	// This is called when the sort is changed by an user's action.
	// This should not be called when the sort is changed by a view selection
	onSortChanged?: (sort?: DataTableSortStatus) => void;

	constructor({
		filterOptions,
		initialValues = [],
		preferencesLocalStorageKey = null,
		urlParamToSyncFilters = null,
		excludeItems = {},
		onFilterChanged,
		onSortChanged,
		initialSort,
	}: SearchFilterV2StoreOptions) {
		makeAutoObservable(this);

		this.preferencesLocalStorageKey = preferencesLocalStorageKey;
		this.urlParamToSyncFilters = urlParamToSyncFilters;
		this.excludeItems = excludeItems;
		this.onFilterChanged = onFilterChanged;
		this.onSortChanged = onSortChanged;
		this.filterOptions = filterOptions;

		this.sort = SortValue.RELEVANCE;
		this.tableSort = initialSort;
		this.viewTableSort = undefined;
		if (isEmpty(initialValues) && preferencesLocalStorageKey) {
			// Try to use the saved filter preferences from the local storage.
			this.values = parseFilterValuesFromLocalStorage(
				preferencesLocalStorageKey
			);
		} else {
			this.values = initialValues;
		}
		this.refreshCachedCatalogFilter();
	}

	reset() {
		this.sort = SortValue.RELEVANCE;
		this.tableSort = undefined;
		this.viewTableSort = undefined;
		this.values = [];

		this.onFilterChanged?.(this.values);
		this.onSortChanged?.(this.tableSort);
		this.refreshCachedCatalogFilter();
	}

	prefetchPromises = () => {
		this.filterOptions.forEach((option) => {
			if (
				option === FILTER_OPTIONS_DIVIDER ||
				option.filterDropdownConfig.dropdownType !== FilterDropdownType.List
			) {
				return;
			}

			const { getItems } = option.filterDropdownConfig;

			if (typeof getItems !== 'function') {
				return;
			}

			Promise.resolve(getItems());
		});
	};

	setValues = (values: FilterValue[]) => {
		this.values = values;
		this.refreshCachedCatalogFilter();
	};

	setSort = (sort: SortValue) => {
		this.sort = sort;
	};

	setTableSort = (sort: DataTableSortStatus | undefined) => {
		this.tableSort = sort;
		this.onSortChanged?.(sort);
	};

	get valuesDiffersFromViewValues() {
		const filtersDiff =
			size(this.values) > 0 && !isEqual(this.values, this.viewValues);
		const sortDiff = !isEqual(this.tableSort, this.viewTableSort);
		return filtersDiff || sortDiff;
	}

	setFilterView = (view: FilterView | null) => {
		this.view = view;

		if (view) {
			runInAction(async () => {
				if (view.selected_sort_by && view.selected_sort_direction) {
					this.tableSort = {
						columnAccessor: view.selected_sort_by,
						direction: view.selected_sort_direction,
					};
					this.viewTableSort = this.tableSort;
				} else {
					this.tableSort = undefined;
					this.viewTableSort = undefined;
				}
				this.values = await legacyFilterToFilterValue(
					this.filterOptions,
					view.filters
				);
				this.viewValues = [...this.values];
				this.refreshCachedCatalogFilter();
			});
		} else {
			runInAction(() => {
				this.tableSort = undefined;
				this.viewTableSort = undefined;
				this.viewValues = [];
				this.values = [];
				this.refreshCachedCatalogFilter();
			});
		}
	};

	setTopLevelOperator = (operator: TopLevelOperatorType) => {
		runInAction(() => {
			this.topLevelOperator = operator;
		});
	};

	onAddValue = (value: FilterValue): AddedFilterResult => {
		const filterIdx = runInAction(() => {
			this.values = [...this.values, value];
			return this.values.length - 1;
		});

		this.onFilterChanged?.(this.values);
		this.refreshCachedCatalogFilter();

		// If ai filters, send a different event
		if (value.filterType === FilterOptionType.AI) {
			trackEvent('ai/filters/apply', {
				url: location.pathname,
				filter: value.filterType ?? '',
				operator: value.operator ?? '',
				value: JSON.stringify(value.value),
			});
		} else {
			trackEvent('filters/select', {
				url: location.pathname,
				filter: value.filterType ?? '',
				operator: value.operator ?? '',
				value: JSON.stringify(value.value),
			});
		}

		return {
			value: this.values[filterIdx],
			changeFilter: this.onChangeValue(filterIdx),
			clearFilter: this.onClearValue(filterIdx),
		};
	};

	onChangeValue = (idx: number) => (value: Partial<FilterValue>) => {
		if (
			Array.isArray(value.value) &&
			value.value.length === 0 &&
			!value.isNotSetApplied &&
			!value.isSetApplied
		) {
			this.onClearValue(idx)();
			return;
		}

		runInAction(() => {
			const tempArr = [...this.values];
			tempArr[idx] = {
				...this.values[idx],
				...value,
			};
			this.values = [...tempArr];
		});

		this.onFilterChanged?.(this.values);
		this.refreshCachedCatalogFilter();

		trackEvent('filters/select', {
			url: location.pathname,
			filter: value.filterType ?? '',
			operator: value.operator ?? '',
			value: JSON.stringify(value.value),
		});
	};

	onClearValue = (idx: number) => () => {
		runInAction(() => {
			const tempArr = [...this.values];
			tempArr.splice(idx, 1);
			this.values = [...tempArr];
		});

		this.onFilterChanged?.(this.values);
		this.refreshCachedCatalogFilter();
	};

	refreshCachedCatalogFilter = async () => {
		const allValues = toJS(this.values);

		const computedCatalogFilter = await getApiCatalogFilterFromFilterValues(
			this.filterOptions,
			allValues,
			this.topLevelOperator
		);

		runInAction(async () => {
			this.catalogFilter = computedCatalogFilter;
		});
	};

	get catalogSort(): ApiCatalogSort | undefined {
		return {
			field: this.sort.toString(),
			order: 'desc',
		};
	}
}

export const SearchFilterV2StoreContext = createContext<SearchFilterV2Store>(
	new SearchFilterV2Store({ filterOptions: [] })
);
