import { Timeline } from '@mantine/core';
import { ActivityItemSkeleton } from './ActivityItemSkeleton';
import { useStyles } from './ActivityLogV2.styles';
import { BULLET_SIZE } from './constants';

export function ActivityLogV2Skeleton() {
	const { classes } = useStyles();

	return (
		<Timeline bulletSize={BULLET_SIZE} lineWidth={2} classNames={classes}>
			<ActivityItemSkeleton />
			<ActivityItemSkeleton />
		</Timeline>
	);
}
