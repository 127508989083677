import { Group } from '@mantine/core';
import { Icon, Title } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import type { ISecodaSlackChannel } from '../../../api';
import { TeamRender, TextRender } from '../../TableV2/render.tsx';
import type { ExtendedDataTableColumn } from '../../TableV2/types.ts';

export const useColumns = (): ExtendedDataTableColumn<ISecodaSlackChannel>[] =>
	useMemo(
		() => [
			{
				accessor: 'title',
				title: 'Title',
				width: 200,
				sortable: false,
				render: (channel) => (
					<Group spacing="xs" noWrap>
						<Icon name="hash" />
						<Title size="sm" truncate>
							{isEmpty(channel.title) ? 'Untitled' : channel.title}
						</Title>
					</Group>
				),
			},
			{
				accessor: 'teams',
				title: 'Teams',
				render: (record) => <TeamRender record={record} />,
			},
			{
				accessor: 'channel_type',
				title: 'Type',
				render: (record) => <TextRender record={record} field="channel_type" />,
			},
		],
		[]
	);
