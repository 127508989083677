import { Group, Stack, createStyles } from '@mantine/core';
import type { PropsWithChildren, ReactNode } from 'react';

const useStyles = createStyles((theme) => ({
	avatarWrapper: {
		width: theme.other.space[10],
	},
	contentWrapper: {
		width: `calc(100% - ${theme.other.space[10]}px)`,
	},
	messageBody: {
		alignItems: 'flex-start',
	},
}));

interface MessageBodyProps {
	avatar: ReactNode;
}

export function MessageBody({
	avatar,
	children,
}: PropsWithChildren<MessageBodyProps>) {
	const { classes } = useStyles();

	return (
		<Stack spacing={0}>
			<Group spacing={0} className={classes.messageBody}>
				<div className={classes.avatarWrapper}>{avatar}</div>
				<div className={classes.contentWrapper}>{children}</div>
			</Group>
		</Stack>
	);
}
