import { filter } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type { IReport } from '../../api';
import { reportsQueryKeyFactory, useDeleteReport } from '../../api';
import type { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';
import { useDeleteManyAction } from '../../components/TableV2/BaseModel.hooks';
import { useGenericColumns } from '../../components/TableV2/SecodaEntity.hooks';
import { BoldTextRender } from '../../components/TableV2/render';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

export function useColumns<T extends IReport>(): ExtendedDataTableColumn<T>[] {
	const genericColumns = useGenericColumns({}) as ExtendedDataTableColumn<T>[];

	const columns = useMemo(
		() => [
			{
				accessor: 'name',
				title: 'Name',
				render: (record) => <BoldTextRender record={record} field={'name'} />,
			},
			...filter(genericColumns, (column) =>
				['updated_at'].includes(column.accessor)
			),
		],
		[genericColumns]
	);

	return columns;
}

export const useActions = (): ICommandListItem<IReport>[] => {
	const { mutateAsync: deleteReport } = useDeleteReport({});
	const onDelete = useCallback(
		(id: string) => deleteReport({ id }),
		[deleteReport]
	);

	const { action } = useDeleteManyAction<IReport>({
		name: 'report',
		queryKeys: reportsQueryKeyFactory.allLists(),
		onDelete,
	});

	const actions = useMemo(() => [action], [action]);

	return actions;
};
