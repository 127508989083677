import {
	Group,
	Tooltip,
	UnstyledButton,
	createStyles,
	getStylesRef,
} from '@mantine/core';
import { getHotkeyHandler, useDisclosure, useHotkeys } from '@mantine/hooks';
import { Icon, ListBox, Text } from '@repo/foundations';
import { useCallback, useMemo } from 'react';
import { KeyboardShortcut } from '../../KeyboardShortcut/KeyboardShortcut';
import type { FILTER_OPTIONS_DIVIDER } from '../constants';
import { FilterDropdown } from '../FilterDropdown/FilterDropdown';
import type { AddedFilterResult, FilterOption, FilterValue } from '../types';
import { AddFilterDropdown } from './AddFilterDropdown';
import { useAddFilter } from './useAddFilter';

const useStyles = createStyles((theme) => ({
	target: {
		ref: getStylesRef('target'),

		border: `1px dashed ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.sm,
		padding: `${theme.spacing['5xs']} ${theme.spacing['2xs']} ${theme.spacing['5xs']} ${theme.spacing.sm}`,

		backgroundColor: theme.other.getColor('fill/primary/default'),

		'&:hover, &:focus': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},

		'&:active': {
			backgroundColor: theme.other.getColor('fill/primary/active'),
		},
	},
	targetText: {
		fontSize: theme.fontSizes.xs,
		lineHeight: theme.spacing.md,
	},
	targetIcon: {
		color: theme.other.getColor('icon/primary/default'),

		[`.${getStylesRef('target')} &:hover, .${getStylesRef('target')} &:focus`]:
			{
				color: theme.other.getColor('icon/primary/hover'),
			},

		[`.${getStylesRef('target')} &:active`]: {
			color: theme.other.getColor('icon/primary/active'),
		},
	},
}));

export interface AddFilterProps {
	options: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[];
	onAddFilter: (value: FilterValue) => AddedFilterResult;
	withTooltip?: boolean;
}

export function AddFilter({
	options,
	onAddFilter,
	withTooltip = true,
}: AddFilterProps) {
	const { classes } = useStyles();
	const [opened, { toggle, close, open }] = useDisclosure();

	const {
		selectedFilterOption,
		setSelectedFilterOption,
		filterValue,
		reset,
		handleAddFilter,
	} = useAddFilter({ onAddFilter, close });

	const onToggle = useCallback(() => {
		reset();
		toggle();
	}, [reset, toggle]);

	useHotkeys([['F', onToggle]]);

	const handleOnOpenChange = useCallback(
		(newOpen: boolean) => {
			if (newOpen) {
				reset();
				open();
			} else {
				close();
			}
		},
		[close, open, reset]
	);

	const handleEscapeKey = useCallback(
		(e: React.KeyboardEvent<HTMLElement> | KeyboardEvent) => {
			e.preventDefault();
			e.stopPropagation();
			if (selectedFilterOption) {
				reset();
			} else {
				close();
			}
		},
		[close, reset, selectedFilterOption]
	);

	const memoizedHotkeyHandler = useMemo(
		() => getHotkeyHandler([['Escape', handleEscapeKey]]),
		[handleEscapeKey]
	);

	return (
		<ListBox
			opened={opened}
			onOpenChange={handleOnOpenChange}
			closeOnEscape={false}
			onKeyDown={memoizedHotkeyHandler}
			autoAdjustPosition={false}
		>
			<ListBox.Target>
				<Tooltip
					disabled={!withTooltip}
					label={<KeyboardShortcut keys={['F']}>Add filter</KeyboardShortcut>}
				>
					<UnstyledButton
						data-testid="filter-button"
						onClick={onToggle}
						className={classes.target}
					>
						<Group spacing={0}>
							<Text className={classes.targetText}>Add filter</Text>
							<Icon name="plus" className={classes.targetIcon} />
						</Group>
					</UnstyledButton>
				</Tooltip>
			</ListBox.Target>

			{opened && selectedFilterOption && (
				<FilterDropdown
					value={
						filterValue ?? {
							value: null,
							operator:
								selectedFilterOption.filterDropdownConfig.defaultOperator,
							filterType: selectedFilterOption.type,
						}
					}
					filterOption={selectedFilterOption}
					filterOptions={options}
					onChange={handleAddFilter}
				/>
			)}
			{opened && !selectedFilterOption && (
				<AddFilterDropdown
					options={options}
					onAddFilter={handleAddFilter}
					onItemClick={setSelectedFilterOption}
				/>
			)}
		</ListBox>
	);
}
