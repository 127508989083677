import type { Filter } from '@repo/api-codegen';
import type { SortValue } from '@repo/common/components/Filter/types';
import type { QueryKey } from '@tanstack/react-query';
import cuid from 'cuid';
import { cloneDeep, last } from 'lodash-es';
import type { ApiCatalogSort, IBaseModel } from '../../api';
import type { FetchModelList } from '../../api/factories/types';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';

type SortType = ApiCatalogSort | { field: SortValue; order: string } | null;

// Improved types with better documentation
export type NestedParams = {
	_id: string;
	paramExpandUid: string;
	paramExpandedLevel: number;
	paramExpandedParentPrefix: string;
	isLastElement?: boolean;
	paramExpanded?: boolean;
	has_child_of_same_type?: boolean;
};

export type ExpandedRecords<T> = {
	[id: string]: {
		expanded: T;
		nested: (T & NestedParams)[];
		queryKey: QueryKey;
		currentPage: number;
		currentSort?: SortType;
	};
};

export type ExpandableRecord<T> = T & {
	onExpandClick?: () => Promise<void>;
	onSortChanged?: (sort: SortType) => void;
};

// New types for better type safety
type QueryParams = {
	page: number;
	page_size: number;
	filters: Record<
		string,
		| string
		| number
		| boolean
		| Filter
		| (string | number | boolean)[]
		| ApiCatalogSort
		| undefined
	>;
};

// Improved helper functions with proper typing
const createQueryParams = (
	page: number,
	page_size: number,
	record: IBaseModel,
	nestingFilter?: string,
	defaultParams?: Record<string, string | boolean>,
	sort?: SortType
): QueryParams => ({
	page,
	page_size,
	filters: {
		calculate_children_count: true,
		...defaultParams,
		filter: {
			operator: 'and',
			operands: [
				{
					operands: [],
					field: nestingFilter,
					operator: 'in',
					value: [last(record.id?.split('.')) ?? ''],
				},
			],
		} as Filter,
		sort: sort as ApiCatalogSort,
	},
});

const getRecordPrefix = (
	record: ExpandableRecord<IBaseModel & Partial<NestedParams>>
): string =>
	record.paramExpandedParentPrefix
		? `${record.paramExpandedParentPrefix}.${record.id}`
		: record.id;

const createNestedRecord = <T extends IBaseModel>(
	record: T,
	prefix: string,
	parentLevel: number,
	index: number,
	totalLength: number
): T & NestedParams => ({
	...record,
	paramExpandedLevel: parentLevel + 1,
	paramExpandedParentPrefix: prefix,
	paramExpandUid: cuid(),
	_id: `${prefix}.${record.id}`,
	isLastElement: index === totalLength - 1,
});

const updateExpandedRecords = <T extends IBaseModel>(
	records: ExpandedRecords<T>,
	prefix: string,
	record: T & Partial<NestedParams>,
	queryKey: QueryKey,
	page: number,
	sort?: SortType,
	results?: T[],
	isExpand = true,
	forceExpanded: boolean = false
): ExpandedRecords<T> => {
	const newRecords = cloneDeep(records);

	if (newRecords[prefix]) {
		if (isExpand && !forceExpanded) {
			// Delete all that start with the given prefix
			Object.keys(newRecords).forEach((key) => {
				if (key.startsWith(prefix)) {
					delete newRecords[key];
				}
			});
			return newRecords;
		}
	}

	if (isExpand && results) {
		// Create new expanded record with all results
		newRecords[prefix] = {
			queryKey,
			expanded: record,
			currentPage: page,
			currentSort: sort,
			nested: results.map((r, index) =>
				createNestedRecord(
					r,
					prefix,
					record.paramExpandedLevel ?? 0,
					index,
					results.length
				)
			),
		};
	}
	return newRecords;
};

const handleExpand = async <
	T extends IBaseModel & { has_child_of_same_type?: boolean },
>(
	record: T & Partial<NestedParams>,
	prefix: string,
	queryParams: QueryParams,
	queryKey: QueryKey,
	fetchPaginationList?: FetchModelList<T>,
	setExpandedRecords?: (
		arg0: (prev: ExpandedRecords<T>) => ExpandedRecords<T>
	) => void,
	forceExpanded: boolean = false
): Promise<void> => {
	// Fetch all items at once with a larger page size
	const result = await fetchPaginationList?.({
		...queryParams,
		filters: {
			...queryParams.filters,
		},
	});
	setExpandedRecords?.((prev) =>
		updateExpandedRecords(
			prev,
			prefix,
			record,
			queryKey,
			queryParams.page,
			queryParams.filters.sort as SortType | undefined,
			result?.results,
			true,
			forceExpanded
		)
	);
};

export const makeRecordsExpandable = <
	T extends IBaseModel & { has_child_of_same_type?: boolean },
>(
	records: T[],
	expandedRecords: ExpandedRecords<T & NestedParams>,
	setExpandedRecords: (
		arg0: (
			prev: ExpandedRecords<T & NestedParams>
		) => ExpandedRecords<T & NestedParams>
	) => void,
	nestingFilter?: string,
	defaultRequiredSearchParamsNesting?: Record<string, string | boolean>,
	fetchPaginationList?: FetchModelList<T>,
	sort?: SortType
): ExpandableRecord<T>[] =>
	records.map((record) => {
		const page = 1;
		// TODO: This is a temporary fix. We should look at paginating with children
		// on the backend, to make the frontend simpler, and we can infinite scroll
		// child elements.
		// 1200 was specifically chosen for Revantage.
		const page_size = 1200;
		const queryParams = createQueryParams(
			page,
			page_size,
			record,
			nestingFilter,
			defaultRequiredSearchParamsNesting,
			sort
		);
		const queryKey = resourceCatalogQueryKeyFactory.list(
			queryParams.page,
			queryParams.filters
		);
		const prefix = getRecordPrefix(record);

		return {
			...record,
			paramExpanded: Object.keys(expandedRecords).some((expandedRecord) =>
				expandedRecord.includes(prefix)
			),
			onExpandClick: record.has_child_of_same_type
				? () =>
						handleExpand(
							record as unknown as T & NestedParams,
							prefix,
							queryParams,
							queryKey,
							fetchPaginationList as unknown as FetchModelList<
								T & NestedParams
							>,
							setExpandedRecords
						)
				: undefined,
			onSortChanged: () => {
				if (!record.has_child_of_same_type) {
					return;
				}

				handleExpand(
					record as unknown as T & NestedParams,
					prefix,
					queryParams,
					queryKey,
					fetchPaginationList as unknown as FetchModelList<T & NestedParams>,
					setExpandedRecords,
					true
				);
			},
		};
	});
