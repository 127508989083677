import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';

import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import type { SecodaEditorComponentProps } from '@repo/secoda-editor/types';
import { ToastType } from '@repo/secoda-editor/types';
import copy from 'copy-to-clipboard';
import type { Token } from 'markdown-it';
import type { ParseSpec } from 'prosemirror-markdown';
import { NodeSelection } from 'prosemirror-state';
import type { MetricNumericFormat } from '../../../../../api';
import { BlockSkeleton } from '../components/BlockSkeleton';
import { ChartBlockContainer } from '../components/ChartBlock/ChartBlockContainer';
import type { CreateGraphModalResult } from '../components/ChartBlock/CreateGraphModal';
import chartBlocksRule from '../rules/chartblocks';
import ReactNode from './ReactNode';

export default class ChartBlock extends ReactNode {
	get name() {
		return 'chart_block';
	}

	get markdownToken() {
		return 'chart_block';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				id: {
					default: '',
				},
				xAxis: {
					default: '',
				},
				yAxis: {
					default: '',
				},
				dimension: {
					default: '',
				},
				numericFormat: {
					default: '',
				},
			},
			group: 'block',
			defining: true,
			atom: true,
			draggable: false,
			parseDOM: [
				{
					preserveWhitespace: 'full',
					tag: 'div.chartblock',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									id: dom.getAttribute('id'),
									xAxis: dom.getAttribute('data-x-axis'),
									yAxis: dom.getAttribute('data-y-axis'),
									dimension: dom.getAttribute('data-dimension'),
									numericFormat: dom.getAttribute('data-numeric-format'),
								},
				},
			],
			toDOM: (node) => [
				'div',
				{
					...node.attrs,
					class: 'chartblock',
					id: node.attrs.id,
					'data-x-axis': node.attrs.xAxis,
					'data-y-axis': node.attrs.yAxis,
					'data-dimension': node.attrs.dimension,
					'data-numeric-format': node.attrs.numericFormat,
				},
				node.attrs.id,
			],
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		const { id, xAxis, yAxis, dimension, numericFormat } = node.attrs;
		if (id) {
			state.write(
				`\n\n:;:${id}|${xAxis ?? ''}|${yAxis ?? ''}|${dimension ?? ''}|${numericFormat ?? ''}:;:\n\n`
			);
			state.closeBlock(node);
		}
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'chart_block',
			getAttrs: (token: Token) => {
				const parsedAttrs = (token.attrs ?? []).reduce(
					(acc, item: [string, string]) => {
						if (item?.length !== 2) {
							return acc;
						}

						return {
							...acc,
							[item[0]]: item[1],
						};
					},
					{} as Record<string, string>
				);

				return {
					id: parsedAttrs.id,
					xAxis: parsedAttrs.xAxis,
					yAxis: parsedAttrs.yAxis,
					dimension: parsedAttrs.dimension,
					numericFormat: parsedAttrs.numericFormat,
				};
			},
		};
	}

	get rulePlugins() {
		return [chartBlocksRule];
	}

	commands() {
		return () => () => true;
	}

	duplicateNode = (pos: number, node: ProsemirrorNode) => {
		const { view } = this.editorState;

		const emptyParagraph = view.state.schema.nodes['paragraph'].create();

		view.dispatch(
			view.state.tr
				.insert(
					pos + node.nodeSize, // after the current query block
					emptyParagraph
				)
				.insert(
					pos + node.nodeSize + emptyParagraph.nodeSize, // after the empty paragraph created above
					node.type.create(node.attrs)
				)
		);
		view.focus();
		this.options.onShowToast(
			this.options.dictionary.chartDuplicated,
			ToastType.Info
		);
	};

	copyLink = (node: ProsemirrorNode) => {
		const hash = `#metric-chart-${node.attrs.id}`;

		this.editor?.options?.onTrackEvent?.('editor/chart-block-copy-link');

		// The existing url might contain a hash already, lets make sure to remove
		// that rather than appending another one.
		const urlWithoutHash = window.location.href.split('#')[0];
		copy(urlWithoutHash + hash);
		this.options.onShowToast(
			this.options.dictionary.linkCopied,
			ToastType.Info
		);
	};

	onChangeAttrs = async (
		pos: number,
		node: ProsemirrorNode,
		result: CreateGraphModalResult
	) => {
		const { xAxis, yAxis, dimension, numericFormat } = result;
		const tr = this.editorState.view.state.tr.setNodeMarkup(pos, null, {
			...node.attrs,
			xAxis,
			yAxis,
			dimension,
			numericFormat,
		});
		this.editorState.view.dispatch(tr);
	};

	component = ({ node, getPos, isSelected }: SecodaEditorComponentProps) => {
		if (!node) {
			return <BlockSkeleton height={100} isSelected={isSelected} />;
		}

		const deleteNode = () => {
			const { view } = this.editorState;
			const $pos = view.state.doc.resolve(getPos());
			const tr = view.state.tr.setSelection(new NodeSelection($pos));
			view.dispatch(tr.deleteSelection());
			view.focus();
			this.options.onShowToast(
				this.options.dictionary.chartDeleted,
				ToastType.Info
			);
		};

		return (
			<ChartBlockContainer
				id={node.attrs.id}
				readOnly={this.editorState.readOnly}
				onDelete={() => deleteNode()}
				isSelected={isSelected}
				xAxis={node.attrs.xAxis}
				yAxis={node.attrs.yAxis}
				dimension={node.attrs.dimension}
				numericFormat={node.attrs.numericFormat as MetricNumericFormat}
				onDuplicate={() => this.duplicateNode(getPos(), node)}
				onCopyLink={() => this.copyLink(node)}
				onSave={(result) => this.onChangeAttrs(getPos(), node, result)}
			/>
		);
	};
}
