import { Box, Divider, Group, Stack, createStyles } from '@mantine/core';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';
import { IconButton, Title } from '@repo/foundations';
import { capitalize } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { Fragment, useMemo, useState } from 'react';
import { useCommentList, type IComment } from '../../../api';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import SearchBox from '../../../components/SearchBox/SearchBox';
import type { WithOnlyIdRequired } from '../../../lib/typescript';
import { useCommentStoreContext } from '../context';
import EmptySidebar from './EmptySidebar';
import FixedCommentThread from './FixedCommentThread';
import { matchSearchTerm } from './utils';

const useStyles = createStyles((theme) => ({
	wrapperStack: {
		height: '100%',
		overflowY: 'hidden',
	},
	wrapperStackHeader: {
		padding: theme.spacing.sm,
		paddingBottom: 0,
		width: '100%',
	},
	commentStack: {
		paddingTop: 0,
		overflowY: 'auto',
	},
}));

type IFixedCommentSidebarProps = {
	commentThreads: { [rootID: string]: IComment[] };
	handleCreateComment: (
		data: Partial<Omit<IComment, 'id'>>
	) => Promise<IComment>;
	handleUpdateComment: (data: WithOnlyIdRequired<IComment>) => Promise<void>;
	handleDeleteComment: (commentID: string) => Promise<void>;
};

function FixedCommentSidebar({
	commentThreads,
	handleCreateComment,
	handleUpdateComment,
	handleDeleteComment,
}: IFixedCommentSidebarProps) {
	const { classes } = useStyles();
	const { sortedCommentIds } = useCommentStoreContext();
	const { isLoading } = useCommentList({});

	const [searchOpen, { open: openSearch, close: closeSearch }] =
		useDisclosure(false);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const debouncedSearchTerm = useDebouncedValue(searchTerm, 500);
	const [filter, setFilter] = useState<'open' | 'resolved'>('open');

	//  Filter based on search term and filter.
	const filteredThreadIds: string[] = useMemo(
		() =>
			sortedCommentIds.filter((rootID) => {
				if (!matchSearchTerm(searchTerm, commentThreads[rootID])) {
					return false;
				}

				if (!commentThreads[rootID]) {
					return false;
				}

				if (filter === 'open' && commentThreads[rootID][0].resolved) {
					return false;
				}
				if (filter === 'resolved' && !commentThreads[rootID][0].resolved) {
					return false;
				}
				return true;
			}),
		[debouncedSearchTerm]
	);

	const filterOptions = ['open', 'resolved'].map((option) => ({
		label: `${capitalize(option)} comments`,
		value: option,
	}));

	const filterDropdown = (
		<SingleSelector
			width={200}
			target={
				<IconButton
					iconName="filter"
					tooltip="Filter comments"
					variant="tertiary"
				/>
			}
			initialSelected={filter}
			isViewerUser={false}
			searchable={false}
			options={filterOptions}
			property="comments"
			iconType="none"
			onChange={(value: string) => setFilter(value as 'open' | 'resolved')}
		/>
	);

	return (
		<Stack p={0} className={classes.wrapperStack}>
			<Group
				position="apart"
				spacing="xs"
				noWrap
				className={classes.wrapperStackHeader}
			>
				<Title size="sm">Comments</Title>
				{searchOpen ? (
					<SearchBox
						defaultSearchTerm={searchTerm}
						onSearch={setSearchTerm}
						onCancelSearch={closeSearch}
						alwaysShowCancel
					/>
				) : (
					<Group spacing="xs">
						{filterDropdown}
						<IconButton
							onClick={openSearch}
							iconName="search"
							tooltip="Search comments"
							variant="tertiary"
						/>
					</Group>
				)}
			</Group>
			<Stack className={classes.commentStack}>
				{isLoading ? (
					<LoadingSpinner />
				) : (
					<>
						{filteredThreadIds.map((rootID, idx) => (
							<Fragment key={rootID}>
								<Box p="md" pt="xs">
									<FixedCommentThread
										rootCommentID={rootID}
										comments={commentThreads[rootID]}
										onCreateComment={handleCreateComment}
										onUpdateComment={handleUpdateComment}
										onDeleteComment={handleDeleteComment}
									/>
								</Box>
								{idx !== sortedCommentIds.length - 1 && <Divider />}
							</Fragment>
						))}
						{filteredThreadIds.length === 0 && (
							<EmptySidebar searchTerm={searchTerm} filter={filter} />
						)}
					</>
				)}
			</Stack>
		</Stack>
	);
}

export default observer(FixedCommentSidebar);
