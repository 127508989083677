import type { PolicyOut } from '@repo/api-codegen';
import { useApiListPolicyFrameworks } from '@repo/api-codegen';
import type { SelectablePropertyItem } from '@repo/common/components/ItemIcon/types';
import MultiSelector from '@repo/common/components/MultiSelector/MultiSelector';
import { useCallback, useMemo, useState } from 'react';
import { useAuthUser } from '../../../api';
import { useUpdatePolicy } from '../hooks';

interface FrameworkSelectorProps {
	policy: PolicyOut;
}

export function FrameworkSelector({ policy }: FrameworkSelectorProps) {
	const { data: frameworks } = useApiListPolicyFrameworks({});
	const { user, isViewerUser } = useAuthUser();

	const [selected, setSelected] = useState<string[]>(
		policy.frameworks.map((framework) => framework.id)
	);

	const memoizedFrameworks: SelectablePropertyItem[] = useMemo(
		() =>
			frameworks?.results.map((framework) => ({
				label: framework.name,
				value: framework.id,
			})) ?? [],
		[frameworks]
	);

	const { mutateAsync: updatePolicy } = useUpdatePolicy({});
	const handleChange = useCallback(
		(newFrameworks: string[]) => {
			updatePolicy({
				pathParams: {
					policyId: policy.id,
				},
				body: {
					frameworks: newFrameworks,
				},
			});
			setSelected(newFrameworks);
		},
		[policy.id, updatePolicy]
	);

	return (
		<MultiSelector
			hideOnEmpty
			initialSelected={selected}
			placeholder="Frameworks"
			property="frameworks"
			iconType="tag"
			options={memoizedFrameworks}
			permittedId={user.id}
			isViewerUser={isViewerUser}
			isMenuItemBadge={false}
			onChange={handleChange as (newValue: (string | boolean)[]) => void}
		/>
	);
}
