import { createStyles, Loader, Tabs } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { Suspense, useCallback } from 'react';

import { useApiHasPreviewAccess, useRetrieveQuality } from '@repo/api-codegen';
import { useParams } from 'react-router';
import type { ISecodaEntity } from '../../../api';
import {
	useAuthUser,
	useDataQualityAccess,
	useIntegration,
} from '../../../api';
import Documentation from '../../../components/Documentation';
import EntityDiscussions from '../../../components/EntityDiscussions';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { LineageGraphWrapper } from '../../../components/LineageGraph/LineageGraph';
import TableDataPreview from '../../../components/Preview/TableDataPreview';
import { QueryList } from '../../../components/QueryListV2/QueryList';
import { trackEvent } from '../../../utils/analytics';
import { useTabNavigate } from '../../../utils/hook/useTabNavigate';
import Tests from '../../TablePage/Tests';
import TableEntityTabsList from '../TableEntityTabsList';
import ColumnsTab from './ColumnsTab';
import { DataQualityTab } from './DataQualityTab';
import IncidentsTab from './IncidentsTab';
import MonitorsTab from './MonitorsTab';
import type { TableEntityTabsStore } from './TableEntityTabsStore';

const useStyles = createStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	panel: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
}));

export interface ITableEntityTabsProps {
	table: ISecodaEntity;
	tableEntityTabsStore: TableEntityTabsStore;
}

function TableEntityTabs({
	table,
	tableEntityTabsStore: store,
}: ITableEntityTabsProps) {
	const { tab } = useParams();
	const { classes } = useStyles();
	const { workspace, user } = useAuthUser();
	const dqsEnabled = useDataQualityAccess();
	const canShowDataQualityScore =
		dqsEnabled && table?.entity_type?.toLowerCase() === 'table';
	const { data: dataQuality } = useRetrieveQuality(
		{
			pathParams: {
				entityId: table.id,
			},
		},
		{
			enabled: canShowDataQualityScore,
			refetchOnMount: 'always',
		}
	);

	const { data: integration } = useIntegration({ id: table.integration ?? '' });

	const { data: previewPermissionGranted } = useApiHasPreviewAccess(
		{
			pathParams: {
				integrationId: table.integration ?? '',
			},
		},
		{
			enabled: true,
		}
	);

	const showPreviewTab =
		!['looker', 'powerbi', 'tableau', 'mode', 's3'].includes(
			integration?.type ?? ''
		) && !!previewPermissionGranted;

	const navigateToTab = useTabNavigate();

	const handleTabChange = useCallback(
		(updatedTab: string) => {
			if (updatedTab === 'lineage') {
				trackEvent('table/lineage/open', {}, user, workspace);
			}
			if (updatedTab === 'quality') {
				trackEvent('dqs/resource_tab/open', {}, user, workspace);
			}
			navigateToTab(updatedTab);
		},
		[navigateToTab, user, workspace]
	);

	return (
		<Tabs
			color="dark"
			classNames={classes}
			value={tab ?? 'columns'}
			onTabChange={handleTabChange}
		>
			<TableEntityTabsList
				store={store}
				dataQuality={dataQuality ?? null}
				showPreviewTab={showPreviewTab}
				integration={integration ?? null}
			/>
			<Tabs.Panel value="lineage" pt="sm">
				<LineageGraphWrapper
					id={table.id}
					entityType={table.entity_type}
					nativeType={table.native_type ?? ''}
					published={table.published}
				/>
			</Tabs.Panel>

			<Tabs.Panel value="columns" pt="sm">
				<ColumnsTab table={table} store={store} />
			</Tabs.Panel>

			<Tabs.Panel value="monitors" pt="sm">
				<ErrorBoundary>
					<MonitorsTab
						integrationId={table.integration as string}
						tableId={table.id}
					/>
				</ErrorBoundary>
			</Tabs.Panel>
			<Tabs.Panel value="incidents" pt="sm">
				<ErrorBoundary onError={() => store.setIncidentsCount(0)}>
					<IncidentsTab
						tableId={table.id}
						onTotalRowCountChange={store.setIncidentsCount}
					/>
				</ErrorBoundary>
			</Tabs.Panel>

			{showPreviewTab && (
				<Tabs.Panel value="preview" pt="sm">
					<TableDataPreview tableKey={table.id} />
				</Tabs.Panel>
			)}

			<Tabs.Panel value="tests" pt="sm">
				<ErrorBoundary onError={() => store.setTestCount(0)}>
					<Suspense fallback={<Loader />}>
						<Tests entity={table} store={store} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="queries" pt="sm">
				<ErrorBoundary onError={() => store.setQueryCount(0)}>
					<Suspense fallback={<Loader />}>
						<QueryList
							entity={table as unknown as ISecodaEntity}
							store={store}
						/>
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="discussions" pt="sm">
				<ErrorBoundary onError={() => store.setDiscussionCount(0)}>
					<Suspense fallback={<Loader />}>
						<EntityDiscussions entity={table} store={store} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="documentation" pt="sm">
				<Documentation entity={table} />
			</Tabs.Panel>

			{dataQuality !== null && (
				<Tabs.Panel value="quality" pt="sm">
					<DataQualityTab entity={table} />
				</Tabs.Panel>
			)}
		</Tabs>
	);
}

export default observer(TableEntityTabs);
