import { Box, createStyles, Group, Stack } from '@mantine/core';
import { Button, Icon, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import type { ReactNode } from 'react';
import React, { useMemo, useState } from 'react';
import { useAuthUser } from '../../api';
import { handleLogout, isImpersonatingAsAdmin } from '../../utils/cache/utils';
import { NavBarWrapper } from '../SecodaAppShell/SecodaNavBar';
import SecodaNavBar from '../SecodaAppShell/SecodaNavBar/SecodaNavBar';
import { sideBarStore } from '../SecodaAppShell/SideBar/store';
import { NavBarContext } from './NavBarContext';

interface IBodyWrapperProps {
	children: React.ReactNode;
	hideNavbar?: boolean;
}

const useStyles = createStyles(
	(
		_,
		{
			showSideBar,
			sideBarWidth,
		}: {
			showSideBar: boolean;
			sideBarWidth: number;
		}
	) => ({
		container: {
			paddingLeft: showSideBar ? sideBarWidth : 0,
			transition: 'margin-left 0.1ms ease-in-out',
			height: '100vh',
			overflow: 'hidden',
			display: 'flex',
			flexGrow: 1,
			flexDirection: 'column',

			'@media print': {
				paddingLeft: 0,
			},
		},
		bodyWrapper: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			overflow: 'auto',
		},
	})
);

function BodyWrapper({ children, hideNavbar = false }: IBodyWrapperProps) {
	const { classes, cx, theme } = useStyles({
		showSideBar: sideBarStore.showSideBar,
		sideBarWidth: sideBarStore.width,
	});

	const [leftNode, setLeftNode] = useState<ReactNode | null>(null);
	const [rightNode, setRightNode] = useState<ReactNode | null>(null);

	const { user: currentUser } = useAuthUser();

	const providerValue = useMemo(
		() => ({ setLeftNode, setRightNode, leftNode, rightNode }),
		[leftNode, rightNode]
	);

	return (
		<NavBarContext.Provider value={providerValue}>
			<Stack className={cx(classes.container, 'BodyWrapper')} spacing={0}>
				{isImpersonatingAsAdmin() && (
					<NavBarWrapper bg={theme.other.getColor('surface/caution/default')}>
						<Group position="apart" w="100%">
							<Group spacing="xs">
								<Icon name="infoCircle" />
								<Text weight="semibold">
									Logged in as {currentUser?.display_name}
								</Text>
							</Group>
							<Button
								variant="default"
								onClick={() => handleLogout('/settings/members')}
							>
								Stop impersonation
							</Button>
						</Group>
					</NavBarWrapper>
				)}
				{!hideNavbar && <SecodaNavBar />}
				<Box className={classes.bodyWrapper}>{children}</Box>
			</Stack>
		</NavBarContext.Provider>
	);
}

export default observer(BodyWrapper);
