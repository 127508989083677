import { Card, Checkbox, Divider, Group, Stack } from '@mantine/core';
import { Text, Title } from '@repo/foundations';
import { capitalize, cloneDeep, map, uniq } from 'lodash-es';
import type { WorkspaceNotificationPreference } from '../../../lib/models';
import { notifications } from '../../Settings/constants';

interface IntegrationSlackNotificationsProps {
	notificationSettings: WorkspaceNotificationPreference;
	onNotificationSettingsChange: (
		settings: WorkspaceNotificationPreference
	) => void;
}

function IntegrationSlackNotifications({
	notificationSettings,
	onNotificationSettingsChange,
}: IntegrationSlackNotificationsProps) {
	const sections = uniq(
		map(
			notifications.filter((settings) => settings.category !== 'Digest'),
			'category'
		)
	);

	const onCheck =
		(attribute: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
			const newSettings = cloneDeep(notificationSettings);
			newSettings[attribute] = e.target.checked;
			onNotificationSettingsChange(newSettings);
		};

	return (
		<Stack>
			<Card withBorder radius="xs" p="xl" mb={32}>
				<Stack spacing="md">
					<Stack>
						<Group>
							<Text size="lg">Channel notification settings</Text>
						</Group>
						<Divider />
						<Stack spacing="lg">
							{Array.from(sections).map((section) => (
								<Stack key={section} spacing={4}>
									<Title order={6}>{section}</Title>
									{notifications
										// Exclude notify_mentions from workspace slack settings.
										.filter(
											(preference) =>
												preference.category === section &&
												preference.attribute !== 'notify_mentions'
										)
										.map((preference) => (
											<Group
												key={preference.category}
												position="apart"
												spacing="xl"
											>
												<Text size="md">
													{capitalize(preference.description)}
												</Text>
												<Checkbox
													onChange={onCheck(preference.attribute)}
													width="40px"
													checked={notificationSettings[preference.attribute]}
												/>
											</Group>
										))}
								</Stack>
							))}
						</Stack>
					</Stack>
				</Stack>
			</Card>
		</Stack>
	);
}

export default IntegrationSlackNotifications;
