import { Anchor } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { EntityType } from '@repo/common/enums/entityType';
import { Icon } from '@repo/foundations';
import type { AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';
import type { NavigateFunction } from 'react-router-dom';
import type { IEmbeddedPrompt, ISecodaEntity, ITemplate } from '../../api';
import {
	createCollection,
	createDocument,
	createMetric,
	createQuestion,
	createSecodaEntity,
	queryClient,
	questionsQueryKeyFactory,
} from '../../api';
import {
	createResourceRelation,
	invalidateResourceRelationList,
} from '../../api/hooks/relations';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';
import type { APIListResponseData } from '../../lib/models';
import { search } from '../../lib/models';
import { v4 } from '../../utils/uuid/v4';
import { ENTITY_TYPES_MODAL_CONFIG } from './constants';

export interface OpenEntityModalData {
	type?: EntityType;
	relatedResource?: ISecodaEntity;
	teamId?: string;
	teamIds?: string[];
	template?: ITemplate;
	metadata?: Record<string, unknown>;
	title?: string;
	description?: string;
	withLinkToAI?: boolean;
}

class EntityModalStore {
	type: EntityType | undefined;

	relatedResource?: ISecodaEntity;

	template: ITemplate | undefined;

	loading = false;

	opened = false;

	title = '';

	description = '';

	metadata: Record<string, unknown> = {};

	relatedQuestions: ISecodaEntity[] | undefined;

	loadingRelatedQuestions = false;

	// Warning: deprecated attribute. We should be able to support multiple teams in the future.
	// Currently only questions support multiple teams.
	teamId?: string;

	teamIds: string[] | undefined;

	withLinkToAI = false;

	constructor() {
		makeAutoObservable(this);
	}

	open(data: OpenEntityModalData) {
		this.reset();

		this.type = data.type;
		this.teamId = data.teamId;
		this.teamIds = data.teamIds;
		this.relatedResource = data.relatedResource;
		this.metadata = data.metadata ?? {};
		this.title = data.title ?? '';
		this.description = data.description ?? '';
		this.withLinkToAI = data.withLinkToAI ?? false;
		if (data.template) {
			this.setTemplate(data.template);
		}

		// open it after all properties have been set
		this.opened = true;
	}

	close() {
		this.opened = false;
	}

	reset() {
		this.type = undefined;
		this.relatedResource = undefined;
		this.template = undefined;
		this.loading = false;
		this.opened = false;
		this.title = '';
		this.description = '';
		this.metadata = {};
		this.teamId = undefined;
		this.relatedQuestions = undefined;
		this.loadingRelatedQuestions = false;
		this.teamIds = undefined;
		this.withLinkToAI = false;
	}

	setTitle = (title: string) => {
		this.title = title;
	};

	setTemplate = (template: ITemplate) => {
		this.template = template;

		this.setDescription(
			(this.type &&
				template?.[
					ENTITY_TYPES_MODAL_CONFIG[this.type].richTextTemplateField ??
						'definition'
				]) ??
				''
		);

		this.setTitle(template.title ?? '');
		this.setMetadata('parent', template.parent ?? '');
		this.setMetadata('published', template.published ?? false);
		this.setMetadata('assignee', template.assignee ?? '');
		this.setMetadata('assignee_group', template.assignee_group ?? '');
		this.setMetadata('priority', template.priority ?? '');
		this.setMetadata('tags', template.tags ?? []);
		this.setMetadata('owners', template.owners ?? []);
		this.setMetadata('collections', template.collections ?? []);
	};

	setDescription = (description: string) => {
		this.description = description;
	};

	setMetadata = (key: string, value: unknown) => {
		this.metadata[key] = value;
	};

	setWithLinkToAI = (value: boolean) => {
		this.withLinkToAI = value;
	};

	checkEmptyTitle() {
		if (this.title === '') {
			showNotification({
				title: 'Title required',
				message: 'Please add a title before creating',
			});
			this.loading = false;
			return true;
		}
		return false;
	}

	findRelatedQuestions = () => {
		this.loadingRelatedQuestions = true;
		search(
			{ search_term: this.title, page: 1, entity_type: EntityType.question },
			false
		)
			.then((res: AxiosResponse<APIListResponseData>) => {
				if (res.data.results.length > 0) {
					this.relatedQuestions = res.data.results.slice(0, 5); // Get max 5 related questions
				}
				this.loadingRelatedQuestions = false;
			})
			.catch(() => {
				this.loadingRelatedQuestions = false;
			});
	};

	async quickCreateEntity(
		entityType: EntityType,
		title: string,
		teamIds: Array<string> | undefined,
		metadata: Record<string, unknown>,
		navigate: NavigateFunction,
		template?: ITemplate
	) {
		this.type = entityType;
		this.title = title;
		this.teamIds = teamIds;
		this.metadata = metadata;

		if (template) {
			this.setTemplate(template);
		}

		await this.create(navigate, true);
	}

	async create(navigate: NavigateFunction, navigateToEntity: boolean = false) {
		this.loading = true;

		if (this.checkEmptyTitle()) {
			return;
		}

		switch (this.type) {
			case EntityType.question:
				await this.createQuestion(navigate, navigateToEntity);
				break;
			case EntityType.collection:
				await this.createCollection(navigate, navigateToEntity);
				break;
			case EntityType.document:
				this.createDocument(navigate, navigateToEntity);
				break;
			case EntityType.metric:
				this.createMetric(navigate, navigateToEntity);
				break;
			case EntityType.glossary:
				this.createGlossaryTerm(navigate, navigateToEntity);
				break;
			default:
				break;
		}
	}

	private showErrorNotification(entityType: string) {
		showNotification({
			title: `Error creating ${entityType.toLowerCase()}`,
			message: 'If the issue persists, please contact support',
			color: 'red',
			icon: <Icon name="alertCircle" />,
		});
	}

	createQuestion = async (
		navigate: NavigateFunction,
		navigateToEntity: boolean = false
	) => {
		this.loading = true;

		if (this.checkEmptyTitle()) {
			return;
		}

		const owners = (this.metadata?.owners ?? []) as string[];
		const assignedTo = (this.metadata.assignee ?? null) as string | undefined;
		const assignedToGroup = (this.metadata.assignee_group ?? null) as
			| string
			| undefined;
		const priority = (this.metadata.priority ?? null) as string | undefined;
		const tags = (this.metadata?.tags ?? []) as string[];
		const aiPrompt = (this.metadata.ai_prompt ?? null) as
			| IEmbeddedPrompt
			| undefined;
		const shareFn = (this.metadata.share_prompt ?? null) as
			| ((promptId: string | undefined) => void)
			| undefined;
		const teams = [];

		if (this.teamIds && this.teamIds.length > 0) {
			teams.push(...this.teamIds);
		} else if (this.teamId) {
			// Legacy support for single team questions.
			teams.push(this.teamId);
		}

		createQuestion({
			data: {
				title: this.title,
				definition: this.description,
				owners,
				assigned_to: assignedTo,
				assigned_to_group: assignedToGroup,
				priority,
				tags,
				teams,
				properties: this.template?.properties,
				ai_prompt: this.withLinkToAI ? aiPrompt?.id : undefined,
			},
		})
			.then(async (question: ISecodaEntity) => {
				if (this.relatedResource) {
					await createResourceRelation({
						data: {
							from_entity: question.id,
							to_entity: this.relatedResource.id,
						},
					}).then(() => {
						if (this.relatedResource) {
							invalidateResourceRelationList(this.relatedResource.id);
						}
						invalidateResourceRelationList(question.id);
					});
				}

				if (this.withLinkToAI && shareFn) {
					await shareFn(aiPrompt?.id);
				} else if (navigateToEntity) {
					navigate(`/questions/${question.id}`);
				}

				// Reload the table v2.
				queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.all());

				// Invalidate the questions on the discussions page.
				queryClient.invalidateQueries(
					questionsQueryKeyFactory.list(1, {
						entity_id: this.relatedResource?.id,
					})
				);

				this.reset();

				showNotification({
					title: 'Question created',
					message: (
						<Anchor
							data-testid="created-notification"
							role="link"
							onClick={() => navigate(`/questions/${question.id}`)}
						>
							View your question
						</Anchor>
					),
					color: 'green',
				});
			})
			.catch(() => this.showErrorNotification('question'))
			.finally(() => {
				this.loading = false;
			});
	};

	createCollection = async (
		navigate: NavigateFunction,
		navigateToEntity: boolean = false
	) => {
		this.loading = true;

		if (this.checkEmptyTitle()) {
			return;
		}

		const owners = (this.metadata?.owners ?? []) as string[];
		const tags = (this.metadata?.tags ?? []) as string[];
		const teams = [];
		if (this.teamIds && this.teamIds.length > 0) {
			teams.push(...this.teamIds);
		} else if (this.teamId) {
			// Legacy support for single team.
			teams.push(this.teamId);
		}
		const collections = (
			this.metadata.parent ? [this.metadata.parent] : []
		) as string[];

		await createCollection({
			data: {
				title: this.title,
				description: this.description,
				icon: 'iconName:folder iconColor:#4a4a4a',
				owners,
				published: this.metadata.published as boolean,
				pinned: this.metadata.pinned as boolean,
				teams,
				tags,
				collections,
				properties: this.template?.properties,
			},
		})
			.then(async (collection: ISecodaEntity) => {
				showNotification({
					title: 'Collection created',
					message: (
						<Anchor
							data-testid="created-notification"
							role="link"
							onClick={() => navigate(`/collections/${collection.id}`)}
						>
							{collection.title}
						</Anchor>
					),
					color: 'green',
				});

				if (navigateToEntity) {
					navigate(`/collections/${collection.id}`);
				}

				// Reload the table v2.
				queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.all());

				this.reset();
			})
			.catch(() => this.showErrorNotification('collection'))
			.finally(() => {
				this.loading = false;
			});
	};

	createQuery = (
		navigate: NavigateFunction,
		navigateToEntity: boolean = false
	) => {
		this.description = `${this.description}\n\n;;;${v4()};;;`;
		this.createDocument(navigate, navigateToEntity);
	};

	createDocument = (
		navigate: NavigateFunction,
		navigateToEntity: boolean = false
	) => {
		this.loading = true;

		if (this.checkEmptyTitle()) return;

		const owners = (this.metadata?.owners ?? []) as string[];
		const collections = (this.metadata?.collections ?? []) as string[];
		const tags = (this.metadata?.tags ?? []) as string[];

		const teams = [];
		if (this.teamIds && this.teamIds.length > 0) {
			teams.push(...this.teamIds);
		} else if (this.teamId) {
			// Legacy support for single team.
			teams.push(this.teamId);
		}

		createDocument({
			data: {
				title: this.title,
				definition: this.description,
				icon: 'iconName:file iconColor:#4a4a4a',
				teams,
				owners,
				collections,
				tags,
				properties: this.template?.properties,
			},
		})
			.then(async (document: ISecodaEntity) => {
				showNotification({
					title: 'Document created',
					message: (
						<Anchor
							data-testid="created-notification"
							role="link"
							onClick={() => navigate(`/docs/${document.id}`)}
						>
							{document.title}
						</Anchor>
					),
					color: 'green',
				});

				if (navigateToEntity) {
					navigate(`/docs/${document.id}`);
				}

				// Reload the table v2.
				queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.all());

				this.reset();
			})
			.catch(() => this.showErrorNotification('document'))
			.finally(() => {
				this.loading = false;
			});
	};

	createMetric = (
		navigate: NavigateFunction,
		navigateToEntity: boolean = false
	) => {
		this.loading = true;

		if (this.checkEmptyTitle()) return;

		const owners = (this.metadata?.owners ?? []) as string[];
		const collections = (this.metadata?.collections ?? []) as string[];
		const tags = (this.metadata?.tags ?? []) as string[];

		const teams = [];
		if (this.teamIds && this.teamIds.length > 0) {
			teams.push(...this.teamIds);
		} else if (this.teamId) {
			// Legacy support for single team.
			teams.push(this.teamId);
		}

		createMetric({
			data: {
				title: this.title,
				description: this.description,
				teams,
				owners,
				collections,
				tags,
				properties: this.template?.properties,
			},
		})
			.then(async (metric: ISecodaEntity) => {
				showNotification({
					title: 'Metric created',
					message: (
						<Anchor
							data-testid="created-notification"
							role="link"
							onClick={() => navigate(`/metrics/${metric.id}`)}
						>
							{metric.title}
						</Anchor>
					),
					color: 'green',
				});

				if (navigateToEntity) {
					navigate(`/metrics/${metric.id}`);
				}

				// Reload the table v2.
				queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.all());

				this.reset();
			})
			.catch(() => this.showErrorNotification('metric'))
			.finally(() => {
				this.loading = false;
			});
	};

	createGlossaryTerm = (
		navigate: NavigateFunction,
		navigateToEntity: boolean = false
	) => {
		this.loading = true;

		if (this.checkEmptyTitle()) return;

		const owners = (this.metadata?.owners ?? []) as string[];
		const collections = (this.metadata?.collections ?? []) as string[];
		const tags = (this.metadata?.tags ?? []) as string[];

		const teams = [];
		if (this.teamIds && this.teamIds.length > 0) {
			teams.push(...this.teamIds);
		} else if (this.teamId) {
			// Legacy support for single team.
			teams.push(this.teamId);
		}

		createSecodaEntity({
			data: {
				entity_type: EntityType.glossary,
				title: this.title,
				description: this.description,
				icon: 'iconName:book iconColor:#4a4a4a',
				definition: this.template?.definition ?? '',
				teams,
				owners,
				collections,
				tags,
				properties: this.template?.properties,
			},
		})
			.then(async (glossaryTerm: ISecodaEntity) => {
				showNotification({
					title: 'Glossary term created',
					message: (
						<Anchor
							data-testid="created-notification"
							role="link"
							onClick={() => navigate(`/glossary/${glossaryTerm.id}`)}
						>
							{glossaryTerm.title}
						</Anchor>
					),
					color: 'green',
				});

				if (navigateToEntity) {
					navigate(`/glossary/${glossaryTerm.id}`);
				}

				queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.all());

				this.reset();
			})
			.catch(() => this.showErrorNotification('glossary term'))
			.finally(() => {
				this.loading = false;
			});
	};
}

export const entityModalStore = new EntityModalStore();
