import { find, isEmpty, isNil } from 'lodash-es';
import { NOTIFICATION_EVENT_TYPES } from '../../../pages/InboxPage/InboxList/constants';
import { inboxPageStore } from '../../../pages/InboxPage/store';
import {
	getEventsFilter,
	getInboxFiltersFromUrl,
} from '../../../pages/InboxPage/utils';
import { getParamsFromUrl } from '../../../utils/url';
import queryClient from '../../queryClient';
import type { IApiListResponse } from '../../types';
import type { INotification } from '../../types/models/notification';
import { notificationQueryKeyFactory } from './constants';

export const getFilters = () => {
	const params = getParamsFromUrl();

	const { page, searchTerm, events, isInclusive } =
		getInboxFiltersFromUrl(params);

	const filters = {
		search_term: searchTerm,
		events: getEventsFilter(NOTIFICATION_EVENT_TYPES, events, isInclusive),
		read: inboxPageStore.showRead,
	};

	return {
		page,
		filters,
	};
};

export const invalidateNotificationListQuery = () => {
	queryClient.invalidateQueries({
		queryKey: notificationQueryKeyFactory.allLists(),
	});
};

export const updateNotificationInList = (
	id: string,
	data: Partial<INotification>
) => {
	const { page, filters } = getFilters();

	const queryData: IApiListResponse<INotification> | undefined =
		queryClient.getQueryData(notificationQueryKeyFactory.list(page, filters));

	if (!isNil(queryData) && !isEmpty(queryData.results)) {
		const notification = find(queryData.results, { id });

		if (!isNil(notification)) {
			queryClient.setQueryData(
				notificationQueryKeyFactory.list(page, filters),
				{
					...queryData,
					results: queryData.results.map((item) =>
						item.id === id ? { ...item, ...data } : item
					),
				}
			);
		}
	}
};
