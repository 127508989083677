import type { LiteQueryOut } from '@repo/api-codegen';
import { makeAutoObservable } from 'mobx';
import type { ILineage, IQuery } from '../../../api';
import type { EntityTabsStore } from '../../TableEntityPage/TableEntityTabs/TableEntityTabsStore';

export class DashboardEntityTabsStore implements EntityTabsStore {
	discussionCount: number | undefined = undefined;

	lineageCount: number | undefined = undefined;

	lineageUpstreamResults?: ILineage[] = undefined;

	lineageDownstreamResults?: ILineage[] = undefined;

	queryCount: number | undefined = undefined;

	// Only populated on some SecodaEntity elements.
	creationQuery: IQuery | LiteQueryOut | undefined = undefined;

	get tables() {
		return this.lineageUpstreamResults ?? [];
	}

	get charts() {
		return this.lineageDownstreamResults ?? [];
	}

	get tablesCount() {
		return this.lineageUpstreamResults?.length;
	}

	get chartsCount() {
		return this.lineageDownstreamResults?.length;
	}

	get isLineageLoading() {
		return this.lineageCount === undefined;
	}

	get isTablesLoading() {
		return this.tablesCount === undefined;
	}

	get isChartsLoading() {
		return this.chartsCount === undefined;
	}

	get isQueryLoading() {
		return this.queryCount === undefined;
	}

	get isDiscussionLoading() {
		return this.discussionCount === undefined;
	}

	setCreationQuery = (query: IQuery | LiteQueryOut) => {
		this.creationQuery = query;
	};

	setLineageCount = (count: number) => {
		this.lineageCount = count;
	};

	setQueryCount = (count: number) => {
		this.queryCount = count;
	};

	setDiscussionCount = (count: number) => {
		this.discussionCount = count;
	};

	setLineageUpstreamResults = (results: ILineage[]) => {
		this.lineageUpstreamResults = results;
	};

	setLineageDownstreamResults = (results: ILineage[]) => {
		this.lineageDownstreamResults = results;
	};

	constructor() {
		makeAutoObservable(this);
	}
}
