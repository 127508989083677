export function getToolName(toolName: string, fallbackName: string) {
	const toolDetails = AI_TOOLS[toolName as keyof typeof AI_TOOLS];
	if (toolDetails) {
		return toolDetails.name;
	}
	return fallbackName;
}

export function getToolDescription(
	toolName: string,
	fallbackDescription: string
) {
	const toolDetails = AI_TOOLS[toolName as keyof typeof AI_TOOLS];
	if (toolDetails) {
		return toolDetails.description;
	}
	return fallbackDescription;
}

export const AI_TOOLS = {
	search_resources: {
		name: 'Search resources',
		description: 'Search for data assets and resources in Secoda.',
	},
	search_knowledge: {
		name: 'Search knowledge',
		description: 'Search for documents and definitions in Secoda.',
	},
	run_sql: {
		name: 'Run SQL',
		description: 'Run a SQL query on the integration and return the results.',
	},
	retrieve_entity: {
		name: 'Retrieve resource',
		description: 'Retrieve a resource from the workspace given a resource id.',
	},
	entity_link: {
		name: 'Resource link',
		description:
			'Retrieve a link to a resource from the workspace given a resource id.',
	},
	get_secoda_docs: {
		name: 'Get Secoda docs',
		description: 'Answer questions about the Secoda docs.',
	},
	entity_lineage: {
		name: 'Resource lineage',
		description: 'Retrieve the upstream and downstream lineage of a resource.',
	},
	glossary: {
		name: 'Glossary',
		description:
			'Retrieve the glossary of terms and definitions for the workspace.',
	},
	get_file_contents: {
		name: 'Get file contents',
		description: 'Read content from given file URLs.',
	},
	head_file: {
		name: 'Head file',
		description: 'Read the first N lines of the file.',
	},
	tail_file: {
		name: 'Tail file',
		description: 'Read the last N lines of the file.',
	},
	grep_file: {
		name: 'Grep file',
		description: 'Search within a file.',
	},
	add_memory: {
		name: 'Add memory',
		description: "Add new information to the AI assistant's memory.",
	},
	delete_memory: {
		name: 'Delete memory',
		description: "Delete a specific memory from the AI assistant's knowledge.",
	},
};
