import { createStyles, Group, Stack, UnstyledButton } from '@mantine/core';
import { pluralize } from '@repo/common/utils';
import { Icon, Text } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import { isEmpty } from 'lib0/object';
import type { IWorkspace } from '../../../../api';
import { workspaceSelectorStore } from './store';
import { WorkspaceLogo } from './WorkspaceLogo';

interface IWorkspaceItem {
	workspace: IWorkspace;
}

const useStyles = createStyles((theme) => ({
	button: {
		borderRadius: theme.radius.sm,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/primary/selected'),
		},
	},
	wrapper: {
		padding: `${theme.spacing['3xs']} ${theme.spacing.xs}}`,
	},
	leftGroup: {
		flexGrow: 1,
	},
	workspaceText: {
		lineHeight: theme.spacing.lg,
	},
}));

export function WorkspaceItem({ workspace }: IWorkspaceItem) {
	const { classes } = useStyles();

	const handleSetActiveWorkspace = () => {
		workspaceSelectorStore.setActiveWorkspace(workspace);
	};

	return (
		<UnstyledButton
			className={classes.button}
			onClick={handleSetActiveWorkspace}
		>
			<Group className={classes.wrapper} position="apart" noWrap>
				<Group className={classes.leftGroup} spacing="sm" noWrap>
					<WorkspaceLogo
						logo={
							!isEmpty(workspace.logo)
								? (workspace.logo as string)
								: '/images/logos/cfsecoda.webp'
						}
						size={32}
					/>
					<Stack spacing={0}>
						<Text
							size="sm"
							weight="bold"
							w={space[32]}
							truncate
							className={classes.workspaceText}
						>
							{workspace.name}
						</Text>
						<Text
							size="xs"
							color="text/secondary/default"
							className={classes.workspaceText}
						>
							{pluralize('member', workspace.members_count, true)}
						</Text>
					</Stack>
				</Group>
				{workspace.is_active && <Icon name="check" />}
			</Group>
		</UnstyledButton>
	);
}
