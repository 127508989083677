import type {
	ApiPatchPolicyError,
	ApiPatchPolicyVariables,
	ApiTogglePolicyEnableError,
	ApiTogglePolicyEnableVariables,
	PolicyOut,
} from '@repo/api-codegen';
import { useApiPatchPolicy, useApiTogglePolicyEnable } from '@repo/api-codegen';
import type * as Schemas from '@repo/api-codegen/api/codegen/apiSchemas';
import type { UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from '../../api';
import { isQueryKeyPrefix } from '../../api/hooks/utils/fns';

// Inject new value into the list query cache on successful mutation
function optimisticUpdatePolicy(updatedPolicy: PolicyOut) {
	queryClient
		.getQueryCache()
		.getAll()
		.map((cache) => cache.queryKey)
		.forEach((existingQueryKey) => {
			if (isQueryKeyPrefix(existingQueryKey, ['policy', 'policies'])) {
				queryClient.setQueryData(
					existingQueryKey,
					(old: Schemas.PagedPolicyOut | undefined) => {
						if (!old) {
							return old;
						}

						const updated = old.results.map((p) =>
							p.id === updatedPolicy.id ? updatedPolicy : p
						);
						return {
							...old,
							results: updated,
						};
					}
				);
			}
		});
}

export function useTogglePolicyEnable(props: {
	options?: Omit<
		UseMutationOptions<
			PolicyOut,
			ApiTogglePolicyEnableError,
			ApiTogglePolicyEnableVariables
		>,
		'mutationFn'
	>;
}) {
	return useApiTogglePolicyEnable({
		...props,
		onSuccess: (data) => {
			optimisticUpdatePolicy(data);
		},
	});
}

export function useUpdatePolicy(props: {
	options?: Omit<
		UseMutationOptions<
			Schemas.PolicyOut,
			ApiPatchPolicyError,
			ApiPatchPolicyVariables
		>,
		'mutationFn'
	>;
}) {
	return useApiPatchPolicy({
		...props,
		onSuccess: (data) => {
			optimisticUpdatePolicy(data);
		},
	});
}
