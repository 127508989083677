import type { ActivityLogOut } from '@repo/api-codegen';
import { useMemo } from 'react';
import { ActivityLogEventRender, LoggedAtRender } from '../TableV2/render';
import type { ExtendedDataTableColumn } from '../TableV2/types';

export function useColumns(): ExtendedDataTableColumn<ActivityLogOut>[] {
	const columns = useMemo(
		() => [
			{
				accessor: 'logged_at',
				title: 'Date',
				render: (record: ActivityLogOut) => <LoggedAtRender record={record} />,
			},
			{
				accessor: 'resource_id',
				title: 'Resource',
				render: (record: ActivityLogOut) => (
					<div>
						<span>{record.resource_id}</span>
					</div>
				),
			},
			{
				accessor: 'event_blocks',
				title: 'Event',
				render: (record: ActivityLogOut) => (
					<ActivityLogEventRender record={record} />
				),
			},
			{
				accessor: 'performer_id',
				title: 'Initiated by',
				render: (record: ActivityLogOut) => (
					<div>
						<span>{record.performer_id}</span>
					</div>
				),
			},
		],
		[]
	);

	return columns;
}
