import type { Dispatch, SecodaEditorComponentProps } from '@repo/secoda-editor';
import type { NodeSpec } from 'prosemirror-model';
import { Selection, type EditorState } from 'prosemirror-state';
import { AISummaryContainer } from '../components/AITools/AISummaryContainer';
import type { CommandFactory } from '../lib/Extension';
import type { NodeOptions } from './Node';
import ReactNode from './ReactNode';

export default class AISummary extends ReactNode {
	get name() {
		return 'ai_summary';
	}

	get schema(): NodeSpec {
		return {
			group: 'block',
			defining: true,
			atom: true,
			selectable: true,
			parseDOM: [
				{
					preserveWhitespace: 'full',
					tag: 'div.ai_summary',
				},
			],
			toDOM: () => ['div', { class: 'ai_summary' }, 0],
		};
	}

	commands({
		type,
	}: NodeOptions): Record<string, CommandFactory> | CommandFactory {
		return () => (state: EditorState, dispatch: Dispatch) => {
			dispatch(state.tr.replaceSelectionWith(type.create()).scrollIntoView());
			return true;
		};
	}

	toMarkdown() {
		// we don't want to render this node - its temporary until the user submits the AI summary that will get converted into a simple paragraph
	}

	parseMarkdown() {
		// this node should never exist in markdown
	}

	cancel = () => {
		const pos = this.editorState.view.posAtDOM(
			document.querySelector('div.ai_summary') as HTMLElement,
			0
		);

		const node = this.editorState.view.state.doc.nodeAt(pos);

		if (!node) {
			return;
		}

		let transaction = this.editorState.view.state.tr.replaceRangeWith(
			pos,
			pos + node.nodeSize,
			this.editorState.view.state.schema.nodes.paragraph.create({})
		);
		transaction = transaction.setSelection(
			Selection.near(transaction.doc.resolve(pos))
		);
		this.editorState.view.focus();
		this.editorState.view.dispatch(transaction);
	};

	save = (summary: string) => {
		const pos = this.editorState.view.posAtDOM(
			document.querySelector('div.ai_summary') as HTMLElement,
			0
		);

		const node = this.editorState.view.state.doc.nodeAt(pos);

		if (!node) {
			return;
		}

		const transaction = this.editorState.view.state.tr.replaceRangeWith(
			pos,
			pos + node.nodeSize,
			this.editorState.view.state.schema.text(summary)
		);
		this.editorState.view.dispatch(transaction);
	};

	component = ({ node }: SecodaEditorComponentProps) => (
		<div className="ai_summary">
			<AISummaryContainer
				editor={this.editor}
				initialSummary={node.textContent}
				onShowToast={this.editor.options.onShowToast}
				onCancel={this.cancel}
				onSave={this.save}
			/>
		</div>
	);
}
