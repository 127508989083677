import type { Filter } from '@repo/api-codegen';
import { useApiGetBottomUpDatatree } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { useState } from 'react';
import IntegrationLogo from '../../IntegrationLogo';
import type { CommonEntityTreeNode } from '../utils';
import { getEntityIconByType } from '../utils';
import type { PrefetchedEntityTreeNodeProps } from './BottomUpTreeEntityNode';
import { BottomUpTreeEntityNode } from './BottomUpTreeEntityNode';

const getIcon = (node: CommonEntityTreeNode) => {
	if (node.entity.entity_type === 'integration') {
		return <IntegrationLogo integrationId={node.entity.id} size={16} />;
	}

	return getEntityIconByType(node.entity.entity_type);
};

const getLabel = (node: CommonEntityTreeNode) => {
	if (node.entity.entity_type === 'integration') {
		return node.entity.title_cased ?? node.entity.title ?? 'Untitled';
	}

	return node.entity.title_cased ?? node.entity.title ?? 'Untitled';
};

export function BottomUpTreeIntegrationNode({
	name,
	id,
	teamId,
	onClick,
}: {
	name: string;
	id: string;
	teamId: string;
	onClick: PrefetchedEntityTreeNodeProps['onClick'];
}) {
	const [open, setOpen] = useState(false);

	const { data: bottomUpDataTree } = useApiGetBottomUpDatatree(
		{
			queryParams: {
				team_id: teamId,
				filters: JSON.stringify({
					operator: 'and',
					operands: [
						{
							operator: 'exact',
							field: 'integration_id',
							value: id,
						},
						{
							operator: 'not',
							operands: [
								{
									operator: 'exact',
									field: 'entity_type',
									value: EntityType.metric,
								},
								{
									operator: 'exact',
									field: 'entity_type',
									value: EntityType.document,
								},
							],
						},
					],
				} as Filter),
			},
		},
		{
			retry: 1,
			select: (data) => Object.values(data ?? {}),
			enabled: open,
		}
	);

	return (
		<BottomUpTreeEntityNode
			key={id}
			node={{
				id,
				hasChildren: !bottomUpDataTree || bottomUpDataTree.length > 0,
				entity: {
					id,
					entity_type: EntityType.integration,
					icon: '',
					title: name,
				},
			}}
			children={bottomUpDataTree}
			level={0}
			getLabel={getLabel}
			getIcon={getIcon}
			onClick={onClick}
			onOpen={setOpen}
		/>
	);
}
