import type { EntityType } from '@repo/common/enums/entityType';
import { getEntityTypeDisplayInfo } from '@repo/common/utils/entityDisplayUtils';
import {
	first,
	has,
	includes,
	isEmpty,
	keys,
	map,
	noop,
	without,
} from 'lodash-es';
import { makeAutoObservable, runInAction } from 'mobx';
import {
	fetchCollection,
	getEntitiesCount,
	updateCollection,
	type ICollection,
} from '../../api';
import type { SecodaEntity } from '../../lib/models';
import type { EntityTabsStore } from '../../pages/TableEntityPage/TableEntityTabs/TableEntityTabsStore';

import type { TabItem } from '@repo/common/components/TabsList';
import {
	fetchSecodaEntity,
	updateSecodaEntity,
} from '../../api/hooks/secodaEntity/fetchSecodaEntities';
import { entityTableStore } from '../EntityTable';
import { showCollectionNotification } from './utils';

export class CollectionsEntityTabsStore implements EntityTabsStore {
	documentationFirst = true;

	activeTab?: string;

	activeCollectionTab: 'Parents' | 'Children' = 'Children';

	tabs?: Record<string, number>;

	collection?: ICollection;

	lineageCount = undefined;

	lineageUpstreamResults = undefined;

	lineageDownstreamResults = undefined;

	creationQuery = undefined;

	setLineageCount = noop;

	setLineageUpstreamResults = noop;

	setLineageDownstreamResults = noop;

	constructor(element: ICollection, activeTab?: string) {
		this.collection = element;

		this.documentationFirst =
			(this.collection?.definition ?? '')
				.replaceAll('\\', '')
				.replaceAll('\n', '')
				.trim().length > 2;

		this.activeTab = activeTab;
		this.fetchTabs(!!activeTab);

		makeAutoObservable(this);
	}

	get isLoading() {
		return this.tabs === undefined;
	}

	get isDocumentationTabActive() {
		return this.activeTab === 'documentation';
	}

	get isCollectionTabActive() {
		return this.activeTab === 'collection';
	}

	get tabNames() {
		return keys(this.tabs);
	}

	get tableFilters() {
		return this.activeTab === 'collection' &&
			this.activeCollectionTab === 'Parents'
			? { id__in: this.collection!.collections?.join(',') }
			: { collections__in: this.collection!.id };
	}

	get tabsList(): TabItem[] {
		return map(this.tabNames, this.getTabInfo)
			.concat({
				value: 'documentation',
				label: 'Documentation',
			})
			.filter((element) => element !== undefined) as TabItem[];
	}

	async fetchTabs(preserveActiveTab = false) {
		const entityCount = await getEntitiesCount(this.collection!.id);
		runInAction(() => {
			this.tabs = entityCount;
		});
		if (!preserveActiveTab) {
			this.setActiveTabAfterFetch();
		}
	}

	getTabInfo = (value: string): TabItem | undefined => {
		const { label } = getEntityTypeDisplayInfo(value as EntityType);

		// getEntityTypeDisplayInfo returns Resource if value doesn't have a specific
		// matching in the switch case. Resource needs to be ignored as a tab as it does
		// not have proper column definition
		if (label === 'Resource') {
			return undefined;
		}

		const count = this.tabs?.[value] ?? 0;

		return {
			value,
			label: `${label} (${count})`,
		};
	};

	setActiveTab = (tabName: string) => {
		this.activeTab = tabName;

		if (tabName === 'documentation') {
			return;
		}

		entityTableStore.setSelectedEntities([]);
	};

	setActiveCollectionTab = (tabName: 'Parents' | 'Children') => {
		this.activeCollectionTab = tabName;
		entityTableStore.reload();
	};

	setActiveTabAfterFetch() {
		entityTableStore.setSelectedEntities([]);

		if (this.documentationFirst || isEmpty(this.tabs)) {
			this.setActiveTab('documentation');
			return;
		}

		if (has(this.tabs, this.activeTab ?? '')) {
			entityTableStore.reload();
			return;
		}

		const tabName = first(this.tabNames)!;
		this.setActiveTab(tabName);
	}

	removeFromCollection = async () => {
		await Promise.all(
			map(entityTableStore.selectedEntities, async (entity) => {
				if (
					this.activeTab === 'collection' &&
					this.activeCollectionTab === 'Parents'
				) {
					this.collection!.collections = without(
						this.collection!.collections,
						entity.id
					);
					await updateCollection({
						data: {
							id: this.collection!.id,
							collections: this.collection!.collections,
						},
					});
				} else {
					await updateSecodaEntity({
						data: {
							id: entity.id,
							collections: without(entity.collections, this.collection!.id),
						},
					});
				}
			})
		);

		await this.fetchTabs(true);
		entityTableStore.reload();
	};

	addToCollection = async (newEntity: SecodaEntity) => {
		const entityType = newEntity.entity_type;
		const entity = await fetchSecodaEntity(newEntity.id);

		if (includes(entity.collections, this.collection!.id)) {
			showCollectionNotification('warning', newEntity.title);
			return;
		}

		const updatedCollections = [...entity.collections, this.collection!.id];

		try {
			await updateSecodaEntity({
				data: {
					id: entity.id,
					collections: updatedCollections,
				},
			});
			this.collection = await fetchCollection(this.collection!.id);
			runInAction(() => {
				// In practice this should never be undefined.
				if (this.tabs) {
					this.tabs[entityType] = (this.tabs?.[entityType] ?? 0) + 1;
				}
			});

			this.setActiveTab(entityType);

			await this.fetchTabs(true);
			entityTableStore.reload();

			showCollectionNotification('success', newEntity.title);
		} catch (error) {
			showCollectionNotification('warning', entity.title || 'Untitled');
		}
	};
}
