import type { IntegrationSpec } from '../../interfaces/integrationSpec';
import { IntegrationCategory } from '../../interfaces/integrationSpec';
import { integrationList } from './integrations';

export const CERTIFICATE_PLACEHOLDER = `DROP CERTIFICATE FILE HERE OR PASTE CERTIFICATE CONTENT

-----BEGIN CERTIFICATE-----
MIIEMDCCApigAwIBAgIDI2GWMA0GCSqGSIb3DQEBDAUAMDoxODA2BgNVBAMML2Fm
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
...
-----END CERTIFICATE-----
`;

export const isSchemaFirstIntegration = (integration: { type: string }) => {
	const found = integrationList.find((i) => i.type === integration.type)!;
	return !!found.schemaSupport && !found.skipSchemaSelect;
};

export const isDashboardIntegration = (integration: { type: string }) => {
	const found = integrationList.find((i) => i.type === integration.type)!;
	return found.categories!.includes(IntegrationCategory.DASHBOARD);
};

export const isDBTCloudIntegration = (integration: { type: string }) =>
	integration.type === 'dbt';

export const isTestableIntegration = (
	integrationSpec: IntegrationSpec,
	group?: number | string
) => {
	if (integrationSpec.type === 'marketplace') {
		return false;
	}

	const found = integrationSpec.value;

	return found.groupSettings?.[group || '']?.testable || found.testable;
};

export const isWarehouseOrDatabaseIntegration = (integration: {
	type: string;
}) => {
	const found = integrationList.find((i) => i.type === integration.type)!;
	return (
		found.categories?.includes(IntegrationCategory.WAREHOUSE) ||
		found.categories?.includes(IntegrationCategory.DATABASE)
	);
};

export const isQueryBlockIntegration = (spec: { type: string }) =>
	integrationList.find((i) => i.type === spec.type)?.queryBlockSupport;

export const isPreviewIntegration = (spec: { type: string }) =>
	integrationList.find((i) => i.type === spec.type)?.previewSupport;
