import { Box, createStyles, Divider, Group, Modal, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button, Icon, Text } from '@repo/foundations';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { IMetric, MetricNumericFormat } from '../../../../../../api';
import { useMetric } from '../../../../../../api';
import { useCheckEntityUpdatePermission } from '../../../../../../utils/authorization/roles';
import { metricOptions } from '../../../../../../utils/metrics';
import { SelectableProperty } from '../../../../../EntityPageLayout/EntityPropertySidebar/SelectableProperty';
import { MetricChart } from './MetricChart';

const useStyles = createStyles((theme) => ({
	modalDivider: {
		borderColor: theme.other.getColor('border/secondary/default'),
	},
}));

const NUMERIC_FORMAT_OPTIONS = [
	{ value: 'number', label: 'Numeric', icon: null },
	{ value: 'percent', label: 'Percentage', icon: null },
	{ value: 'currency', label: 'Currency', icon: null },
	{ value: 'decimal', label: 'Decimal', icon: null },
];

export interface CreateGraphModalResult {
	metric: IMetric;
	xAxis: string;
	yAxis: string;
	dimension: string;
	numericFormat: MetricNumericFormat;
}

export interface MetricInfo extends IMetric {
	inDoc: boolean;
	parentUrl?: string;
}

export interface CreateGraphModalProps {
	initialMetricId?: string;
	initialXAxis?: string;
	initialYAxis?: string;
	initialDimension?: string;
	initialNumericFormat?: MetricNumericFormat;
	metrics?: MetricInfo[];
	onClose: () => void;
	onSave: (result: CreateGraphModalResult) => Promise<void>;
}

export function CreateGraphModal({
	initialMetricId,
	initialXAxis,
	initialYAxis,
	initialDimension,
	initialNumericFormat,
	metrics,
	onClose,
	onSave,
}: CreateGraphModalProps) {
	const { classes, theme } = useStyles();

	const [metricId, setMetricId] = useState(initialMetricId);

	const { data: metric } = useMetric({
		id: metricId ?? '',
		options: {
			enabled: !!metricId,
		},
	});

	const canEditMetric = useCheckEntityUpdatePermission({
		entity: metric,
	});
	const viewerOfEntity = !canEditMetric;

	const [xAxis, setXAxis] = useState(initialXAxis ?? metric?.time ?? '');
	const [yAxis, setYAxis] = useState(initialYAxis ?? metric?.primary ?? '');
	const [dimension, setDimension] = useState(
		initialDimension ?? metric?.dimension ?? ''
	);
	const [numericFormat, setNumericFormat] = useState(
		initialNumericFormat ?? metric?.numeric_format ?? 'number'
	);

	const handleSave = useCallback(async () => {
		if (!metric) {
			showNotification({
				title: 'Error',
				message: 'You need to select a query to create a graph.',
				color: 'red',
				icon: <Icon name="alertCircle" />,
			});
			return;
		}

		await onSave({ xAxis, yAxis, dimension, numericFormat, metric });
	}, [dimension, metric, numericFormat, onSave, xAxis, yAxis]);

	const { timeCols, metricCols, dimensionCols } = useMemo(
		() => metricOptions(metric?.results ?? []),
		[metric]
	);

	const metricsOptions = useMemo(
		() =>
			metrics
				?.sort((a, b) => Number(b.inDoc) - Number(a.inDoc))
				?.map((m) => ({
					group: m.inDoc ? 'From this document' : 'From this workspace',
					value: m.id,
					label: m.title ?? 'Untitled query',
					icon: <Icon name="sql" color="icon/primary/default" />,
					navigateTo: m.parentUrl,
				})) ?? [],
		[metrics]
	);

	const primaryOptions = useMemo(
		() =>
			metricCols.map(({ name }) => ({
				value: name,
				label: name,
				icon: <Icon name="hash" color="icon/primary/default" />,
			})) ?? [],
		[metricCols]
	);

	const timeOptions = useMemo(
		() =>
			timeCols.map(({ name }) => ({
				value: name,
				label: name,
				icon: <Icon name="calendar" color="icon/primary/default" />,
			})) ?? [],
		[timeCols]
	);

	const dimensionOptions = useMemo(() => {
		let result: { value: string; label: string; icon: JSX.Element }[] = [];
		if (dimensionCols.length > 0) {
			result = result.concat([
				{
					value: '',
					label: 'None',
					icon: <Icon name="letterCase" color="icon/primary/default" />,
				},
			]);
		}
		result = result.concat(
			dimensionCols.map(({ name }) => ({
				value: name,
				label: name,
				icon: <Icon name="letterCase" color="icon/primary/default" />,
			}))
		);
		return result;
	}, [dimensionCols]);

	useEffect(() => {
		if (!metricId && metricsOptions.length > 0) {
			setMetricId(metricsOptions[0].value);
		}
	}, [metricsOptions, metricId]);

	useEffect(() => {
		if (!initialYAxis && primaryOptions.length > 0) {
			setYAxis(primaryOptions[0].value);
		}

		if (!initialXAxis && timeOptions.length > 0) {
			setXAxis(timeOptions[0].value);
		}

		if (!initialDimension && dimensionOptions.length > 1) {
			setDimension(dimensionOptions[1].value);
		}
	}, [
		dimensionOptions,
		initialDimension,
		initialXAxis,
		initialYAxis,
		primaryOptions,
		timeOptions,
	]);

	return (
		<Modal
			opened
			onClose={onClose}
			title={metric?.title ?? 'Create graph'}
			size={960}
		>
			<Stack spacing={0}>
				<Group spacing={0} align="flex-start">
					<Box style={{ flex: 1 }} p="md">
						<MetricChart
							results={metric?.results ?? []}
							xAxis={xAxis}
							yAxis={yAxis}
							dimension={dimension}
							numericFormat={numericFormat}
						/>
					</Box>
					<Divider orientation="vertical" className={classes.modalDivider} />
					<Stack p="xl" spacing="lg" miw={360}>
						<Text size="sm" weight="bold">
							Configuration
						</Text>
						<Stack spacing="xs">
							{!initialMetricId && (
								<SelectableProperty
									searchable
									selected={metricId ?? ''}
									onChange={(value) => setMetricId(value as string)}
									type="single"
									label="Query"
									value="query"
									iconType="react-node"
									isViewerUser={viewerOfEntity}
									options={metricsOptions}
								/>
							)}
							<SelectableProperty
								searchable
								selected={xAxis}
								onChange={(value) => setXAxis(value as string)}
								type="single"
								label="X-axis"
								value="time"
								iconType="react-node"
								isViewerUser={viewerOfEntity}
								options={timeOptions}
							/>
							<SelectableProperty
								searchable
								selected={yAxis}
								onChange={(value) => setYAxis(value as string)}
								type="single"
								label="Y-axis"
								value="primary"
								iconType="react-node"
								isViewerUser={viewerOfEntity}
								options={primaryOptions}
							/>
							<SelectableProperty
								searchable
								selected={dimension}
								onChange={(value) => setDimension(value as string)}
								type="single"
								label="Dimensions"
								value="dimension"
								iconType="react-node"
								isViewerUser={viewerOfEntity}
								options={dimensionOptions}
							/>
							<SelectableProperty
								searchable
								selected={numericFormat}
								onChange={(value) =>
									setNumericFormat(value as MetricNumericFormat)
								}
								type="single"
								label="Format"
								value="numeric_format"
								iconType="react-node"
								isViewerUser={viewerOfEntity}
								options={NUMERIC_FORMAT_OPTIONS}
							/>
						</Stack>
					</Stack>
				</Group>
				<Group
					pt="md"
					spacing="md"
					position="apart"
					style={{
						borderTop: `1px solid ${theme.other.getColor('border/secondary/default')}`,
					}}
				>
					<Text size="sm" color="text/secondary/default">
						Learn more about{' '}
						<a
							href="https://docs.secoda.co/features/queries/running-queries-in-secoda/adding-live-charts-to-documentation"
							target="_blank"
						>
							configuring graphs
						</a>
					</Text>
					<Group spacing="xs">
						<Button variant="default" onClick={onClose}>
							Cancel
						</Button>
						<Button variant="primary" onClick={handleSave}>
							{initialXAxis ? 'Save' : 'Create graph'}
						</Button>
					</Group>
				</Group>
			</Stack>
		</Modal>
	);
}
