import type { DataAccessRequestOut } from '@repo/api-codegen';
import { useCallback } from 'react';
import { useCreateComment } from '../../../api';
import CommentForm from '../../Comment/components/CommentForm';
import { removeTrailingWhitespaces } from '../../Editor/outline/src/utils/removeTrailingWhitespaces';

export function NewCommentForm({
	request,
	onCreate,
}: {
	request: DataAccessRequestOut;
	onCreate: () => void;
}) {
	const { mutateAsync: createComment } = useCreateComment({
		options: {
			onSuccess: onCreate,
		},
	});

	const handleSubmitComment = useCallback(
		async (definition: string) =>
			createComment({
				data: {
					data_access_request: request.id,
					definition: removeTrailingWhitespaces(definition),
				},
			}),
		[createComment, request.id]
	);

	return (
		<CommentForm
			definition=""
			onSubmit={handleSubmitComment}
			onCancel={() => {}}
		/>
	);
}
