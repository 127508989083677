import { FilterOptionType } from '@repo/common/components/Filter';
import {
	DEFAULT_FILTER_OPTIONS,
	DEFAULT_FILTER_OPTIONS_WITH_DQS,
	FILTER_OPTIONS_DIVIDER,
} from '@repo/common/components/Filter/constants';
import { useMemo } from 'react';
import { useDataQualityAccess } from '../../api';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import { FILTER_OPTIONS_CONFIG } from './constants';

export function useCatalogFilter() {
	const { enableAi } = useAiEnabled();
	const dqsEnabled = useDataQualityAccess();

	return useMemo(() => {
		let options = dqsEnabled
			? DEFAULT_FILTER_OPTIONS_WITH_DQS
			: DEFAULT_FILTER_OPTIONS;

		if (enableAi) {
			options = [FilterOptionType.AI, FILTER_OPTIONS_DIVIDER, ...options];
		}

		return options.map((option) =>
			option === FILTER_OPTIONS_DIVIDER
				? FILTER_OPTIONS_DIVIDER
				: FILTER_OPTIONS_CONFIG[option]
		);
	}, [dqsEnabled, enableAi]);
}
