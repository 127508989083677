import { Select } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import type { ISecodaEntity, ISlackChannel } from '../../api';
import { queryClient, useAuthUser } from '../../api';
import {
	slackhooksQueryKeyFactory,
	useCreateSlackHook,
} from '../../api/hooks/workspace/useSlackHooks';
import type { SecodaEntity } from '../../lib/models';
import { trackEvent } from '../../utils/analytics';

interface IEntityNotificationHookEntryInsertProps {
	entity: SecodaEntity | ISecodaEntity;
	channels: ISlackChannel[];
}

function EntityNotificationHookEntryInsert(
	props: IEntityNotificationHookEntryInsertProps
) {
	const { entity, channels } = props;
	const [selected, setSelected] = useState('');
	const { user, workspace } = useAuthUser();

	const { mutateAsync: createSlackHook } = useCreateSlackHook({});

	const updateHook = async (hook: string) => {
		setSelected(hook);
		await createSlackHook({
			data: {
				entity: entity.id,
				slack_conversation_id: hook,
				slack_conversation_name: channels.find((c) => c.id === hook)?.name,
			},
		});
		queryClient.invalidateQueries(slackhooksQueryKeyFactory.all());
		trackEvent(`${entity.entity_type}/slack/add`, {}, user, workspace!);
	};

	const data = useMemo(
		() =>
			channels.map((channel) => ({
				label: channel.name,
				value: channel.id,
			})),
		[channels]
	);

	return (
		<Select
			label="Connect to Slack channel"
			placeholder="Pick a channel"
			data={data}
			value={selected}
			onChange={updateHook}
			searchable
		/>
	);
}

export default observer(EntityNotificationHookEntryInsert);
