import type { ISlackHook } from '../../../api';
import { baseQueryHooksFactory, createQueryKeys } from '../../factories';

export const SLACKHOOKS_NAMESPACE = ['notification', 'hooks', 'entity'];

export const slackhooksQueryKeyFactory = createQueryKeys(SLACKHOOKS_NAMESPACE);

const {
	useCreateSlackHook,
	useDeleteSlackHook,
	useUpdateSlackHook,
	useSlackHookList,
	updateSlackHook,
	fetchSlackHookList,
} = baseQueryHooksFactory<ISlackHook, 'SlackHook'>(
	'SlackHook',
	slackhooksQueryKeyFactory
);

export {
	fetchSlackHookList,
	updateSlackHook,
	useCreateSlackHook,
	useDeleteSlackHook,
	useSlackHookList,
	useUpdateSlackHook,
};
