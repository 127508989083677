import type { TeamOut } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { filter, isEmpty, isNil, noop } from 'lodash-es';
import type { NavigateFunction } from 'react-router';
import type { IBaseModel, IWorkspace } from '../../../../api';
import type { User } from '../../../../lib/models';
import { entityModalStore } from '../../../EntityModal/store';
import { closeSpotlight } from '../../events';
import type { ICommandListItem } from './constants';
import { groupCommandTitleList, staticCommandList } from './constants';

export const generateCreateCommandList = (
	workspace: IWorkspace
): ICommandListItem<IBaseModel>[] => [
	{
		id: 'create::question',
		title: 'Create Question',
		name: 'Create Question',
		link: workspace.default_question_form,
		show: true,
		iconName: 'messages',
		hotkey: '/nq',
		type: EntityType.question,
		team: undefined,
		category: 'create',
	},
	{
		id: 'create::document',
		title: 'Create Document',
		name: 'Create Document',
		link: workspace.default_document_form,
		show: true,
		iconName: 'fileAnalytics',
		hotkey: '/nd',
		type: EntityType.document,
		team: undefined,
		category: 'create',
	},
	{
		id: 'create::glossary',
		title: 'Create Glossary',
		name: 'Create Glossary',
		link: null,
		show: true,
		iconName: 'book',
		hotkey: '/nt',
		type: EntityType.glossary,
		team: undefined,
		category: 'create',
	},
	{
		id: 'create::collection',
		title: 'Create Collection',
		name: 'Create Collection',
		link: null,
		show: true,
		iconName: 'folders',
		hotkey: '/nc',
		type: EntityType.collection,
		team: undefined,
		category: 'create',
	},
];

export const generateTeamCommandsList = (team: {
	id: string;
	name: string;
	link?: string | null;
	icon?: string | null;
	hotkey?: string;
	type: string;
	team?: string | null;
	category?: string;
}): ICommandListItem<IBaseModel>[] => [
	{
		id: team.id,
		name: team.name,
		type: team.type,
		icon: team.icon || '',
		iconName: null,
		title: team.name,
		show: true,
	},
];
export function generateTeamBreadcrumbs(
	event: ICommandListItem<IBaseModel>,
	workspace: IWorkspace
) {
	return generateCreateCommandList(workspace).map((item) => ({
		...item,
		id: `create::${item.title}::${event?.name}`,
		name: `${item.name} - ${event?.name}`,
		team: event.id,
	}));
}

export function generateCreateBreadcrumbs(
	event: ICommandListItem<IBaseModel>,
	teams: TeamOut[]
) {
	return (
		teams.map((team) => ({
			...team,
			type: event.type,
			id: `create::${team.name}::${event.name}`,
			name: `${event.name} - ${team.name}`,
			team: team.id,
			show: true,
			icon: team.icon || undefined,
			iconName: null,
			title: team.name,
		})) ?? []
	);
}

export function getInitialResults(
	workspace: IWorkspace,
	teamsCommandList?: ICommandListItem<IBaseModel>[],
	actionsCommandList?: ICommandListItem<IBaseModel>[]
) {
	const actions = actionsCommandList ?? [];
	const teams = teamsCommandList ?? [];

	if (!isEmpty(actions)) {
		return filter(
			[!isEmpty(actions) ? groupCommandTitleList[3] : null, ...actions],
			(el) => !isNil(el) && !isEmpty(el)
		);
	}

	return filter(
		[
			groupCommandTitleList[0],
			...generateCreateCommandList(workspace),
			groupCommandTitleList[1],
			...staticCommandList,
			!isEmpty(teams) ? groupCommandTitleList[2] : null,
			...teams,
		],
		(el) => !isNil(el) && !isEmpty(el)
	);
}

export function getSearchResults(
	workspace: IWorkspace,
	teamsCommandList?: ICommandListItem<IBaseModel>[],
	actionsCommandList?: ICommandListItem<IBaseModel>[]
) {
	const actions = actionsCommandList ?? [];
	const teams = teamsCommandList ?? [];

	if (!isEmpty(actions)) {
		return filter(
			[...actions, ...generateCreateCommandList(workspace)],
			(el) => !isNil(el) && !isEmpty(el)
		);
	}

	return filter(
		[
			...actions,
			...generateCreateCommandList(workspace),
			...staticCommandList,
			...teams,
		],
		(el) => !isNil(el) && !isEmpty(el)
	);
}

export function handleCommandPaletteItem(
	cmdListItem: ICommandListItem<IBaseModel>,
	navigate: NavigateFunction,
	user: User,
	team?: string
) {
	if (cmdListItem.onClick) {
		cmdListItem.onClick(cmdListItem.selected ?? [], noop);
	} else if (cmdListItem.type === 'group') {
		closeSpotlight('command');
	} else if (cmdListItem.type === 'static' && cmdListItem.link) {
		navigate(cmdListItem.link);
	} else if (cmdListItem.type === 'OPEN') {
		navigate(`/teams/${cmdListItem.id}`);
	} else {
		entityModalStore.open({
			type: cmdListItem.type as EntityType,
			teamId: team,
			metadata: {
				owners: [user.id],
			},
		});
	}
}
