import { createQueryKeys } from '../../factories';

export const NOTIFICATION_NAMESPACE = ['notification', 'notifications'];
export const notificationQueryKeyFactory = createQueryKeys(
	NOTIFICATION_NAMESPACE
);

export const USER_ACTIVITY_NAMESPACE = ['notification', 'activity'];

export const userActivityQueryKeyFactory = createQueryKeys(
	USER_ACTIVITY_NAMESPACE
);

export const WORKSPACE_ACTIVITY_NAMESPACE = ['notification', 'workspace'];

export const workspaceActivityQueryKeyFactory = createQueryKeys(
	WORKSPACE_ACTIVITY_NAMESPACE
);

export const RESOURCE_ACTIVITY_NAMESPACE = [
	'notification',
	'resource_activity',
];

export const resourceActivityQueryKeyFactory = createQueryKeys(
	RESOURCE_ACTIVITY_NAMESPACE
);

export const NOTIFICATION_UNREAD_COUNT_NAMESPACE = [
	'notification',
	'notifications',
	'unread_count',
];
export const notificationUnreadCountQueryKeyFactory = createQueryKeys(
	NOTIFICATION_UNREAD_COUNT_NAMESPACE
);

export const NOTIFICATION_CHANGE_REQUEST_NAMESPACE = [
	'notification',
	'change_requests',
];

export const notificationChangeRequestQueryKeyFactory = createQueryKeys(
	NOTIFICATION_CHANGE_REQUEST_NAMESPACE
);

export const ACCESS_REQUESTED_NAMESPACE = ['notification', 'access_requests'];

export const notificationAccessRequestQueryKeyFactory = createQueryKeys(
	ACCESS_REQUESTED_NAMESPACE
);

export const NOTIFICATION_SETTINGS_NAMESPACE = ['notification', 'settings'];

export const notificationSettingsQueryKeyFactory = createQueryKeys(
	NOTIFICATION_SETTINGS_NAMESPACE
);
