import { Menu } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { useCallback } from 'react';
import { useAuthUser } from '../../../../../api';
import {
	useDeleteAllNotifications,
	useDeleteNotification,
	useMarkNotificationAsRead,
} from '../../../../../api/hooks/notification';
import { notificationQueryKeyFactory } from '../../../../../api/hooks/notification/constants';
import { getFilters } from '../../../../../api/hooks/notification/utils';
import type { INotification } from '../../../../../api/types/models/notification';
import { trackEvent } from '../../../../../utils/analytics';
import { useInboxEventTypes } from '../../../useInboxEventTypes';
import { getEventsFilter } from '../../../utils';

interface INotificationActionsProps {
	selected: INotification | undefined;
}

function NotificationActions({ selected }: INotificationActionsProps) {
	const { user, workspace } = useAuthUser();
	const inboxEventTypes = useInboxEventTypes();

	const { mutate: markAsRead } = useMarkNotificationAsRead();

	const { page, filters } = getFilters();

	const { mutate: deleteNotification } = useDeleteNotification({
		invalidationKeys: [notificationQueryKeyFactory.list(page, filters)],
	});

	const { mutate: deleteAllNotifications } = useDeleteAllNotifications();

	const handleDeleteAll = useCallback(() => {
		trackEvent('inbox/delete_all/click', {}, user, workspace!);
		deleteAllNotifications({
			events: getEventsFilter(inboxEventTypes, '', true),
		});
	}, [deleteAllNotifications, inboxEventTypes, user, workspace]);

	const handleDeleteAllRead = useCallback(() => {
		trackEvent('inbox/delete_read/click', {}, user, workspace!);
		deleteAllNotifications({
			events: getEventsFilter(inboxEventTypes, '', true),
			read: true,
		});
	}, [deleteAllNotifications, inboxEventTypes, user, workspace]);

	const handleMarkAsRead = useCallback(() => {
		if (!isNil(selected)) {
			markAsRead({
				id: selected.id,
				status: !selected.read,
			});
		}
	}, [markAsRead, selected]);

	const handleDelete = useCallback(() => {
		if (!isNil(selected)) {
			deleteNotification({
				id: selected.id,
			});
		}
	}, [deleteNotification, selected]);

	const handleMarkAllAsRead = useCallback(() => {
		trackEvent('inbox/all_read/click', {}, user, workspace!);
		markAsRead({
			id: undefined,
			all: true,
			events: getEventsFilter(inboxEventTypes, '', true),
			status: true,
		});
	}, [user, workspace, markAsRead, inboxEventTypes]);

	return (
		<>
			{!isNil(selected) && (
				<>
					<Menu.Item
						icon={
							selected?.read ? (
								<Icon name="notification" />
							) : (
								<Icon name="squareCheck" />
							)
						}
						onClick={handleMarkAsRead}
					>
						{selected?.read ? 'Mark as unread' : 'Mark as read'}
					</Menu.Item>
					<Menu.Item icon={<Icon name="trash" />} onClick={handleDelete}>
						Delete notification
					</Menu.Item>
					<Menu.Divider />
				</>
			)}
			<Menu.Item
				icon={<Icon name="squareCheck" />}
				onClick={handleMarkAllAsRead}
			>
				Mark all notifications as read
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item icon={<Icon name="trash" />} onClick={handleDeleteAll}>
				Delete all notifications
			</Menu.Item>
			<Menu.Item icon={<Icon name="trash" />} onClick={handleDeleteAllRead}>
				Delete all read notifications
			</Menu.Item>
		</>
	);
}

export default NotificationActions;
