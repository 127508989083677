import { Stack } from '@mantine/core';
import type { Filter } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import type { IDocument } from '../../api';
import { useAuthUser } from '../../api';
import type { FetchModelInfiniteListHook } from '../../api/factories/types';
import {
	fetchCatalogList,
	useCatalogInfiniteList,
} from '../../api/hooks/resourceCatalog';
import { useTeamEmptyState } from '../../api/hooks/team/emptyStates';
import { useTemplateList } from '../../api/hooks/templates';
import { EmptyState, type ButtonDetails } from '../../components/EmptyState';
import { entityModalStore } from '../../components/EntityModal/store';
import { useCatalogFilter } from '../../components/Filter/useCatalogFilter';
import { TableV2 } from '../../components/TableV2';
import type { OnCellClickHandlerParams } from '../../components/TableV2/types';
import { useHomepageTeam } from '../HomePage/hooks/useHomepageTeam';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import { useActions, useColumns } from './TeamDocumentsPage.hooks';

const COLUMN_VISIBILITY = {
	catalogType: EntityType.document,
	catalogServerType: EntityType.document,
} as const;

const DEFAULT_CATALOG_FILTERS = {
	operands: [
		{
			operator: 'not',
			field: 'parent_id',
			operands: [],
		},
	],
} as { readonly operands: readonly Filter[] };

const DEFAULT_SEARCH_PARAMS_NESTING = {
	entity_type: EntityType.document,
	calculate_children_count: true,
} as const;

const PAGINATION_LIST_OPTIONS = {
	suspense: true,
};

const DEFAULT_SORT = {
	field: 'updated_at',
	order: 'desc',
} as const;

const QUICK_ACTIONS = [
	'actions::sidebar',
	'actions::verified',
	'actions::delete',
] as const;

function TeamDocumentsPage() {
	const { classes } = useStyles();
	const { teamId } = useParams();
	const navigate = useNavigate();

	const { user, isAdminUser, isViewerOrGuestUser } = useAuthUser();
	const { isTeamEditorUser } = useHomepageTeam();

	const columns = useColumns();
	const actions = useActions();

	const { data: defaultTemplates } = useTemplateList({
		filters: {
			is_default: true,
			entity_type_template: EntityType.document,
		},
	});

	const handleCellClick = useCallback(
		({ column, record }: OnCellClickHandlerParams<IDocument>) => {
			if (column.navigate !== false) {
				navigate(`/docs/${record.id}`);
			}
		},
		[navigate]
	);

	const handleCreateDocumentClick = useCallback(async () => {
		await entityModalStore.quickCreateEntity(
			EntityType.document,
			'Untitled',
			[teamId ?? ''],
			{
				owners: [user.id],
			},
			navigate,
			defaultTemplates?.results?.[0]
		);
	}, [teamId, user.id, navigate, defaultTemplates]);

	const emptyState = useTeamEmptyState(teamId as string, 'document', {
		enabled: !isViewerOrGuestUser,
	});

	const buttons: ButtonDetails[] = useMemo(
		() => [
			{
				name: 'Learn more',
				action: () => {
					window.open('https://docs.secoda.co/features/documents', '_blank');
				},
				isPrimary: false,
				size: 'md',
				leftIconName: 'externalLink',
			},
			...(isTeamEditorUser || isAdminUser
				? [
						{
							name: 'Create document',
							action: handleCreateDocumentClick,
							isPrimary: true,
							size: 'md',
						} as ButtonDetails,
					]
				: []),
		],
		[handleCreateDocumentClick, isAdminUser, isTeamEditorUser]
	);

	const defaultSearchParams = useMemo(
		() => ({
			team_id: teamId,
			entity_type: EntityType.document,
			calculate_children_count: true,
		}),
		[teamId]
	);

	const filterOptions = useCatalogFilter();

	if (!isViewerOrGuestUser) {
		if (emptyState?.is_empty) {
			return (
				<EmptyState
					illustrationName="documents"
					title="Welcome to Documents"
					description="Add the necessary context to your data resources and capture data knowledge with documents"
					includeGoBack={false}
					buttons={buttons}
					stateHeight="80vh"
					size="lg"
				/>
			);
		}
	}

	return (
		<Stack className={classes.wrapper}>
			<TableV2<IDocument>
				withCustomProperties={EntityType.document}
				pluralTypeString="documents"
				withInfiniteScroll
				withQuickActions={QUICK_ACTIONS}
				usePaginationList={
					useCatalogInfiniteList as FetchModelInfiniteListHook<IDocument>
				}
				fetchPaginationList={fetchCatalogList}
				withSearch
				withFilterOptions={filterOptions}
				withInteractiveHeader
				withCsvExport
				withServerCsvExport
				withCheckbox
				defaultSort={DEFAULT_SORT}
				withActions={actions}
				onCellClick={handleCellClick}
				columns={columns}
				columnVisibility={COLUMN_VISIBILITY}
				nestingFilter="parent_id"
				defaultRequiredCatalogFilters={DEFAULT_CATALOG_FILTERS}
				defaultRequiredSearchParamsNesting={DEFAULT_SEARCH_PARAMS_NESTING}
				defaultRequiredSearchParams={defaultSearchParams}
				usePaginationListOptions={PAGINATION_LIST_OPTIONS}
			/>
		</Stack>
	);
}

export default TeamDocumentsPage;
