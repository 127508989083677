import { Group } from '@mantine/core';
import { UserRole } from '@repo/common/enums/UserRole';
import { Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import { useFeatureAccess } from '../../../api/hooks/workspace/useFeatureAccess';
import AddPolicyButton from '../../../pages/PoliciesListPage/AddPolicyButton';
import { Guard } from '../../Guard/Guard';

const PoliciesListPageNavBar = observer(() => {
	const { policiesAccess } = useFeatureAccess();
	if (!policiesAccess) {
		return null;
	}

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Group>
					<Title order={1} size="sm" data-testid="policies-page-nav-bar-title">
						Policies
					</Title>
				</Group>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<Guard
					v1AllowedRoles={[UserRole.ADMIN, UserRole.EDITOR]}
					v2Permission="Policies.Create"
				>
					<AddPolicyButton />
				</Guard>
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
});

PoliciesListPageNavBar.displayName = 'PoliciesListPageNavBar';
export default PoliciesListPageNavBar;
