import type { PluginWithOptions } from 'markdown-it';
import type { Attrs, Node, Schema } from 'prosemirror-model';
import type { EditorState, Transaction } from 'prosemirror-state';
import type { EditorView } from 'prosemirror-view';
import type React from 'react';
import type { baseDictionary } from '.';

export enum ToastType {
	Error = 'error',
	Info = 'info',
}

export type MenuItem = {
	icon?: typeof React.Component | React.FC<any>;
	name?: string;
	title?: string;
	shortcutKeys?: string[];
	keywords?: string;
	tooltip?: string;
	defaultHidden?: boolean;
	attrs?: Attrs;
	visible?: boolean;
	active?: (state: EditorState) => boolean;
	menuOptions?: MenuItem[];
};

export type EmbedDescriptor = MenuItem & {
	matcher: (url: string) => boolean | [] | RegExpMatchArray;
	component: typeof React.Component | React.FC<any>;
};

export interface SecodaEditorState {
	id?: string;
	singleLineEditor: boolean;
	isTemplate: boolean;
	readOnly: boolean;
	disableExtensions: Array<string>;
	schema: Schema;
	view: EditorView;
	uploadFile?: (file: File, isImage: boolean) => Promise<string>;
	onFileUploadStart?: () => void;
	onFileUploadStop?: () => void;
	onShowToast: (message: string, code: ToastType | string) => void;
	embeds?: EmbedDescriptor[];
	disableResourceLinking?: boolean;
}

export type EditorDictionary = typeof baseDictionary;

export interface MarkdownPluginOptions {
	disabledExtensions: Array<string>;
}

export type MarkdownPlugin = PluginWithOptions<MarkdownPluginOptions>;

export type SecodaEditorComponentProps = {
	node: Node;
	theme: unknown;
	isSelected: boolean;
	isEditable: boolean;
	getPos: () => number;
	view: EditorView;
};

export type Dispatch = (tr: Transaction) => void;
