import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { updateWorkspace, useAuthUser } from '../../api';

export function BillingCallback() {
	const { workspace } = useAuthUser();

	// Get URL query params customer_id and customer_plan_id
	const urlParams = new URLSearchParams(window.location.search);
	const customerId = urlParams.get('customer_id') || '';
	const customerPlanId = urlParams.get('customer_plan_id') || '';

	// Check if urlParams are not empty
	if (
		customerId &&
		customerPlanId &&
		workspace.customer_id !== customerId &&
		workspace.customer_plan_id !== customerPlanId
	) {
		// Save to the workspace
		workspace!.customer_id = customerId;
		workspace!.customer_plan_id = customerPlanId;

		updateWorkspace({
			data: {
				id: workspace.id,
				customer_id: customerId,
				customer_plan_id: customerPlanId,
			},
		});

		// Show notification that plan has been updated
		showNotification({
			title: 'Plan updated',
			message: 'Your plan has been updated to Growth',
			color: 'green',
		});

		window.open('/settings/billing', '_self');
	}

	return <Box />;
}
