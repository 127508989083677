import { showNotification } from '@mantine/notifications';
import type { IconNames } from '@repo/foundations';
import { Icon, Text } from '@repo/foundations';
import { filter } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type {
	Automation,
	DefaultContext,
	ISecodaEntity,
	UpdateRequestParams,
} from '../../api';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { Box, createStyles, Group, rem, useMantineTheme } from '@mantine/core';
import IconEmojiSelector from '@repo/common/components/IconEmojiSelector';
import { SELECTABLE_PROPERTY_OPTIONS } from '@repo/common/components/SelectableProperty/constants';
import { EntityType } from '@repo/common/enums/entityType';
import type { ColorNames } from '@repo/theme/utils';
import type { UpdateModelHook } from '../../api/factories/types';
import {
	useAutomationRun,
	useDeleteAutomation,
	useUpdateAutomation,
} from '../../api/hooks/automation';
import { automationQueryKeyFactory } from '../../api/hooks/automation/constants';
import { getCadenceDisplayLabel } from '../../components/Automation/AutomationCard/AutomationTriggerCard/constants';
import type { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';
import { useDeleteManyAction } from '../../components/TableV2/BaseModel.hooks';
import { useGenericColumns } from '../../components/TableV2/SecodaEntity.hooks';
import { ROW_HEIGHT } from '../../components/TableV2/TableV2.styles';
import { BadgeRender, DateTimeRender } from '../../components/TableV2/render';

const useStyles = createStyles((theme) => {
	const hoverFillColor = 'fill/transparent/hover';
	const activeFillColor = 'fill/transparent/active';
	const hoverIconColor: ColorNames = 'icon/primary/hover';
	const paddingY: number = theme.other.space[1.5];
	const activeIconColor: ColorNames = 'icon/primary/active';
	const boxShadow = '';
	const boxShadowOnActive = '';

	return {
		container: {
			height: theme.other.space[7],
			width: theme.other.space[7],
			flexShrink: 0,
			padding: `${paddingY} 0`,
			position: 'relative',

			borderRadius: theme.other.space[2],

			boxShadow: boxShadow,
			transition: 'box-shadow 0.075s ease-in-out 0s',

			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',

			'&:hover': {
				color: theme.other.getColor(hoverIconColor),
				backgroundColor: theme.other.getColor(hoverFillColor),
			},
			'&:active': {
				color: theme.other.getColor(activeIconColor),
				backgroundColor: theme.other.getColor(activeFillColor),
				boxShadow: boxShadowOnActive,
				paddingTop: undefined,
				paddingBottom: undefined,
			},
			'&:focus': {
				outline: `solid ${theme.other.getColor('border/emphasis/default')} 2px`,
				outlineOffset: rem(theme.other.space[0.25]),
			},
		},
	};
});

export function useColumns(): ExtendedDataTableColumn<Automation>[] {
	const theme = useMantineTheme();
	const { classes } = useStyles();

	const genericColumns = useGenericColumns({
		useUpdate: useUpdateAutomation as unknown as UpdateModelHook<
			ISecodaEntity,
			UpdateRequestParams<ISecodaEntity>,
			DefaultContext<ISecodaEntity>,
			unknown
		>,
	});

	const { mutateAsync: updateAutomation } = useUpdateAutomation({});

	const handleIconChange = useCallback(
		(id: string) => async (value: string) => {
			await updateAutomation({
				data: {
					id: id,
					['icon']: value,
				},
			});
		},
		[updateAutomation]
	);

	const columns = useMemo(
		() => [
			{
				accessor: 'title',
				title: 'Name',
				width: 150,
				render: (record: Automation) => (
					<Group noWrap spacing={theme.spacing['xs']}>
						<Box
							className={classes.container}
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}
						>
							<IconEmojiSelector
								value={record.icon ?? 'iconName:bolt iconColor:#4a4a4a'}
								onChange={handleIconChange(record.id)}
								iconSize={15}
								emojiSize={15}
								entityType={'automation'}
							/>
						</Box>
						<Text
							size="sm"
							sx={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								lineHeight: `${ROW_HEIGHT}px`,
							}}
							color="text/primary/default"
							fw={600}
						>
							{record?.title_cased ?? record?.title ?? 'Untitled'}
						</Text>
					</Group>
				),
			},

			{
				accessor: 'schedule',
				title: 'Schedule',
				render: (record: Automation) => {
					if (!record.schedule) {
						return '';
					}
					return getCadenceDisplayLabel(record.schedule);
				},
			},
			{
				accessor: 'status',
				title: 'Status',
				render: (record: Automation) => (
					<BadgeRender
						record={record}
						field="status"
						badgeOptions={Object.entries(
							SELECTABLE_PROPERTY_OPTIONS.automationStatus
						).map(([key, value]) => ({
							color: value.color || 'fill/transparent-secondary/default',
							label: value.label,
							option: value.value,
							textColor: value.textColor,
							fontSize: 'xs',
						}))}
						nilOption={{
							color: 'fill/transparent-secondary/default',
							label: 'Off',
							option: 'disabled',
							textColor: 'text/primary/default',
							fontSize: 'xs',
						}}
					/>
				),
			},
			...(filter(genericColumns, (column) =>
				['description'].includes(column.accessor)
			) as ExtendedDataTableColumn<Automation>[]),
			{
				accessor: 'next_run_at',
				title: 'Next run',
				render: (record: Automation) => (
					<DateTimeRender record={record} field="next_run_at" />
				),
			},

			{
				accessor: 'last_run_at',
				title: 'Last run',
				render: (record: Automation) => (
					<DateTimeRender record={record} field="last_run_at" />
				),
			},
		],
		[classes.container, genericColumns, handleIconChange, theme.spacing]
	);

	return columns;
}

export const useActions = (): ICommandListItem<Automation>[] => {
	const { mutateAsync: runAutomation } = useAutomationRun();
	const { mutateAsync: deleteAutomation } = useDeleteAutomation({});
	const onDelete = useCallback(
		(id: string) => deleteAutomation({ id }),
		[deleteAutomation]
	);
	const { action: deleteManyAction } = useDeleteManyAction<Automation>({
		name: 'automation',
		queryKeys: automationQueryKeyFactory.allLists(),
		onDelete,
	});

	const handleRunAutomations = useCallback(
		async (entities: Automation[]) => {
			let automationIds =
				entities
					?.filter(({ is_enabled: isEnabled }) => isEnabled)
					.map(({ id }) => id) ?? [];

			showNotification({
				title: 'Automations running',
				message: 'All the selected automations have been triggered',
				color: 'green',
				icon: <Icon name="check" />,
			});

			// Create a promise array of all the automations that are being run.
			await Promise.all(
				automationIds.map((automationId) =>
					runAutomation({
						data: {
							automationId,
						},
					})
				)
			);
		},
		[runAutomation]
	);

	const actions = useMemo(
		() => [
			{
				id: 'actions::run',
				title: 'Run automation',
				name: 'Run',
				iconName: 'refresh' as IconNames,
				hotkey: '/mp',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: true,
				onClick: handleRunAutomations,
			},
			deleteManyAction,
		],
		[deleteManyAction, handleRunAutomations]
	);

	return actions;
};
