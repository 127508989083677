import type { IComment } from '../../../api';
import { useAuthUser, useUpdateComment } from '../../../api';
import { updateReactionArray } from '../../InlineEmojiPicker/utils';

export function useCommentReaction({ comment }: { comment: IComment }) {
	const { user: currentUser } = useAuthUser();

	const { mutateAsync: updateComment } = useUpdateComment({});

	const handleSelectEmoji = (emoji: { native: string }) => {
		const newReactions = updateReactionArray(
			comment.reactions,
			emoji.native,
			currentUser?.id
		);
		updateComment({ data: { id: comment.id, reactions: newReactions } });
	};

	return {
		handleSelectEmoji,
	};
}
