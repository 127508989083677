import { Box, Menu, UnstyledButton } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';

import { FilterDropdownType } from '@repo/common/components/Filter/types';
import { Icon, Text } from '@repo/foundations';
import type {
	DataTableColumn,
	DataTableSortStatus,
} from '@repo/mantine-datatable';
import { capitalize, isNil } from 'lodash-es';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useRef, useState } from 'react';
import type { FilterOptionType } from '../Filter';
import { SearchFilterV2StoreContext } from '../Filter';
import { POCFilter } from './Filter';
import TableV2HeaderResizeHandle from './TableV2HeaderResizeHandle/TableV2HeaderResizeHandle';

export const TableV2Header = observer(
	({
		column,
		onSort,
		withFilters,
		onColumnVisibilityChange,
		withColumnVisibility = false,
		onResizeColumn,
	}: {
		column: DataTableColumn<never> & {
			esAccessor?: string;
			filterOptionType?: FilterOptionType;
		};
		onSort?: (args: DataTableSortStatus) => void;
		withFilters?: boolean;
		withColumnVisibility?: boolean;
		onColumnVisibilityChange: (columnName: string, visible: boolean) => void;
		onResizeColumn?: (columnName: string, newWidth: number) => void;
	}) => {
		const searchFilterV2Store = useContext(SearchFilterV2StoreContext);

		const [dropdown, { close: dropdownClose, toggle: dropdownToggle }] =
			useDisclosure(false);

		const handleSortAsc = useCallback(() => {
			onSort?.({
				columnAccessor: column.esAccessor || column.accessor,
				direction: 'asc',
			});
			dropdownClose();
		}, [column.accessor, column.esAccessor, dropdownClose, onSort]);

		const handleSortDesc = useCallback(() => {
			onSort?.({
				columnAccessor: column.esAccessor || column.accessor,
				direction: 'desc',
			});
			dropdownClose();
		}, [column.accessor, column.esAccessor, dropdownClose, onSort]);

		const [
			filterDropdown,
			{ open: filterDropdownOpen, close: filterDropdownClose },
		] = useDisclosure(false);

		const handleDropdownToggle = useCallback(() => {
			if (!dropdown) {
				filterDropdownClose();
				dropdownToggle();
			}
		}, [dropdown, dropdownToggle, filterDropdownClose]);

		const handleFilterDropdownClose = useCallback(() => {
			filterDropdownClose();
			dropdownClose();
		}, [dropdownClose, filterDropdownClose]);

		const filterOption = toJS(searchFilterV2Store.filterOptions).find(
			(option) =>
				option !== 'divider' && option.type === column.filterOptionType
		);

		const filterable = !isNil(filterOption) && filterOption !== 'divider';

		// Refs for click outside.
		const [dropdownRef, setDropdownRef] = useState<HTMLDivElement | null>(null);
		const [controlRef, setControlRef] = useState<HTMLButtonElement | null>(
			null
		);
		useClickOutside(() => dropdownClose(), null, [controlRef, dropdownRef]);

		const ref = useRef<HTMLDivElement>(null);

		const activeSortAsc =
			searchFilterV2Store.tableSort?.columnAccessor === column.accessor &&
			searchFilterV2Store.tableSort?.direction === 'asc';

		const activeSortDesc =
			searchFilterV2Store.tableSort?.columnAccessor === column.accessor &&
			searchFilterV2Store.tableSort?.direction === 'desc';

		const ascSortText =
			filterable &&
			filterOption.filterDropdownConfig.dropdownType === FilterDropdownType.Date
				? 'Oldest first'
				: 'Sort ascending';

		const descSortText =
			filterable &&
			filterOption.filterDropdownConfig.dropdownType === FilterDropdownType.Date
				? 'Newest first'
				: 'Sort descending';

		const handleResize = useCallback(
			(updatedWidth: number) => {
				onResizeColumn?.(column.accessor, updatedWidth);
			},
			[column.accessor, onResizeColumn]
		);

		return (
			<Box ref={ref} data-testid={`table-header-${column.accessor}`}>
				<Menu
					disabled={!onSort && !withFilters}
					withinPortal
					opened={dropdown && !filterDropdown}
					position="bottom-start"
				>
					<Menu.Target>
						<UnstyledButton
							onClick={handleDropdownToggle}
							ref={setControlRef}
							w="100%"
						>
							<Text size="sm" fw={500} color="text/secondary/default">
								{column.title ?? capitalize(column.accessor.replace(/_/g, ' '))}
							</Text>
						</UnstyledButton>
					</Menu.Target>

					<Menu.Dropdown>
						<Box ref={setDropdownRef}>
							<Menu.Item
								onClick={handleSortAsc}
								icon={<Icon name={activeSortAsc ? 'x' : 'sortDescending'} />}
							>
								{activeSortAsc ? 'Remove sort' : ascSortText}
							</Menu.Item>

							<Menu.Item
								onClick={handleSortDesc}
								icon={<Icon name={activeSortDesc ? 'x' : 'sortAscending'} />}
							>
								{activeSortDesc ? 'Remove sort' : descSortText}
							</Menu.Item>
							{withFilters && filterable && (
								<Menu.Item
									onClick={filterDropdownOpen}
									icon={<Icon name="filter" />}
								>
									Add filter
								</Menu.Item>
							)}
						</Box>
					</Menu.Dropdown>
				</Menu>
				{withFilters && filterDropdown && filterable && (
					<POCFilter
						controlRef={controlRef}
						dropdownStandaloneOption={filterOption}
						onClose={handleFilterDropdownClose}
					/>
				)}
				{onResizeColumn && (
					<TableV2HeaderResizeHandle
						headerRef={ref.current}
						minWidth={35}
						maxWidth={1200}
						onResize={handleResize}
					/>
				)}
			</Box>
		);
	}
);
