import {
	ActionIcon,
	Anchor,
	Box,
	createStyles,
	Group,
	Menu,
	Skeleton,
	Stack,
} from '@mantine/core';
import { Badge, Button, Icon, IconButton, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { isEmpty } from 'lib0/object';
import { isNil, some, sortBy, uniqBy } from 'lodash-es';
import { useCallback, useState } from 'react';
import type { IEmbeddedPrompt, IQuestion, IQuestionReply } from '../../../api';
import {
	useAIEmbedded,
	useAIFeedback,
	useUser,
	useWorkspace,
} from '../../../api';
import { useQuestionPermissions } from '../../../api/hooks/question/permissions';
import { useToggleQuestionReaction } from '../../../api/hooks/question/reaction';

import { SentimentType } from '../../../components/AIAssistant/AIFeedbackModal/constants.ts';
import { AISteps } from '../../../components/AIAssistant/Messages/AISteps.tsx';
import { isSecodaAIUser } from '../../../components/AIAssistant/utils.ts';
import { InlineEmoji } from '../../../components/InlineEmojiPicker/InlineEmoji';
import { InlineEmojiPicker } from '../../../components/InlineEmojiPicker/InlineEmojiPicker';
import { RichEditor } from '../../../components/RichEditor';
import { UserAvatar } from '../../../components/UserAvatar';
import { trimMarkdown } from './helpers';
import { useStyles } from './styles';

const useLocalStyles = createStyles((theme) => ({
	rightSection: {
		flex: '1 !important',
	},
	userSection: {
		minHeight: 32,
		gap: 4,
		justifyContent: 'space-between',
	},
	emojiSection: {
		gap: 4,
	},
	inlineEmojiSection: {
		display: 'flex',
		gap: theme.spacing.xs,
	},
	actionGroup: {
		display: 'flex',
		width: '100%',
		justifyContent: 'flex-end',
		marginBottom: theme.spacing.xs,
	},
}));

export type IReplyWrapperProps = {
	handleUpdate: (values: Record<string, unknown>) => void;
	handleDelete: VoidFunction;
	accepted?: boolean;
} & (
	| {
			type: 'question';
			entity: IQuestion;
	  }
	| {
			type: 'reply';
			entity: IQuestionReply;
	  }
);

export function ReplyWrapper({
	entity,
	handleUpdate,
	handleDelete,
	accepted = false,
	type,
}: IReplyWrapperProps) {
	const { workspace } = useWorkspace();
	const [definition, setDefinition] = useState(
		trimMarkdown(entity.definition || '').length > 2 ? entity.definition : ''
	);
	const [isEmojiOpen, setIsEmojiOpen] = useState(false);

	const { classes: localClasses } = useLocalStyles();
	const { classes, cx } = useStyles({ accepted, shouldSetFullWidth: true });
	const [editing, setEditing] = useState<boolean>(false);
	const [hovered, setHovered] = useState(false);
	const [showSteps, setShowSteps] = useState(false);

	const { canDelete, canEdit, canReact, canAccept } = useQuestionPermissions(
		entity,
		type
	);

	const { toggleReaction } = useToggleQuestionReaction(entity.id, type);

	const handleSave = () => {
		handleUpdate({ definition });
		setEditing(false);
	};

	const handleCancel = () => {
		setDefinition(entity.definition);
		setEditing(false);
	};

	const toggleAccept = () => {
		const newAccepted = String(!accepted);
		handleUpdate({
			accepted_answer: newAccepted,
		});
	};

	const handleSelectEmoji = (emoji: { native: string }) => {
		toggleReaction(emoji.native);
		setIsEmojiOpen(false);
		setHovered(false);
	};

	const { data: user, isLoading } = useUser({
		id: entity.owners?.[0],
		options: {
			enabled: !isNil(entity.owners?.[0]),
		},
	});

	const handleMouseEnter = useCallback(() => {
		setHovered(true);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setHovered(false);
	}, []);

	// AI syncing
	const embeddedPromptId =
		type === 'reply' ? (entity.embedded_prompt ?? '') : '';
	const { data: embeddedPrompt } = useAIEmbedded({
		id: embeddedPromptId,
		options: {
			enabled: !!embeddedPromptId,
		},
	});
	const { showPositiveFeedbackModal, showNegativeFeedbackModal } =
		useAIFeedback({
			id: embeddedPromptId,
		});

	// use AI user for backwards compatibility with old question replies that don't have an embedded prompt id
	const isAIAnswer = user && isSecodaAIUser(user, workspace.id);
	const hasAISteps =
		isAIAnswer && 'ai_steps' in entity ? !!entity?.ai_steps?.length : false;
	const stepsInput =
		'ai_steps' in entity
			? ({
					response: {
						steps: entity?.ai_steps,
					},
				} as unknown as IEmbeddedPrompt)
			: undefined;

	// Jira syncing
	const fromJira =
		type === 'reply' &&
		'jira_comment' in entity &&
		entity.jira_comment?.is_from_jira;

	if (isLoading && !isNil(entity.owners?.[0])) {
		return <Skeleton />;
	}

	const showName = !isEmpty(user?.first_name) || !isEmpty(user?.last_name);

	return (
		<Group
			className={classes.wrapper}
			data-testid={`${type}-wrapper-${entity.id}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			spacing="md"
		>
			<Box miw={32}>{user && <UserAvatar user={user} size="md" />}</Box>
			<Stack className={localClasses.rightSection} spacing="3xs">
				<Group className={localClasses.userSection}>
					<Group spacing="xs">
						<Text size="sm" color="text/primary/default" weight="bold">
							{showName
								? `${user?.first_name} ${user?.last_name}`
								: user?.email}
						</Text>
						<Text size="xs" color="text/secondary/default">
							{dayjs(entity.created_at).fromNow(false)}
						</Text>
						{fromJira && (
							<Anchor
								size="xs"
								href={entity.jira_comment?.comment_url}
								target="_blank"
								rel="noopener noreferrer"
							>
								<Text size="xs" color="text/secondary/default">
									via Jira
								</Text>
							</Anchor>
						)}
					</Group>
					<Group>
						{(hovered || isEmojiOpen) && (
							<Group className={localClasses.emojiSection}>
								{hasAISteps && (
									<IconButton
										iconName="listSearch"
										variant="tertiary"
										onClick={() => setShowSteps((prev) => !prev)}
										tooltip="Show AI steps"
									/>
								)}
								{isAIAnswer &&
									(embeddedPrompt?.user_feedback_raw?.sentiment ===
									SentimentType.Positive ? (
										<IconButton
											iconName="thumbUpFilled"
											variant="tertiary"
											onClick={showNegativeFeedbackModal}
											tooltip="Positive feedback for this prompt already submitted"
										/>
									) : (
										<IconButton
											iconName="thumbUp"
											variant="tertiary"
											onClick={showPositiveFeedbackModal}
											tooltip="Good response"
										/>
									))}
								{isAIAnswer &&
									(embeddedPrompt?.user_feedback_raw?.sentiment ===
									SentimentType.Negative ? (
										<IconButton
											iconName="thumbDownFilled"
											variant="tertiary"
											onClick={showNegativeFeedbackModal}
											tooltip="Negative feedback for this prompt already submitted"
										/>
									) : (
										<IconButton
											iconName="thumbDown"
											variant="tertiary"
											onClick={showNegativeFeedbackModal}
											tooltip="Bad response"
										/>
									))}
								{canReact && (
									<InlineEmojiPicker
										onClick={handleSelectEmoji}
										onOpenChange={setIsEmojiOpen}
									/>
								)}
								{canAccept && type === 'reply' && (
									<IconButton
										iconName={accepted ? 'x' : 'check'}
										variant="tertiary"
										onClick={toggleAccept}
										data-testid={`reply-${accepted ? 'reopen' : 'accept'}-${entity.id}`}
										tooltip={accepted ? 'Reopen question' : 'Mark as answer'}
									/>
								)}
								{(canEdit || canDelete) && (
									<ActionIcon size="xs">
										<Menu shadow="md" width={200}>
											<Menu.Target>
												<Icon name="dots" color="icon/primary/default" />
											</Menu.Target>
											<Menu.Dropdown>
												{canEdit && (
													<Menu.Item
														icon={<Icon name="pencil" />}
														onClick={() => setEditing((prev) => !prev)}
													>
														Edit
													</Menu.Item>
												)}
												{canDelete && (
													<Menu.Item
														icon={<Icon name="trash" />}
														onClick={handleDelete}
													>
														Delete
													</Menu.Item>
												)}
											</Menu.Dropdown>
										</Menu>
									</ActionIcon>
								)}
							</Group>
						)}
						{accepted && (
							<Badge
								leftSection={<Icon name="check" color="icon/success/default" />}
								variant="success"
							>
								Answer
							</Badge>
						)}
					</Group>
				</Group>
				<Group className={editing ? classes.editorWrapper : undefined}>
					<Stack
						data-test-id="wrapper-max-width"
						className={cx(
							editing ? classes.editorWhileEditing : undefined,
							classes.editorMaxWidth
						)}
					>
						<RichEditor
							dataTestId={`${type}-editor-${entity.id}`}
							placeholder="Edit the content..."
							{...(editing
								? { initialValue: definition, onChangeCallback: setDefinition }
								: { value: definition })}
							readOnly={!editing}
							disableTopGap
							enableScrollToHash
						/>
						{editing && (
							<Group className={localClasses.actionGroup} spacing="xs">
								<Button size="sm" variant="default" onClick={handleCancel}>
									Cancel
								</Button>
								<Button onClick={handleSave} size="sm" variant="primary">
									Save
								</Button>
							</Group>
						)}
					</Stack>
				</Group>
				{!editing && (
					<Box className={localClasses.inlineEmojiSection}>
						{sortBy(uniqBy(entity.reactions ?? [], 'emoji'), 'emoji')
							.filter((f) => f.users.length > 0)
							.map(({ emoji, users }) => (
								<InlineEmoji
									key={emoji}
									emoji={emoji}
									users={users}
									onToggle={handleSelectEmoji}
								/>
							))}
						{canReact &&
							some(entity.reactions ?? [], (r) => r.users.length > 0) && (
								<InlineEmojiPicker onClick={handleSelectEmoji} />
							)}
					</Box>
				)}
				{hasAISteps && showSteps && !!stepsInput && (
					<AISteps prompt={stepsInput} />
				)}
			</Stack>
		</Group>
	);
}
