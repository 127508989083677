import { Button } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';

function AddPolicyButton() {
	const navigate = useNavigate();
	const location = useLocation();
	const { policiesAccess } = useFeatureAccess();

	const handleOpen = () => {
		navigate('/policies/new', {
			// Store the current url path so we can return back to it
			state: { prev_url_path: location.pathname },
		});
	};

	if (!policiesAccess) {
		return null;
	}

	return (
		<Button onClick={handleOpen} variant="primary">
			Create policy
		</Button>
	);
}

export default observer(AddPolicyButton);
