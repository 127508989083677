import { Stack } from '@mantine/core';
import { MultiSelect, Switch, Text, TextArea, Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useExtendedUserList } from '../../../api/hooks/user/useExtendedUserList';
import { UserAvatar } from '../../../components/UserAvatar';
import { getDisplayName } from '../../../utils/userUtils';
import { useCreatePolicyStore } from './context';

export function RemediationStack() {
	const store = useCreatePolicyStore();
	const { activeUsers } = useExtendedUserList();

	const ownersData = useMemo(
		() =>
			activeUsers?.map((u) => ({
				value: u.id,
				label: getDisplayName(u),
				user: u,
			})) || [],
		[activeUsers]
	);

	return (
		<Stack p={0} spacing="md">
			<Title size="md">Remediation</Title>
			<Text size="sm">
				Describe the steps or actions required to resolve a policy violation and
				ensure compliance is restored.
			</Text>
			<TextArea
				label="Remediation guidelines"
				value={store.remediationGuidelines}
				onChange={(e) => store.setRemediationGuidelines(e.target.value)}
				minRows={4}
			/>
			<MultiSelect
				label="Owner"
				placeholder="Select owners"
				data={ownersData}
				value={store.owners}
				setValue={store.setOwners}
				renderIcon={(item) => <UserAvatar user={item.user} size="xs" />}
				error={store.ownersError}
				dropdownPosition="flip"
			/>
			<MultiSelect
				label="Subscribers"
				placeholder="Select subscribers"
				data={ownersData}
				value={store.subscribers}
				setValue={store.setSubscribers}
				renderIcon={(item) => <UserAvatar user={item.user} size="xs" />}
				dropdownPosition="flip"
			/>
			<Switch
				label="Enable automation"
				checked={store.automationEnabled}
				onChange={(e) => store.setAutomationEnabled(e.currentTarget.checked)}
			/>
		</Stack>
	);
}

export default observer(RemediationStack);
