import { makeAutoObservable } from 'mobx';
import { createContext, useContext, useMemo } from 'react';

class CreatePolicyStore {
	// Properties
	name: string = '';
	description: string = '';
	framework: string = '';
	severity: string = '';

	nameError: string = '';
	descriptionError: string = '';

	// Remediation
	remediationGuidelines: string = '';
	owners: string[] = [];
	subscribers: string[] = [];
	automationEnabled: boolean = false;

	ownersError: string = '';

	constructor() {
		makeAutoObservable(this);
	}

	// Properties

	setName = (name: string) => {
		this.name = name;
		this.nameError = '';
	};

	setDescription = (description: string) => {
		this.description = description;
		this.descriptionError = '';
	};

	setFramework = (framework: string) => {
		this.framework = framework;
	};

	setSeverity = (severity: string) => {
		this.severity = severity;
	};

	// Remediation

	setRemediationGuidelines = (guidelines: string) => {
		this.remediationGuidelines = guidelines;
	};

	setOwners = (owners: string[]) => {
		this.owners = owners;
		this.ownersError = '';
	};

	setSubscribers = (subscribers: string[]) => {
		this.subscribers = subscribers;
	};

	setAutomationEnabled = (enabled: boolean) => {
		this.automationEnabled = enabled;
	};

	setValue = (field: 'owners' | 'subscribers') => (value: string[]) => {
		if (field === 'owners') {
			this.setOwners(value);
		} else {
			this.setSubscribers(value);
		}
	};

	get isFormValid() {
		this.nameError = '';
		this.descriptionError = '';
		this.ownersError = '';

		if (!this.name) {
			this.nameError = 'Policy name is required';
		}

		if (!this.description) {
			this.descriptionError = 'Policy description is required';
		}
		if (this.owners.length === 0) {
			this.ownersError = 'At least one owner is required';
		}

		return !(this.nameError || this.descriptionError || this.ownersError);
	}
}

const CreatePolicyStoreContext = createContext<CreatePolicyStore | null>(null);

export function CreatePolicyStoreProvider({
	children,
}: {
	children: React.ReactNode;
}) {
	const store = useMemo(() => new CreatePolicyStore(), []);
	return (
		<CreatePolicyStoreContext.Provider value={store}>
			{children}
		</CreatePolicyStoreContext.Provider>
	);
}

export function useCreatePolicyStore() {
	const context = useContext(CreatePolicyStoreContext);
	if (!context) {
		throw new Error(
			'useCreatePolicyStore must be used within CreatePolicyStoreProvider'
		);
	}
	return context;
}
