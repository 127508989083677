import { Image, useMantineTheme } from '@mantine/core';
import { useState } from 'react';
import { UploadWrapper } from './UploadWrapper';

interface ImageUploadProps {
	callback: (file: File) => void;
	label: string;
	placeholder: string | React.ReactNode;
}

export function ImageUpload({
	label,
	placeholder,
	callback,
}: ImageUploadProps) {
	const [url, setUrl] = useState<string | null>(null);
	const theme = useMantineTheme();

	const handleClickUpload = async (file: File) => {
		setUrl(URL.createObjectURL(file));
		callback(file);
	};

	return (
		<UploadWrapper label={label} onClick={handleClickUpload}>
			{url || typeof placeholder === 'string' ? (
				<Image
					src={url || (placeholder as string)}
					width={theme.other.space[10]}
					height={theme.other.space[10]}
					radius={theme.other.space[1]}
				/>
			) : (
				placeholder
			)}
		</UploadWrapper>
	);
}
