import { Group, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import CollapsableStack from '@repo/common/components/CollapsableStack';
import { EntityType } from '@repo/common/enums/entityType';
import { Icon, SplitButton, Text } from '@repo/foundations';
import type { IQuestion } from '../../../../api';
import IntegrationLogo from '../../../../components/IntegrationLogo';
import { JiraIssueCreateModal } from '../../../../pages/IncidentPage/v2/JiraIssueWorkflow/JiraIssueCreateModal';
import { JiraIssueLinkModal } from '../../../../pages/IncidentPage/v2/JiraIssueWorkflow/JiraIssueLinkModal';
import { LinkedJiraIssue } from '../../../../pages/IncidentPage/v2/JiraIssueWorkflow/LinkedJiraIssue';
import { StaticProperty } from '../../EntityPropertySidebar';

export function JiraIssueStack({
	question,
	isViewer = false,
}: {
	question: IQuestion;
	isViewer?: boolean;
}) {
	const [
		isCreateModalOpened,
		{ open: openCreateModal, close: closeCreateModal },
	] = useDisclosure(false);
	const [isLinkModalOpened, { open: openLinkModal, close: closeLinkModal }] =
		useDisclosure(false);

	if (isViewer && !question.jira_issue?.issue_key) {
		return (
			<CollapsableStack groupName="Jira">
				<Text size="sm" color="text/secondary/default">
					No issue created.
				</Text>
			</CollapsableStack>
		);
	}

	const customContent = question.jira_issue?.issue_key ? (
		<LinkedJiraIssue
			issueName={question.title || ''}
			issueUrl={question.jira_issue.issue_url || ''}
			entityId={question.id}
			isViewer={isViewer}
		/>
	) : (
		<>
			<SplitButton
				label={
					<Group spacing="xs" noWrap>
						<IntegrationLogo
							integrationType="jira"
							size={16}
							entityType={EntityType.integration}
						/>
						<Text size="xs">Create issue</Text>
					</Group>
				}
				onClick={openCreateModal}
			>
				<Menu>
					<Menu.Target>
						<Icon name="chevronDown" />
					</Menu.Target>
					<Menu.Dropdown>
						<Menu.Item icon={<Icon name="link" />} onClick={openLinkModal}>
							Link issue
						</Menu.Item>
					</Menu.Dropdown>
				</Menu>
			</SplitButton>
			<JiraIssueCreateModal
				opened={isCreateModalOpened}
				handleClose={closeCreateModal}
				entityId={question.id}
				title={question.title}
				definition={question.definition}
			/>
			<JiraIssueLinkModal
				opened={isLinkModalOpened}
				handleClose={closeLinkModal}
				entityId={question.id}
			/>
		</>
	);

	return (
		<CollapsableStack groupName="Jira">
			<StaticProperty type="custom" label="Jira issue" custom={customContent} />
		</CollapsableStack>
	);
}
