import { baseQueryHooksFactory, createQueryKeys } from '../../factories';
import type { IApiKey } from '../../types/models/apiKey';

export const API_KEY_NAMESPACE = ['auth', 'api_keys'];

export const apiKeyQueryKeyFactory = createQueryKeys(API_KEY_NAMESPACE);

const {
	useApiKey,
	useApiKeyInfiniteList,
	useApiKeyList,
	useCreateApiKey,
	useDeleteApiKey,
	useUpdateApiKey,
	fetchApiKeyList,
	updateApiKey,
	createApiKey,
} = baseQueryHooksFactory<IApiKey, 'apiKey'>('apiKey', apiKeyQueryKeyFactory);

export {
	createApiKey,
	fetchApiKeyList,
	updateApiKey,
	useApiKey,
	useApiKeyInfiniteList,
	useApiKeyList,
	useCreateApiKey,
	useDeleteApiKey,
	useUpdateApiKey,
};
