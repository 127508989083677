import { Flex } from '@mantine/core';
import type { MantineNumberSize } from '@mantine/styles';
import { OvalLoader } from '@repo/common/components/Loader';

export interface FullWidthLoadingSpinnerProps {
	size?: MantineNumberSize;
	// Remaning props
	[k: string]: unknown;
}

export function FullWidthLoadingSpinner({
	size,
	...otherProps
}: FullWidthLoadingSpinnerProps) {
	return (
		<Flex
			mt={8}
			h="100%"
			w="100%"
			align="center"
			justify="center"
			{...otherProps}
		>
			<OvalLoader
				size={
					['lg', 'md', 'sm', 'xs'].includes(size as unknown as string)
						? (size as unknown as 'lg' | 'md' | 'sm' | 'xs')
						: 'lg'
				}
			/>
		</Flex>
	);
}
