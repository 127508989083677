import type { Filter } from '@repo/api-codegen';
import {
	useApiGetBottomUpCatalogTreeIntegrations,
	useApiGetCatalogTreeIntegrations,
} from '@repo/api-codegen';
import type { FilterValue } from '@repo/common/components/Filter';
import {
	FilterOperator,
	FilterOptionType,
} from '@repo/common/components/Filter';
import { EntityType } from '@repo/common/enums/entityType';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import type React from 'react';
import { useCallback, useMemo } from 'react';
import { useEntityBreadcrumbTeam } from '../../../hooks/useEntityBreadcrumbs';
import { buildResourceUrl } from '../../../utils/navigationUtils';
import IntegrationLogo from '../../IntegrationLogo';
import { BottomUpTreeIntegrationNode } from '../components/BottomUpTreeIntegrationNode';
import { DataTreeLoader } from '../components/DataTreeLoader';
import { EntityTreeNode } from '../components/EntityTreeNode';
import type { CommonEntityTreeNode } from '../utils';
import { getEntityIconByType } from '../utils';
import { isIntegrationRootNode } from './utils';

interface CatalogTreeProps {
	teamId: string;
}

function catalogFilterQueryParamsFromCatalogTreeNode(
	node: CommonEntityTreeNode
): FilterValue[] {
	if (node.entity.entity_type === EntityType.integration) {
		return [
			{
				value: [node.entity.id],
				operator: FilterOperator.Is,
				filterType: FilterOptionType.INTEGRATION,
			},
		];
	}

	return [
		{
			value: [node.entity.id],
			operator: FilterOperator.Is,
			filterType: FilterOptionType.PARENT_ID,
		},
	];
}

function catalogFilterFromTreeNode(node: CommonEntityTreeNode): Filter[] {
	if (node.entity.entity_type === EntityType.integration) {
		return [
			{
				operator: 'not',
				operands: [
					{
						value: '',
						operator: 'is_set',
						field: 'parent_id',
						operands: [],
					},
				],
			},
			{
				value: node.entity.id,
				operator: 'exact',
				field: 'integration_id',
				operands: [],
			},
		];
	}

	return [
		{
			value: node.entity.id,
			operator: 'exact',
			field: 'parent_id',
			operands: [],
		},
	];
}

const getIcon = (node: CommonEntityTreeNode) => {
	if (node.entity.entity_type === 'integration') {
		return <IntegrationLogo integrationId={node.entity.id} size={16} />;
	}

	return getEntityIconByType(node.entity.entity_type);
};

const getLabel = (node: CommonEntityTreeNode) => {
	if (node.entity.entity_type === 'integration') {
		return node.entity.title_cased ?? node.entity.title ?? 'Untitled';
	}

	return node.entity.title_cased ?? node.entity.title ?? 'Untitled';
};

export function CatalogTreeV2({ teamId }: CatalogTreeProps) {
	// Queries
	const { data: integrationsData, isLoading: isLoadingIntegrations } =
		useApiGetCatalogTreeIntegrations({
			queryParams: {
				team_id: teamId,
			},
		});

	const {
		data: bottomUpIntegrations,
		isLoading: isLoadingBottomUpIntegrations,
	} = useApiGetBottomUpCatalogTreeIntegrations({
		queryParams: {
			team_id: teamId,
		},
	});

	// Hooks
	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();
	const navigateHandler = useNavigateHandler();

	// Callbacks
	const handleNavigateCatalogNodeNew = useCallback(
		(event: React.MouseEvent, node: CommonEntityTreeNode) => {
			const catalogFilterQueryParams =
				catalogFilterQueryParamsFromCatalogTreeNode(node);

			const catalogPageURL = `/teams/${teamId}/catalog?filters=${encodeURIComponent(
				JSON.stringify(catalogFilterQueryParams)
			)}`;

			if (node.entity.entity_type !== EntityType.integration) {
				const resourceURL = buildResourceUrl(node.entity);
				if (resourceURL !== '#') {
					navigateHandler(event)(resourceURL);
					return;
				}
			}

			navigateHandler(event)(catalogPageURL);
		},
		[teamId, navigateHandler]
	);

	const handleNavigate = useCallback(
		(event: React.MouseEvent, node: CommonEntityTreeNode) => {
			setTeamBreadcrumb(teamId);
			handleNavigateCatalogNodeNew(event, node);
		},
		[setTeamBreadcrumb, teamId, handleNavigateCatalogNodeNew]
	);

	// Data
	const nodes: React.ReactNode[] = useMemo(() => {
		// Builds the top down tree nodes
		const topDownNodes: { sort: string; node: React.ReactNode }[] =
			integrationsData
				?.filter((integration) => isIntegrationRootNode(integration))
				.map((integration) => ({
					sort: integration.name,
					node: (
						<EntityTreeNode
							key={integration.id}
							node={{
								id: integration.id,
								hasChildren: true,
								entity: {
									id: integration.id,
									entity_type: EntityType.integration,
									icon: '',
									title: integration.name,
								},
							}}
							getIcon={getIcon}
							level={0}
							onClick={handleNavigate}
							getLabel={getLabel}
							childFilterOperands={catalogFilterFromTreeNode}
						/>
					),
				})) || [];

		// Builds the bottom up tree nodes
		const bottomUpNodes: { sort: string; node: React.ReactNode }[] = (
			bottomUpIntegrations ?? []
		).map((tree) => ({
			sort: tree.name,
			node: (
				<BottomUpTreeIntegrationNode
					key={tree.integration_id}
					name={tree.name}
					teamId={teamId}
					id={tree.integration_id}
					onClick={handleNavigate}
				/>
			),
		}));

		// Combines the top down and bottom up tree nodes and sorts them by title
		return [...topDownNodes, ...bottomUpNodes]
			.sort((a, b) => a.sort.localeCompare(b.sort))
			.map((node) => node.node);
	}, [integrationsData, bottomUpIntegrations, handleNavigate, teamId]);

	if (isLoadingIntegrations || isLoadingBottomUpIntegrations) {
		return <DataTreeLoader />;
	}

	return <>{nodes}</>;
}
