import { Stack } from '@mantine/core';
import { useApiListAuditLogs, type ActivityLogOut } from '@repo/api-codegen';
import { noop } from 'lodash-es';
import { TableV2 } from '../TableV2';
import { useColumns } from './hooks';

function AuditLog() {
	const columns = useColumns();
	return (
		<Stack spacing="md" py="md">
			<TableV2<ActivityLogOut>
				columns={columns}
				withSearch={false}
				withFilters={true}
				onRowClick={noop}
				pluralTypeString="logs"
				withStickyColumnBorder={false}
				defaultRequiredSearchParams={{
					body: {
						page: 1,
					},
				}}
				// @ts-ignore
				usePaginationList={useApiListAuditLogs}
				useCodegenListInterface={true}
				withInteractiveHeader={false}
			/>
		</Stack>
	);
}

export default AuditLog;
