import { ActionIcon, Group, Menu } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import type { IAMRoleOut } from '@repo/api-codegen';
import {
	apiQueryKey,
	useApiCloneIamRole,
	useApiDeleteIamRole,
} from '@repo/api-codegen';
import { UserRole } from '@repo/common/enums/UserRole';
import { Icon, Text } from '@repo/foundations';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../../../../api';
import { useCheckIAMPermission } from '../../../../utils/authorization/roles';
import { ROW_HEIGHT } from '../../../TableV2/TableV2.styles';

interface IamRoleActionCellProps {
	iamRole: IAMRoleOut;
}

export function IamRoleActionCell({ iamRole }: IamRoleActionCellProps) {
	const navigate = useNavigate();
	const canEditRole = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN],
		v2Permission: 'Roles.Update',
		defaultResult: false,
	});
	const canDeleteRole = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN],
		v2Permission: 'Roles.Delete',
		defaultResult: false,
	});
	const canCreateRole = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN],
		v2Permission: 'Roles.Create',
		defaultResult: false,
	});

	const { mutateAsync: deleteIamRole } = useApiDeleteIamRole({
		onSuccess: () => {
			notifications.show({
				title: 'Role deleted',
				message: 'Successfully deleted role',
				color: 'green',
			});
			queryClient.invalidateQueries(apiQueryKey('iam/iam_roles/iam_role'));
			queryClient.invalidateQueries(apiQueryKey('iam/iam_roles/users'));
		},
	});

	const { mutateAsync: cloneIamRole } = useApiCloneIamRole({
		onSuccess: () => {
			queryClient.invalidateQueries(apiQueryKey('iam/iam_roles/iam_role'));
		},
	});

	const handleDelete = async (e: React.MouseEvent) => {
		e.stopPropagation();
		await deleteIamRole({
			pathParams: { iamRoleId: iamRole.id },
		});
	};

	const handleEdit = (e: React.MouseEvent) => {
		e.stopPropagation();
		navigate(`/roles/edit/${iamRole.id}`);
	};

	const handleClone = async (e: React.MouseEvent) => {
		e.stopPropagation();
		await cloneIamRole({
			pathParams: { iamRoleId: iamRole.id },
		});
	};

	return (
		<Menu position="bottom-end" withinPortal>
			<Menu.Target>
				<Group
					mah={`${ROW_HEIGHT}px`}
					onClick={(e) => e.stopPropagation()}
					align="revert"
				>
					<ActionIcon variant="subtle" color="gray">
						<Icon name="dots" size="md" />
					</ActionIcon>
				</Group>
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Item
					icon={
						<Icon
							name={iamRole.is_legacy || !canEditRole ? 'eye' : 'edit'}
							size="md"
						/>
					}
					onClick={handleEdit}
				>
					<Text size="sm">
						{iamRole.is_legacy || !canEditRole ? 'View' : 'Edit role'}
					</Text>
				</Menu.Item>
				{canCreateRole && (
					<Menu.Item
						icon={<Icon name="copy" size="md" />}
						onClick={handleClone}
					>
						<Text size="sm">Duplicate</Text>
					</Menu.Item>
				)}
				{!iamRole.is_legacy && canDeleteRole && (
					<Menu.Item
						icon={<Icon name="trash" size="md" />}
						onClick={handleDelete}
					>
						<Text size="sm">Delete</Text>
					</Menu.Item>
				)}
			</Menu.Dropdown>
		</Menu>
	);
}
