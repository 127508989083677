import type { TimelineItemProps } from '@mantine/core';
import { Group, Timeline } from '@mantine/core';
import { Text } from '@repo/foundations';
import { formatDateTime } from '../../utils/time';
import type { AuditLogItemType } from './types';

export interface AuditLogItemProps extends TimelineItemProps {
	item: AuditLogItemType;
}

export function AuditLogItem({ item, ...props }: AuditLogItemProps) {
	return (
		<Timeline.Item
			key={item.id}
			{...props}
			bullet={item.bullet}
			title={
				<Group spacing="sm" noWrap align="flex-start">
					<Group spacing="3xs" style={{ flex: 1 }}>
						<Text size="sm">{item.title}</Text>
						<Text size="sm" color="text/secondary/default">
							·
						</Text>
						<Text size="sm" color="text/secondary/default">
							{formatDateTime(item.date)}
						</Text>
					</Group>
					{item.titleRightSection}
				</Group>
			}
		>
			{item.content}
		</Timeline.Item>
	);
}
