import {
	fetchApiExportCatalogCsv,
	type Filter as CatalogFilter,
} from '@repo/api-codegen';
import { isNil, omitBy } from 'lodash-es';
import type { ApiCatalogSort } from '../../../api';

interface ServerCsvExportParams {
	currentTeamId: string;
	defaultRequiredOptions: Record<string, unknown>;
	withSearch: boolean;
	defaultRequiredSearchParams: Record<string, unknown>;
	catalogFilter?: {
		operator?: string;
		operands: CatalogFilter[];
	};
	debouncedSearch?: string;
	sort?: ApiCatalogSort;
	startJob: (jobId: string, filename: string) => Promise<void>;
	nestingField?: string;
	nestedIds?: string[];
}

export async function handleServerCsvExport({
	currentTeamId,
	defaultRequiredOptions,
	withSearch,
	defaultRequiredSearchParams,
	catalogFilter,
	debouncedSearch,
	sort,
	startJob,
	nestingField,
	nestedIds,
}: ServerCsvExportParams) {
	if (!currentTeamId) {
		throw new Error('Team ID is required for export');
	}

	try {
		const exportParams = {
			...defaultRequiredOptions,
			filters: withSearch
				? {
						...defaultRequiredSearchParams,
						filter: catalogFilter,
						search_term: debouncedSearch,
						sort,
					}
				: defaultRequiredSearchParams,
		};

		const cleanedParams = omitBy(exportParams, isNil);

		const stringifiedParams = Object.keys(cleanedParams.filters).reduce<
			Record<string, string>
		>((acc, key) => {
			const value = (cleanedParams.filters as Record<string, unknown>)[key];
			// eslint-disable-next-line no-param-reassign
			acc[key] =
				typeof value === 'object' ? JSON.stringify(value) : String(value);
			return acc;
		}, {});

		const response = await fetchApiExportCatalogCsv({
			queryParams: {
				...stringifiedParams,
				team_id: currentTeamId,
				nesting_field: nestingField,
				nested_ids:
					typeof nestedIds === 'object'
						? JSON.stringify(nestedIds)
						: String(nestedIds),
			},
		});

		if (response.id) {
			await startJob(response.id, 'resources.csv');
		}
	} catch (error) {
		throw error;
	}
}
