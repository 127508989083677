import { Box, createStyles } from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { useMemo } from 'react';
import { useCatalogList } from '../../../api/hooks/resourceCatalog';
import { useCatalogFilter } from '../../../components/Filter/useCatalogFilter';
import { TableV2 } from '../../../components/TableV2';
import { useDataQualityScoreColumns } from '../../../components/TableV2/SecodaEntity.hooks';
import {
	useActions,
	useColumns,
} from '../../TeamCatalogPage/TeamCatalogPage.hooks';

interface ISchemasTabProps {
	databaseId: string;
}

const useStyles = createStyles({
	schemaWrapper: {
		height: '100%',
	},
});

const COLUMN_VISIBILITY = {
	catalogType: EntityType.schema,
	catalogServerType: EntityType.schema,
} as const;

const DEFAULT_SEARCH_PARAMS = {
	entity_type: EntityType.schema,
} as const;

function SchemasTab({ databaseId }: ISchemasTabProps) {
	const { classes } = useStyles();

	const defaultColumns = useColumns();
	const dataQualityColumns = useDataQualityScoreColumns({
		catalogType: EntityType.schema,
	});

	const columns = useMemo(
		() => [...defaultColumns, ...dataQualityColumns],
		[dataQualityColumns, defaultColumns]
	);

	const actions = useActions();

	const defaultCatalogFilters = useMemo(
		() => ({
			operands: [
				{
					operands: [],
					field: 'parent_id' as const,
					operator: 'exact' as const,
					value: databaseId,
				},
			],
		}),
		[databaseId]
	);

	const filterOptions = useCatalogFilter();

	return (
		<Box className={classes.schemaWrapper}>
			<TableV2
				pluralTypeString="schemas"
				withSearch
				withInteractiveHeader
				withServerCsvExport
				withCsvExport
				withCheckbox
				withActions={actions}
				columns={columns}
				usePaginationList={useCatalogList}
				columnVisibility={COLUMN_VISIBILITY}
				defaultRequiredSearchParams={DEFAULT_SEARCH_PARAMS}
				defaultRequiredCatalogFilters={defaultCatalogFilters}
				withFilterOptions={filterOptions}
			/>
		</Box>
	);
}

export default SchemasTab;
