import { useCallback } from 'react';
import { type FilterValue } from './types';

interface UseFilterProps {
	value: FilterValue | null;
	onChange: (value: Partial<FilterValue>) => void;
	close: () => void;
}

export function useFilter({ value, onChange, close }: UseFilterProps) {
	const handleOnChange = useCallback(
		(newValue: Partial<FilterValue>, shouldClose: boolean) => {
			if (!value) {
				return;
			}

			onChange(newValue);

			if (shouldClose) {
				close();
			}
		},
		[close, onChange, value]
	);

	return {
		handleOnChange,
	};
}
