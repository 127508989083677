import { Group } from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { Breadcrumbs, Button, Text } from '@repo/foundations';
import { find, isNil, lowerCase } from 'lodash-es';
import type { MouseEvent } from 'react';
import { memo, useCallback, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import { useAuthUser } from '../../../api';
import { useTemplateList } from '../../../api/hooks/templates';
import { useHomepageTeam } from '../../../pages/HomePage/hooks/useHomepageTeam';
import { entityModalStore } from '../../EntityModal/store';
import { NavBarContext } from '../../PrivateRoute/NavBarContext';
import { TemplatesButtonGroup } from '../../Templates/TemplatesButtonGroup';
import { ENTITY_LABEL_OVERRIDE, TEAM_CATALOG_PAGE_PATHS } from './constants';

const CREATE_AND_OPEN_DIRECTLY_ENTITIES = [
	EntityType.document,
	EntityType.dictionary_term,
	EntityType.glossary,
	EntityType.collection,
	EntityType.metric,
];

function TeamCatalogPageNavBar() {
	const { user, isAdminUser } = useAuthUser();
	const { team, isTeamEditorUser } = useHomepageTeam();
	const page = find(TEAM_CATALOG_PAGE_PATHS, ({ pattern }) =>
		pattern.test(window.location.pathname)
	);

	const location = useLocation();

	const showPageBreadcrumb = !TEAM_CATALOG_PAGE_PATHS[0].pattern.test(
		location.pathname
	);

	const context = useContext(NavBarContext);
	const leftNode = context?.leftNode;
	const rightNode = context?.rightNode;

	const navigate = useNavigate();

	const isUserCreatable =
		isTeamEditorUser || isAdminUser || page?.entityType === EntityType.question;
	const hasEntityType = !isNil(page?.entityType);
	const withTemplate = page?.entityType !== EntityType.metric;
	const mustQuickCreate = CREATE_AND_OPEN_DIRECTLY_ENTITIES.includes(
		page?.entityType!
	);

	const { data: defaultTemplates } = useTemplateList({
		filters: {
			is_default: true,
			entity_type_template: page?.entityType,
		},
		options: {
			enabled: hasEntityType && mustQuickCreate,
		},
	});

	const crumbs = useMemo(() => {
		const teamCrumb = {
			title: team?.name ?? '',
			icon: team?.icon || undefined,
			href: `/teams/${team?.id}`,
		};

		const pageCrumb = {
			title: page?.name ?? '',
			href: `/teams/${team?.id}/${page?.path}`,
		};

		return showPageBreadcrumb ? [teamCrumb, pageCrumb] : [teamCrumb];
	}, [
		page?.name,
		page?.path,
		showPageBreadcrumb,
		team?.icon,
		team?.id,
		team?.name,
	]);

	const handleNewEntity = useCallback(
		(ev: MouseEvent<HTMLButtonElement>) => {
			if (hasEntityType) {
				if (mustQuickCreate) {
					entityModalStore.quickCreateEntity(
						page?.entityType!,
						'Untitled',
						[team?.id ?? ''],
						{
							owners: [user.id],
						},
						navigate,
						defaultTemplates?.results?.[0]
					);
					return;
				}

				entityModalStore.open({
					type: page?.entityType!,
					teamId: team?.id,
					metadata: {
						owners: [user.id],
					},
				});
			}
		},
		[
			mustQuickCreate,
			hasEntityType,
			navigate,
			page?.entityType,
			team?.id,
			user.id,
			defaultTemplates,
		]
	);

	if (isNil(team) || isNil(page)) {
		return null;
	}

	const entityLabel =
		ENTITY_LABEL_OVERRIDE?.[page?.entityType ?? ''] ?? page?.entityType;

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Group spacing={0} noWrap>
					<Breadcrumbs crumbs={crumbs} />
					{!isNil(leftNode) && (
						<Group noWrap spacing={0} m={0} p={0}>
							<Text size={'sm'} color={'border/input/default'}>
								/
							</Text>
							{leftNode}
						</Group>
					)}
				</Group>
			</NavBarBreadcrumbWrapper>
			{rightNode}
			<NavBarRightSideWrapper>
				{hasEntityType && withTemplate && isUserCreatable && (
					<TemplatesButtonGroup
						handleNew={handleNewEntity}
						type={page?.entityType!}
						withSetDefault
						label={`Create ${lowerCase(entityLabel)}`}
					/>
				)}
				{hasEntityType && !withTemplate && isUserCreatable && (
					<Button onClick={handleNewEntity} variant="primary">
						Create {lowerCase(entityLabel)}
					</Button>
				)}
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}

export default memo(TeamCatalogPageNavBar);
