import { Group } from '@mantine/core';
import { useGetAccessRequest } from '@repo/api-codegen';
import { Button, TextSkeleton } from '@repo/foundations';
import { useCallback } from 'react';
import type { INotification } from '../../../../../api/types/models/notification';
import { DataAccessRequestApproveModal } from '../../../../../components/DataAccessRequest/DataAccessRequestApproveModal/DataAccessRequestApproveModal';
import { DataAccessRequestRejectModal } from '../../../../../components/DataAccessRequest/DataAccessRequestRejectModal/DataAccessRequestRejectModal';
import {
	closeAllModals,
	openModal,
} from '../../../../../components/ModalManager/events';

interface DataAccessRequestProps {
	notification: INotification;
}

export function DataAccessRequest({ notification }: DataAccessRequestProps) {
	const canShowButtons = ['data_access_request_created'].includes(
		notification.event
	);

	const { data: dataAccessRequest, isLoading } = useGetAccessRequest(
		{
			pathParams: {
				requestId: notification.resource_id as string,
			},
		},
		{
			enabled: Boolean(notification.resource_id) && canShowButtons,
		}
	);

	const handleDenyAccess = useCallback(() => {
		if (!dataAccessRequest) {
			return;
		}

		openModal({
			title: 'Reject access request',
			size: 480,
			children: (
				<DataAccessRequestRejectModal
					request={dataAccessRequest}
					onClose={closeAllModals}
				/>
			),
		});
	}, [dataAccessRequest]);

	const handleApproveAccess = useCallback(() => {
		if (!dataAccessRequest) {
			return;
		}

		openModal({
			title: 'Approve access request',
			size: 480,
			children: (
				<DataAccessRequestApproveModal
					request={dataAccessRequest}
					onClose={closeAllModals}
				/>
			),
		});
	}, [dataAccessRequest]);

	if (!canShowButtons || dataAccessRequest?.status !== 'pending') {
		return null;
	}

	if (isLoading || !dataAccessRequest) {
		return (
			<Group spacing="xs">
				<TextSkeleton text="Deny access" />
				<TextSkeleton text="Approve access" />
			</Group>
		);
	}

	return (
		<Group spacing="xs">
			<Button variant="default" onClick={handleDenyAccess}>
				Deny access
			</Button>
			<Button variant="primary" onClick={handleApproveAccess}>
				Approve access
			</Button>
		</Group>
	);
}
