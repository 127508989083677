import { createStyles, Flex, Menu, Tooltip } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { Button, Icon } from '@repo/foundations';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { usePlan } from '../../../../hooks/usePlans';
import { getChromeExtensionAddedComplete } from '../../../../pages/HomePage/components/Onboarding/utils';

const useStyles = createStyles((theme) => ({
	targetButton: {
		pointerEvents: 'auto',
		width: theme.other.space[7],
		height: theme.other.space[7],
		borderRadius: '50%',
		backgroundColor: theme.other.getColor('fill/secondary/default'),
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/secondary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/secondary/active'),
		},
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	menuDropdown: {
		pointerEvents: 'auto',
	},
	changeLogButton: {
		width: '100%',
	},
}));

function openLink(url: string) {
	window.open(url, '_blank');
}

export interface HelpMenuProps {
	initialOpenState?: boolean;
}

export function HelpMenu({ initialOpenState }: HelpMenuProps) {
	const { classes } = useStyles();
	const [opened, setOpened] = useState(initialOpenState || false);
	const { trial } = usePlan();

	const { data: isChromeExtensionInstalled } = useQuery(
		['chrome-extension-status'],
		getChromeExtensionAddedComplete
	);

	const { boot, show, showNewMessage } = useIntercom();

	const menuItems = [
		...(trial
			? []
			: [
					{
						key: 'message',
						icon: <Icon name="message" color="icon/primary/default" />,
						label: 'Message support',
						isExternalLink: false,
						onClick: async () => {
							await boot();
							showNewMessage();
						},
					},
					{
						key: 'feedback',
						icon: <Icon name="bulb" color="icon/primary/default" />,
						label: 'Give feedback',
						onClick: async () => {
							await boot();
							show();
						},
					},
					{
						divider: true,
						key: 'divider',
					},
				]),
		{
			key: 'doc',
			icon: <Icon name="documentation" color="icon/primary/default" />,
			label: 'Documentation',
			isExternalLink: true,
			onClick: () => openLink('https://docs.secoda.co'),
		},
		{
			key: 'whats-new',
			icon: <Icon name="speakerphone" color="icon/primary/default" />,
			label: 'Change log',
			isExternalLink: true,
			onClick: () => openLink('https://docs.secoda.co/changelog'),
		},
	];

	if (!isChromeExtensionInstalled) {
		menuItems.push({
			key: 'chrome-extension',
			icon: <Icon name="brandChrome" color="icon/primary/default" />,
			label: 'Install Chrome extension',
			isExternalLink: true,
			onClick: () =>
				openLink(
					'https://chrome.google.com/webstore/detail/secoda/akcolkhleaionhppniljgglpeeohkljk'
				),
		});
	}

	const handleMenuIconClick = () => {
		setOpened((prev) => !prev);
	};

	const helpIcon = useClickOutside(() => {
		setOpened(false);
	});

	return (
		<div ref={helpIcon}>
			<Menu
				position="top-start"
				shadow="md"
				opened={opened}
				classNames={{
					dropdown: classes.menuDropdown,
				}}
			>
				<Menu.Target>
					<Button
						className={classes.targetButton}
						onClick={handleMenuIconClick}
					>
						<Tooltip label="Help and resources">
							<Icon name="questionMark" size="md" />
						</Tooltip>
					</Button>
				</Menu.Target>
				<Menu.Dropdown>
					{menuItems.map(
						({ key, icon, label, isExternalLink, onClick, divider }) =>
							divider ? (
								<Menu.Divider key={key} />
							) : (
								<Menu.Item key={key} icon={icon} onClick={onClick}>
									<Flex w="100%" justify="space-between">
										{label}
										{isExternalLink && (
											<Icon
												name="externalLink"
												color="icon/secondary/default"
											/>
										)}
									</Flex>
								</Menu.Item>
							)
					)}
				</Menu.Dropdown>
			</Menu>
		</div>
	);
}
