import { createStyles, Stack } from '@mantine/core';
import { useGetAccessRequest } from '@repo/api-codegen';
import { Text, Title } from '@repo/foundations';
import { Helmet } from 'react-helmet';
import { DataAccessRequestContent } from '../../components/DataAccessRequest/DataAccessRequestContent/DataAccessRequestContent';
import { DOCUMENTATION_WIDTH } from '../../components/Documentation/constants';
import Error404 from '../../components/Error/Error404';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';

const useStyles = createStyles((theme) => ({
	content: {
		maxWidth: DOCUMENTATION_WIDTH,
		marginTop: theme.spacing.xl,
	},
}));

export default function RequestPage() {
	const { classes } = useStyles();
	const id = useParamsIdSuffixUuid();

	const { data: request } = useGetAccessRequest(
		{
			pathParams: {
				requestId: id,
			},
		},
		{
			suspense: true,
		}
	);

	if (!request) {
		return <Error404 />;
	}

	return (
		<PageLayoutOuterWrapper>
			<Helmet>
				<title>{request.integration_name} request</title>
			</Helmet>
			<PageLayoutWrapper name="request-page">
				<PageLayoutContentWrapper name="request-page">
					<PageLayoutContent className={classes.content}>
						<Stack spacing="xl">
							<Stack spacing="xs">
								<Title size="xl">{request.integration_name} request</Title>
								<Text size="md" style={{ whiteSpace: 'pre-wrap' }}>
									{request.requested_text}
								</Text>
							</Stack>
							<DataAccessRequestContent request={request} />
						</Stack>
					</PageLayoutContent>
				</PageLayoutContentWrapper>
			</PageLayoutWrapper>
		</PageLayoutOuterWrapper>
	);
}
