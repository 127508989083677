import { makeAutoObservable } from 'mobx';

import type { LiteQueryOut } from '@repo/api-codegen';
import type { ILineage, IQuery } from '../../../api';
import type { EntityTabsStore } from '../../TableEntityPage/TableEntityTabs/TableEntityTabsStore';

export class DatabaseEntityTabsStore implements EntityTabsStore {
	lineageCount?: number | undefined;
	setLineageCount!: (count: number) => void;
	lineageUpstreamResults?: ILineage[] | undefined;
	lineageDownstreamResults?: ILineage[] | undefined;
	setLineageUpstreamResults?: ((results: ILineage[]) => void) | undefined;
	setLineageDownstreamResults?: ((results: ILineage[]) => void) | undefined;
	creationQuery?: IQuery | LiteQueryOut | undefined;
	setCreationQuery?: ((query: IQuery | LiteQueryOut) => void) | undefined;
	queryCount?: number | undefined;
	setQueryCount?: ((count: number) => void) | undefined;
	discussionCount: number | undefined = undefined;

	get isDiscussionLoading() {
		return this.discussionCount === undefined;
	}

	reset = () => {
		this.discussionCount = undefined;
	};

	setDiscussionCount = (count: number) => {
		this.discussionCount = count;
	};
	constructor() {
		makeAutoObservable(this);
	}
}
