import { ActionIcon } from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { Icon, IconButton } from '@repo/foundations';
import { Link } from 'react-router-dom';
import {
	useAuthUser,
	useReportList,
	useWorkspace,
} from '../../../../../../api';
import { entityModalStore } from '../../../../../../components/EntityModal/store';
import { SmallLoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { WidgetType } from '../../../../../../interfaces';
import type {
	HomepageWidgetType,
	WidgetRemoveFn,
	WidgetUpdateFn,
} from '../../../../types';
import {
	COLLECTION_WIDGET_FILTERS,
	QUESTIONS_WIDGET_FILTERS,
	RESOURCE_WIDGET_FILTERS,
} from '../../constants';
import type { IWidgetItemProps } from '../../types';
import {
	isCollectionWidget,
	isQuestionsWidget,
	isResourceWidget,
	isTextWidget,
} from '../../utils';
import WidgetFilterSelector from './components/WidgetFilterSelector';
import WidgetWrapper from './components/WidgetWrapper';
import AnnouncementWidget from './widgets/AnnouncementWidget';
import CollectionWidget from './widgets/CollectionWidget';
import CollectionsWidget from './widgets/CollectionsWidget';
import { DataQualityScoreWidget } from './widgets/DataQualityScoreWidget';
import HelpfulLinksWidget from './widgets/HelpfulLinksWidget';
import InboxWidget from './widgets/InboxWidget';
import IntegrationsWidget from './widgets/IntegrationsWidget';
import QuestionsWidget from './widgets/QuestionsWidget';
import ResourcesWidget from './widgets/ResourcesWidget';
import SearchWidget from './widgets/SearchWidget';
import TextBlockWidget from './widgets/TextBlockWidget';

interface IWidgetProps extends IWidgetItemProps {
	onUpdateWidget: WidgetUpdateFn;
	onRemoveWidget: WidgetRemoveFn;
}

function Widget({ widget, onUpdateWidget, onRemoveWidget }: IWidgetProps) {
	const { workspace } = useWorkspace();
	const { user } = useAuthUser();
	const widgetType = widget.type as HomepageWidgetType;

	const { data: reportData, isLoading: isLoadingReport } = useReportList({
		filters: {
			type: 'data_quality',
		},
		options: {
			enabled: widgetType === WidgetType.DATA_QUALITY_SCORE,
		},
	});

	let content = null;
	let filter = null;
	let right = null;

	if (isTextWidget(widgetType)) {
		content = (
			<TextBlockWidget
				widget={widget}
				onChange={onUpdateWidget(widget.id, 'content')}
			/>
		);
	}

	if (widgetType === WidgetType.INTEGRATIONS) {
		content = <IntegrationsWidget widget={widget} />;
	}

	if (widgetType === WidgetType.RECENT_SEARCHES) {
		content = <SearchWidget widget={widget} />;
	}

	if (widgetType === WidgetType.ANNOUNCEMENTS) {
		content = <AnnouncementWidget />;
	}

	if (widgetType === WidgetType.INBOX) {
		content = <InboxWidget />;
	}

	if (widgetType === WidgetType.DATA_QUALITY_SCORE) {
		content = (
			<DataQualityScoreWidget
				widget={widget}
				href={`/analytics/${reportData?.results?.[0]?.id}`}
			/>
		);

		if (isLoadingReport) {
			right = <SmallLoadingSpinner w="auto" h="auto" />;
		} else if (!!reportData?.results?.[0]?.id) {
			right = (
				<ActionIcon
					component={Link}
					to={`/analytics/${reportData?.results?.[0]?.id}`}
				>
					<Icon name="chevronRight" />
				</ActionIcon>
			);
		}
	}

	if (isQuestionsWidget(widgetType)) {
		filter = (
			<WidgetFilterSelector
				value={widgetType}
				data={QUESTIONS_WIDGET_FILTERS}
				onChange={onUpdateWidget(widget.id, 'type')}
			/>
		);

		content = <QuestionsWidget widget={widget} />;
		right = (
			<IconButton
				variant="default"
				iconName="messageCircleQuestion"
				tooltip="Ask a question"
				onClick={() =>
					entityModalStore.open({
						type: EntityType.question,
						metadata: {
							owners: [user.id],
						},
					})
				}
			>
				Ask your team a question
			</IconButton>
		);
	}

	if (isResourceWidget(widgetType)) {
		const widgetFilters = workspace?.quality_enabled
			? RESOURCE_WIDGET_FILTERS
			: RESOURCE_WIDGET_FILTERS.filter(
					(filter) => filter.value !== WidgetType.LOWEST_QUALITY_SCORE
				);

		filter = (
			<WidgetFilterSelector
				value={widgetType}
				data={widgetFilters}
				onChange={onUpdateWidget(widget.id, 'type')}
			/>
		);

		content = (
			<ResourcesWidget
				widget={widget}
				onWidgetTypeChange={onUpdateWidget(widget.id, 'type')}
			/>
		);
	}

	if (isCollectionWidget(widgetType)) {
		filter = (
			<WidgetFilterSelector
				value={widgetType}
				data={COLLECTION_WIDGET_FILTERS}
				onChange={onUpdateWidget(widget.id, 'type')}
			/>
		);

		content = (
			<CollectionsWidget
				widget={widget}
				onWidgetTypeChange={onUpdateWidget(widget.id, 'type')}
			/>
		);
	}

	if (widgetType === WidgetType.COLLECTION_RESOURCES) {
		content = (
			<CollectionWidget
				widget={widget}
				onCollectionChange={onUpdateWidget(widget.id, [
					'linked_entity',
					'title',
				])}
			/>
		);
	}

	if (widgetType === WidgetType.HELPFUL_LINKS) {
		content = <HelpfulLinksWidget widget={widget} />;
	}

	return (
		<WidgetWrapper
			widget={widget}
			withMenu
			onUpdateWidget={onUpdateWidget}
			onRemoveWidget={onRemoveWidget}
			filter={filter}
			right={right}
		>
			{content}
		</WidgetWrapper>
	);
}

export default Widget;
