import { Space } from '@mantine/core';
import { Text } from '@repo/foundations';
import { useAuthUser } from '../../api';
import { useAIMemory } from '../../api/hooks/ai/useAIMemory';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import { EmptyState } from '../EmptyState';
import { SettingsAction } from './WorkspaceSettings';

export function AIViewerSettings() {
	const { workspace } = useAuthUser();
	const { needsUpgrade, enableAi, isLoading } = useAiEnabled();

	const { showMemoriesModal } = useAIMemory();

	if (isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	if (needsUpgrade) {
		return (
			<EmptyState
				title="Upgrade to access Secoda AI"
				description="Your workspace admin can upgrade to access Secoda AI's features"
				illustrationName="upgrade"
				includeGoBack={false}
				stateHeight="50vh"
				size="lg"
			/>
		);
	}

	if (enableAi) {
		return (
			<>
				<Text size="lg" weight="bold">
					Personalization
				</Text>
				<SettingsAction
					title="Memory"
					description="Secoda AI will become more helpful as you chat, picking up on details and preferences to tailor its responses to you."
					buttonText="Manage Memories"
					onButtonClick={showMemoriesModal}
				/>
				<Space h="5xl" />
			</>
		);
	} else {
		return (
			<EmptyState
				title="Enable Secoda AI to personalize your experience"
				description="Your workspace admin can enable Secoda AI for your workspace"
				illustrationName="ai"
				includeGoBack={false}
				stateHeight="50vh"
				size="lg"
			/>
		);
	}
}
