import { Stack } from '@mantine/core';
import { useCountPendingRequests } from '@repo/api-codegen';
import { UserRole } from '@repo/common/enums/UserRole';
import { Icon } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useCheckIAMPermission } from '../../../utils/authorization/roles';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { ensurePositive } from '../../../utils/utils';
import { isActive } from '../utils';
import { SideBarCategoryToggle } from './SideBarCategoryToggle';
import type { Link } from './SideBarLink';
import SideBarLink from './SideBarLink';
import { sideBarStore } from './store';

function SideBarWorkspaceLinks() {
	const location = useLocation();
	const { dataAccessRequest, policies } = useFeatureFlags();
	const { data: pendingRequestsCount } = useCountPendingRequests(
		{},
		{
			enabled: !!dataAccessRequest,
		}
	);

	const showIntegrations = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN],
		v2Permission: 'Integrations.Manage',
	});

	const showMonitors = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
		v2Permission: 'Monitors.Read',
	});

	const showAutomations = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
		v2Permission: 'Automations.Read',
	});

	const showAnalytics = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
		v2Permission: 'Analytics.Read',
	});

	const showPolicies =
		useCheckIAMPermission({
			v1AllowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
			v2Permission: 'Policies.Read',
		}) && policies;

	const footerLinks: Array<Link> = useMemo(
		() =>
			[
				{
					id: 'nav::integrations',
					label: 'Integrations',
					icon: <Icon name="plugConnected" color="icon/primary/default" />,
					href: ['/integrations'],
					show: showIntegrations,
				},
				{
					id: 'nav::requests',
					label: 'Requests',
					icon: <Icon name="squareRoundedCheck" color="icon/primary/default" />,
					href: ['/requests'],
					show: dataAccessRequest,
					showCount: true,
					count: ensurePositive(pendingRequestsCount),
				},
				{
					id: 'nav::monitors',
					label: 'Monitors',
					icon: <Icon name="activity" color="icon/primary/default" />,
					href: ['/monitors'],
					show: showMonitors,
				},
				{
					id: 'nav::policies',
					label: 'Policies',
					icon: <Icon name="checklist" color="icon/primary/default" />,
					href: ['/policies'],
					show: showPolicies,
				},
				{
					id: 'nav::automations',
					label: 'Automations',
					icon: <Icon name="bolt" color="icon/primary/default" />,
					href: ['/automations'],
					show: showAutomations,
				},
				{
					id: 'nav::analytics',
					label: 'Analytics',
					icon: <Icon name="chartBar" color="icon/primary/default" />,
					href: ['/analytics'],
					show: showAnalytics,
				},
			].filter((l) => l.show),
		[
			showIntegrations,
			dataAccessRequest,
			pendingRequestsCount,
			showMonitors,
			showPolicies,
			showAutomations,
			showAnalytics,
		]
	);

	if (!footerLinks?.length) {
		// We don't want to show "just the title 'Workspace'" to guests and viewers.
		return null;
	}

	return (
		<SideBarCategoryToggle
			label="Workspace"
			toggleKey="workspace-sidebar-collapsable-opened"
		>
			<Stack
				spacing={0}
				align={sideBarStore.collapsed ? 'center' : 'flex-start'}
			>
				{footerLinks.map((link) => (
					<SideBarLink
						key={link.id}
						link={link}
						selected={isActive(link.href, location)}
					/>
				))}
			</Stack>
		</SideBarCategoryToggle>
	);
}

export default observer(SideBarWorkspaceLinks);
