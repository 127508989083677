import { Loader } from '@mantine/core';
import type { Dispatch, SecodaEditorComponentProps } from '@repo/secoda-editor';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import type { ParseSpec } from 'prosemirror-markdown';
import type {
	Attrs,
	NodeSpec,
	Node as ProsemirrorNode,
} from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import { v4 } from '../../../../../utils/uuid/v4';
import { PageContainer } from '../components/PageContainer';
import pagesRule from '../rules/pages';
import type { NodeOptions } from './Node';
import ReactNode from './ReactNode';

export default class Page extends ReactNode {
	get name() {
		return 'page';
	}

	get markdownToken() {
		return 'page';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				id: {
					default: '', // This value is shared across all new nodes, so the `uuid()` call cannot be here.
				},
			},
			group: 'block',
			defining: true,
			atom: true,
			draggable: false,
			parseDOM: [
				{
					preserveWhitespace: 'full',
					tag: 'div.page',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									id: dom.getAttribute('id'),
								},
				},
			],
			toDOM: (node) => [
				'div',
				{
					...node.attrs,
					class: 'page',
					id: node.attrs.id,
				},
				node.attrs.id,
			],
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		const { id } = node.attrs;
		if (id) {
			state.ensureNewLine();
			state.write(`&&&${id}&&&\n\n`);
			state.ensureNewLine();
		}
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'page',
			getAttrs: (tok) => ({ id: tok.attrs?.[0][1].split('&&&')[1] }),
		};
	}

	get rulePlugins() {
		return [pagesRule];
	}

	commands({ type }: NodeOptions) {
		return (attrs?: Attrs) => (state: EditorState, dispatch: Dispatch) => {
			let id = v4();
			if (attrs && attrs.id && attrs.id !== '') {
				id = attrs.id;
			}
			dispatch(
				state.tr.insert(
					state.selection.from,
					type.create({ ...(attrs ?? {}), id })
				)
			);
			return true;
		};
	}

	component = ({ node }: SecodaEditorComponentProps) => {
		if (!node) {
			return <Loader size="xs" />;
		}

		return <PageContainer id={node.attrs.id} />;
	};
}
