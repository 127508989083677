import type {
	ESQueryModel,
	LiteQueryOut,
	QueryListOut,
} from '@repo/api-codegen';
import { useApiGetCreationQuery } from '@repo/api-codegen';
import {
	FilterDropdownType,
	FilterOperator,
	FilterOptionType,
	FilterType,
} from '@repo/common/components/Filter/types';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { useCallback } from 'react';
import type { IBaseModel, ISecodaEntity } from '../../api';
import {
	usersGetItemsByIdFn,
	usersGetItemsFn,
} from '../../components/Filter/filterConfigUtils';
import type { EntityTabsStore } from '../../pages/TableEntityPage/TableEntityTabs/TableEntityTabsStore';
import { TableV2 } from '../TableV2';
import { useActions, useColumns, useEntityQueryList } from './hooks';

const FILTER_OPTIONS = [
	{
		label: 'User',
		type: FilterOptionType.OWNERS,
		field: 'user_id',
		filterDropdownConfig: {
			searchPlaceholder: 'Search users',
			multipleCountsSuffix: 'users',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Multiple,
			getItems: usersGetItemsFn,
			getItemsById: usersGetItemsByIdFn,
			hasIsNotSetOption: true,
		},
	},
	{
		label: 'Last Run Time',
		type: FilterOptionType.RUN_DATE,
		field: 'finished_at',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.Date,
			defaultOperator: FilterOperator.IsOnOrBefore,
			getItems: [],
		},
	},
];

export interface IQueryListProps {
	entity: ISecodaEntity;
	store: EntityTabsStore;
}

export function QueryList({ entity, store }: IQueryListProps) {
	const columns = useColumns();
	const actions = useActions();
	const navigate = useNavigate();

	const entityQueryList = useEntityQueryList(
		entity?.id,
		(data: QueryListOut) => {
			store.setQueryCount?.(data.count);
		}
	);

	useApiGetCreationQuery(
		{
			pathParams: {
				entityId: entity.id,
			},
		},
		{
			onSuccess: (data: LiteQueryOut) => {
				store.setCreationQuery?.(data);
			},
		}
	);

	const handleCellClick = useCallback(
		(cell) => {
			navigate(`/query/${cell.record.id}`);
		},
		[navigate]
	);

	return (
		<TableV2<ESQueryModel & IBaseModel>
			pluralTypeString="queries"
			onTotalRowCountChange={store.setQueryCount}
			columns={columns}
			// @ts-ignore
			usePaginationList={entityQueryList}
			paginationSize={20}
			withSearch
			withCheckbox={false}
			withFilters={true}
			withFilterOptions={FILTER_OPTIONS}
			withActions={actions}
			withStickyColumn={false}
			withInteractiveHeader
			defaultSort={null}
			onCellClick={handleCellClick}
		/>
	);
}
