import axios from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { api } from '../../../../../network';
import { createWorkspace, type IWorkspace } from '../../../../api';
import { authHeaders } from '../../../../api/common';
import type { User } from '../../../../lib/models';
import { UserAccountManager } from '../../../../lib/models/userAccountManager';
import { clearCache, clearTokens } from '../../../../utils/cache/utils';

class WorkspaceSelectorStore {
	activeWorkspace: IWorkspace | undefined;

	account: UserAccountManager | undefined;

	loading = false;

	selectorOpen = false;

	authorizedDomainSearchTerm = '';

	usersSearchTerm = '';

	filteredUsers: User[] = [];

	// eslint-disable-next-line class-methods-use-this
	async clearLocalState() {
		// Both of these steps are very important:
		// 1. Clear the auth tokens from the local storage.
		// 2. Clear the workspace-level cache.
		clearTokens();
		await clearCache();
	}

	openSelector() {
		this.selectorOpen = true;
	}

	load = async () => {
		this.loading = true;
		try {
			const data = await UserAccountManager.list();
			runInAction(() => {
				[this.account] = data.results;
				this.activeWorkspace = this.account?.workspaces.find(
					(workspace) => workspace.is_active
				);
			});
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}
		runInAction(() => {
			this.loading = false;
		});
	};

	impersonate = async (email: string, totp: string) => {
		// Get the new token before clearing the local state.
		// If we clear the local state first, the request will fail and
		// the user will be logged out.
		const { status } = await axios.get(
			`${api()}auth/utilities/account/?email=${encodeURIComponent(
				email
			)}&totp=${encodeURIComponent(totp)}`,
			authHeaders()
		);
		if (status === 200) {
			await this.clearLocalState();
			window.location.reload();
		} else {
			return 'Invalid confirmation code.';
		}
	};

	searchUsers = async () => {
		if (this.usersSearchTerm.length < 3) {
			this.filteredUsers = [];
			return;
		}

		this.loading = true;
		const { data } = await axios.get(
			`${api()}auth/utilities/users/?search_term=${this.usersSearchTerm}`,
			authHeaders()
		);
		this.filteredUsers = data.results;
		this.loading = false;
	};

	setActiveWorkspace = async (workspace: IWorkspace) => {
		if (!this.account) {
			// eslint-disable-next-line no-console
			console.error('No account found.');
			return;
		}
		this.account.active_user = workspace.user_id;
		await this.account.save(['active_user']);

		// Get the new token before clearing the local state.
		// If we clear the local state first, the request will fail and
		// the user will be logged out.
		await axios.get(`${api()}auth/accounts/refresh_token/`, authHeaders());
		await this.clearLocalState();
		window.location.pathname = '/';
	};

	createWorkspace = async () => {
		await createWorkspace({
			data: {
				name: 'Untitled',
			},
		});
		// Get the new token before clearing the local state.
		// If we clear the local state first, the request will fail and
		// the user will be logged out.
		await axios.get(`${api()}auth/accounts/refresh_token/`, authHeaders());
		await this.clearLocalState();
		window.location.href = '/settings/workspace';
	};

	constructor() {
		makeAutoObservable(this);
	}
}

export const workspaceSelectorStore = new WorkspaceSelectorStore();
