import { Stack } from '@mantine/core';
import { useGetAccessRequest } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import type { INotification } from '../../../../api/types/models/notification';
import { DataAccessRequestContent } from '../../../../components/DataAccessRequest/DataAccessRequestContent/DataAccessRequestContent';
import { DataAccessRequestContentSkeleton } from '../../../../components/DataAccessRequest/DataAccessRequestContent/DataAccessRequestContentSkeleton';

interface ResourceDataAccessRequestContentProps {
	selected: INotification;
}

export default function ResourceDataAccessRequestContent({
	selected,
}: ResourceDataAccessRequestContentProps) {
	const { data: dataAccessRequest } = useGetAccessRequest(
		{
			pathParams: {
				requestId: selected.resource_id as string,
			},
		},
		{
			enabled: Boolean(selected.resource_id),
		}
	);

	if (!dataAccessRequest) {
		return <DataAccessRequestContentSkeleton />;
	}

	return (
		<Stack spacing="xl">
			<Text>{selected.content}</Text>
			<DataAccessRequestContent request={dataAccessRequest} />
		</Stack>
	);
}
