import { useDebouncedValue } from '@mantine/hooks';
import { useGetFilter } from '@repo/api-codegen';
import { useCallback } from 'react';
import { createMockableHook } from '../../../utils/createMockableHook';
import type { FILTER_OPTIONS_DIVIDER } from '../constants';
import type { FilterOption, FilterValue } from '../types';
import { getFilterValueFromApiCatalogFilter } from '../utils';

interface UseFilterDropdownAIProps {
	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[];
	onChange: (value: FilterValue, close: boolean) => void;
}

function useFilterDropdownAIInternal({
	filterOptions,
	onChange,
}: UseFilterDropdownAIProps) {
	const {
		error: errorFilter,
		isLoading: isLoadingFilter,
		mutateAsync: generateFilter,
	} = useGetFilter({});
	const [debouncedLoading] = useDebouncedValue(isLoadingFilter, 300);

	const askAi = useCallback(
		async (searchTerm: string) => {
			if (!searchTerm) {
				return;
			}

			try {
				const filterResult = await generateFilter({
					queryParams: { query: searchTerm },
				});

				const filterValue = await getFilterValueFromApiCatalogFilter(
					filterOptions,
					filterResult
				);

				filterValue.forEach((value, idx) => {
					onChange(value, idx === filterValue.length - 1);
				});
			} catch {
				// Do nothing
			}
		},
		[filterOptions, generateFilter, onChange]
	);

	return {
		loading: debouncedLoading,
		error: errorFilter,
		askAi,
	};
}

export const [useFilterDropdownAI, MockUseFilterDropdownAIProvider] =
	createMockableHook(useFilterDropdownAIInternal);
