import { Group, Stack, Tabs } from '@mantine/core';
import { Button } from '@repo/foundations';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { useApiListPolicies } from '@repo/api-codegen';
import TabsList from '@repo/common/components/TabsList';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import { EmptyState } from '../../components/EmptyState';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { UpgradeButton } from '../../components/Settings/UpgradeButton';
import { useListPageTabNavigate } from '../../utils/hook/useTabNavigate';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import AddPolicyButton from './AddPolicyButton';
import PoliciesList from './PoliciesList';

// TODO[tan-policies]: TBD if we need incident tab as well
type PoliciesListPageTab = 'policies';

function PoliciesListPage() {
	const { tab } = useParams();
	const { data: policies, isLoading } = useApiListPolicies({});
	const { policiesAccess, isLoading: isFeatureAccessLoading } =
		useFeatureAccess();

	const { classes, theme } = useStyles();
	const [activeTab, setActiveTab] = useState<PoliciesListPageTab>(
		tab === 'policies' ? 'policies' : 'policies'
	);
	const navigateToTab = useListPageTabNavigate();

	const handleTabChange = (value: string): void => {
		if (value === 'policies') {
			setActiveTab(value);
			navigateToTab(value);
		}
	};

	const learnMoreAction = useMemo(
		() => (
			<Group>
				<Button
					component="a"
					// TODO[tan-policies]: update this link
					href="https://docs.secoda.co/features/policies#how-to-create-policies"
					target="_blank"
					rel="noreferrer noopener"
					size="md"
					leftIconName="externalLink"
				>
					Learn more
				</Button>
				<AddPolicyButton />
			</Group>
		),
		[]
	);

	if (isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	if (!policiesAccess) {
		return (
			<EmptyState
				illustrationName="upgrade"
				title="Upgrade to access Policies"
				description="Create policies to ensure compliance and security across your data stack."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
				withActions={
					<UpgradeButton
						tooltip="Upgrade to access Policies"
						feature="Policies"
						size="md"
					/>
				}
			/>
		);
	}

	if (policies && policies?.results.length === 0) {
		if (isFeatureAccessLoading) {
			return <FullWidthLoadingSpinner />;
		}

		return (
			<EmptyState
				className={classes.emptySpace}
				// TODO[tan-policies]: empty state for policies
				illustrationName="monitors"
				title="Govern your data sources"
				description="Create policies to ensure compliance and security across your data stack."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
				withActions={learnMoreAction}
			/>
		);
	}

	return (
		<Stack
			className={classes.wrapper}
			spacing={0}
			mt={`-${theme.other.space[2]}px`}
		>
			<Tabs value={activeTab} onTabChange={handleTabChange}>
				<TabsList tabs={[{ value: 'policies', label: 'Policies' }]} />
				<Tabs.Panel value="policies" pt="sm">
					<PoliciesList />
				</Tabs.Panel>
			</Tabs>
		</Stack>
	);
}

export default PoliciesListPage;
