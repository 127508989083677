import { Box, Divider, Stack } from '@mantine/core';
import { isNil } from 'lodash-es';
import type { INotification } from '../../../api/types/models/notification';
import { useStyles } from './InboxViewBody.styles';
import InboxViewBodySkeleton from './InboxViewBodySkeleton';
import InboxViewContent from './InboxViewContent';
import InboxViewTitle from './InboxViewTitle';

interface IInboxViewBodyProps {
	selected?: INotification;
	isLoading: boolean;
}

function InboxViewBody({ selected, isLoading }: IInboxViewBodyProps) {
	const { classes } = useStyles();

	if (isLoading) {
		return <InboxViewBodySkeleton />;
	}

	if (isNil(selected) && !isLoading) {
		return null;
	}

	return (
		<Box className={classes.scrollbarContainer}>
			<Box className={classes.container}>
				<Stack className={classes.content} spacing="lg">
					<InboxViewTitle selected={selected} />
					<Divider />
					<InboxViewContent selected={selected} />
				</Stack>
			</Box>
		</Box>
	);
}

export default InboxViewBody;
