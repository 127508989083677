import { createStyles } from '@mantine/core';
import {
	RIGHT_SIDEBAR_TRANSITION_DURATION,
	RIGHT_SIDEBAR_TRANSITION_TIMING_FUNCTION,
	TOP_NAVBAR_HEIGHT,
} from '../constants';

export const useStyles = createStyles(
	(theme, withImpersonatingBanner: boolean = false) => ({
		sidesheetControlsWrapper: {
			position: 'fixed',
			top: withImpersonatingBanner ? TOP_NAVBAR_HEIGHT : 0,
			right: theme.spacing.xl,
			zIndex: 10,

			height: `${TOP_NAVBAR_HEIGHT}px`,
			gap: theme.spacing.sm,
			flexWrap: 'nowrap',
			paddingLeft: theme.spacing.sm,
			justifyContent: 'flex-end',
			alignItems: 'center',
		},
		toggles: {
			display: 'flex',
			gap: theme.spacing['3xs'],
			flexWrap: 'nowrap',
		},
		divider: {
			transition: `opacity 100ms ${RIGHT_SIDEBAR_TRANSITION_TIMING_FUNCTION}`,
		},
		sidesheetControlsPhantomElement: {
			height: `${TOP_NAVBAR_HEIGHT}px`,
			gap: theme.spacing.sm,
			flexWrap: 'nowrap',
			paddingLeft: theme.spacing.sm,
			justifyContent: 'flex-end',
			alignItems: 'center',

			transition: `width ${RIGHT_SIDEBAR_TRANSITION_DURATION} ${RIGHT_SIDEBAR_TRANSITION_TIMING_FUNCTION}`,
			visibility: 'hidden',
		},
	})
);
