import type { Dispatch } from '@repo/secoda-editor';
import type { EditorState } from 'prosemirror-state';
import { TextSelection } from 'prosemirror-state';

// Collapse the current selection to a cursor.
// Prose-mirror commands returns True if the transaction can be applied.
const collapseSelection = () => (state: EditorState, dispatch?: Dispatch) => {
	dispatch?.(
		state.tr.setSelection(
			TextSelection.create(state.doc, state.tr.selection.from)
		)
	);
	return true;
};

export default collapseSelection;
