import { Group, Stack, Tabs } from '@mantine/core';
import type {
	DataAccessRequestOut,
	DataAccessRequestStatus,
} from '@repo/api-codegen';
import { useListAccessRequests } from '@repo/api-codegen';
import TabsList from '@repo/common/components/TabsList';
import { Badge, Button, Text } from '@repo/foundations';
import type { DataTableColumn } from '@repo/mantine-datatable';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import type { IBaseModel } from '../../api';
import { AddRequestButton } from '../../components/DataAccessRequest/AddRequestButton';
import { EmptyState } from '../../components/EmptyState';
import { TableV2 } from '../../components/TableV2';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import { useActions, useColumns } from './RequestsPage.hooks';

const COLUMN_VISIBILITY = {
	catalogServerType: 'data_access_requests',
	catalogType: 'data_access_requests',
} as const;

function getQuickActions(status: DataAccessRequestStatus) {
	if (status === 'pending') {
		return ['actions::approve', 'actions::reject'] as const;
	}
	if (status === 'approved') {
		return ['actions::cancel'] as const;
	}
	return ['actions::cancel'] as const;
}

function AccessRequestsTable({ status }: { status: DataAccessRequestStatus }) {
	const navigate = useNavigate();
	const columns = useColumns();
	const actions = useActions();

	const filteredColumns = useMemo(
		() =>
			columns.filter((col) => {
				if (status === 'pending') {
					return (
						col.accessor !== 'approved_text' && col.accessor !== 'rejected_text'
					);
				}
				if (status === 'rejected') {
					return col.accessor !== 'approved_text';
				}
				return col.accessor !== 'rejected_text';
			}),
		[columns, status]
	);

	const handleClick = useCallback(
		(cell) => {
			if (
				(cell.column as ExtendedDataTableColumn<DataAccessRequestOut>)
					.navigate !== false
			) {
				navigate(`/requests/${cell.record.id}`);
			}
		},
		[navigate]
	);

	return (
		<TableV2
			pluralTypeString="requests"
			withQuickActions={getQuickActions(status)}
			columns={filteredColumns as unknown as DataTableColumn<IBaseModel>[]}
			columnVisibility={COLUMN_VISIBILITY}
			// @ts-expect-error using API codegen list hook
			usePaginationList={useListAccessRequests}
			useCodegenListInterface
			withActions={actions}
			withCheckbox={true}
			withCsvExport
			withInteractiveHeader
			withSearch
			defaultRequiredSearchParams={{
				status,
			}}
			withFilters={false}
			defaultSort={null}
			onCellClick={handleClick}
		/>
	);
}

export default function RequestsPage() {
	const { classes, theme } = useStyles();

	const { data: pendingRequests } = useListAccessRequests(
		{ queryParams: { status: 'pending' } },
		{ suspense: true }
	);
	const { data: activeRequests } = useListAccessRequests(
		{ queryParams: { status: 'approved' } },
		{ suspense: true }
	);
	const { data: expiredRequests } = useListAccessRequests(
		{ queryParams: { status: 'expired' } },
		{ suspense: true }
	);
	const { data: rejectedRequests } = useListAccessRequests(
		{ queryParams: { status: 'rejected' } },
		{ suspense: true }
	);
	const { data: cancelledRequests } = useListAccessRequests(
		{ queryParams: { status: 'cancelled' } },
		{ suspense: true }
	);

	if (
		!pendingRequests?.count &&
		!activeRequests?.count &&
		!expiredRequests?.count &&
		!rejectedRequests?.count &&
		!cancelledRequests?.count
	) {
		return (
			<EmptyState
				className={classes.emptySpace}
				imgSrc="/images/illustrations/automations.webp"
				imgWidth={theme.other.space[40]}
				title="Request data access"
				description="Request data access from your data team."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
				withActions={
					<Group>
						<Button
							component="a"
							target="_blank"
							rel="noreferrer noopener"
							size="md"
							href="https://docs.secoda.co/features/data-access-requests"
							leftIconName="externalLink"
						>
							Learn more
						</Button>
						<AddRequestButton />
					</Group>
				}
			/>
		);
	}

	return (
		<Stack className={classes.wrapper}>
			<Tabs defaultValue="pending">
				<TabsList
					mb="sm"
					tabs={[
						{
							value: 'pending',
							label: (
								<Group spacing="2xs">
									<Text size="xs" fw={500} color="text/secondary/default">
										Pending
									</Text>
									{pendingRequests?.count && (
										<Badge>{pendingRequests?.count ?? 0}</Badge>
									)}
								</Group>
							),
						},
						{
							value: 'active',
							label: (
								<Group spacing="2xs">
									<Text size="xs" fw={500} color="text/secondary/default">
										Active
									</Text>
									{activeRequests?.count && (
										<Badge>{activeRequests?.count ?? 0}</Badge>
									)}
								</Group>
							),
						},
						{
							value: 'expired',
							label: (
								<Group spacing="2xs">
									<Text size="xs" fw={500} color="text/secondary/default">
										Expired
									</Text>
									{expiredRequests?.count && (
										<Badge>{expiredRequests?.count ?? 0}</Badge>
									)}
								</Group>
							),
						},
						{
							value: 'rejected',
							label: (
								<Group spacing="2xs">
									<Text size="xs" fw={500} color="text/secondary/default">
										Rejected
									</Text>
									{rejectedRequests?.count && (
										<Badge>{rejectedRequests?.count ?? 0}</Badge>
									)}
								</Group>
							),
						},
						{
							value: 'cancelled',
							label: (
								<Group spacing="2xs">
									<Text size="xs" fw={500} color="text/secondary/default">
										Cancelled
									</Text>
									{cancelledRequests?.count && (
										<Badge>{cancelledRequests?.count ?? 0}</Badge>
									)}
								</Group>
							),
						},
					]}
				/>

				<Tabs.Panel value="pending">
					<AccessRequestsTable status="pending" />
				</Tabs.Panel>

				<Tabs.Panel value="active">
					<AccessRequestsTable status="approved" />
				</Tabs.Panel>

				<Tabs.Panel value="expired">
					<AccessRequestsTable status="expired" />
				</Tabs.Panel>

				<Tabs.Panel value="rejected">
					<AccessRequestsTable status="rejected" />
				</Tabs.Panel>

				<Tabs.Panel value="cancelled">
					<AccessRequestsTable status="cancelled" />
				</Tabs.Panel>
			</Tabs>
		</Stack>
	);
}
