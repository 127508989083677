import { Group, Menu, Stack, useMantineTheme } from '@mantine/core';
import type { AIPersonaOut } from '@repo/api-codegen';
import { useGetPersonas } from '@repo/api-codegen';
import { useCallback, useEffect, useState } from 'react';

import { AIAvatar } from '@repo/common/components/AI/Avatar';
import { Button, Icon, IconButton, Text } from '@repo/foundations';
import { isNil, size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarSidesheetControlsWrapper,
	NavBarWrapper,
} from '.';
import { useActiveAiPersona } from '../../../api/hooks/ai/useActiveAiPersona';
import { useFeatureFlags } from '../../../utils/featureFlags';
import AIShareButton from '../../AIAssistant/AIShareButton';
import { rightSidebarStore } from '../../RightSidebar';

function AIPageNavBar() {
	const navigate = useNavigate();
	const theme = useMantineTheme();

	const { aiPersonas } = useFeatureFlags();

	const { collapsed, setCollapsed } = rightSidebarStore;
	const { setActiveAiPersonaId, activeAiPersonaId } = useActiveAiPersona();

	const [activePersona, setActivePersona] = useState<AIPersonaOut | null>(null);
	const { data: personas } = useGetPersonas({});

	useEffect(() => {
		if (personas) {
			const persona = personas.find((p) => p.id === activeAiPersonaId) ?? null;
			setActivePersona(persona);
		}
	}, [personas, activeAiPersonaId]);

	const handleSetActivePersonaId = useCallback(
		(id?: string) => () => {
			setActiveAiPersonaId(id ?? '');
			if (personas) {
				const persona = personas.find((p) => p.id === id) ?? null;
				setActivePersona(persona);
			}
		},
		[personas, setActiveAiPersonaId]
	);

	const location = useLocation();

	const AIPromptId = useCallback(() => {
		const path = location.pathname.replace(/\/$/, '');
		return path === '/ai/new' ? '' : (path.split('/').pop() ?? '');
	}, [location.pathname]);

	const handleNewConversation = useCallback(() => {
		navigate('/ai/new');
	}, [navigate]);

	const hasPersonas = size(personas) > 0;

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				{aiPersonas && (
					<Menu position="bottom-start">
						<Menu.Target>
							<Button
								variant="tertiary"
								disabled={!hasPersonas}
								sx={{ display: 'flex', alignItems: 'center' }}
							>
								<Group spacing={theme.spacing['2xs']}>
									<AIAvatar size="xs" speed="slow" iconSpeed="none" />
									{!isNil(activePersona) && (
										<Text size="sm" weight="bold">
											{activePersona?.name ?? 'New Agent'}
										</Text>
									)}
									{!activePersona && (
										<Text size="sm" weight="bold">
											Secoda AI
										</Text>
									)}
									{hasPersonas && (
										<Icon name="selector" color="icon/secondary/default" />
									)}
								</Group>
							</Button>
						</Menu.Target>
						{hasPersonas && (
							<Menu.Dropdown>
								<Menu.Item
									onClick={handleSetActivePersonaId()}
									data-testid="ai-persona-all"
									rightSection={
										!activePersona && (
											<Icon name="check" color="icon/secondary/default" />
										)
									}
									disabled={!activePersona}
									sx={{
										backgroundColor: !activePersona
											? theme.other.getColor('surface/tertiary/default')
											: undefined,
									}}
								>
									<Group spacing={theme.spacing.xs}>
										<AIAvatar size="sm" speed="none" iconSpeed="none" />
										<Stack spacing={0} sx={{ maxWidth: 300 }}>
											<Text size="sm" weight="bold">
												Secoda AI
											</Text>
											<Text size="xs" color="text/secondary/default" truncate>
												Default persona
											</Text>
										</Stack>
									</Group>
								</Menu.Item>
								{personas?.map((persona) => {
									const isActive = activePersona?.id === persona.id;
									return (
										<Menu.Item
											key={persona.id}
											onClick={handleSetActivePersonaId(persona?.id)}
											rightSection={
												isActive && (
													<Icon name="check" color="icon/secondary/default" />
												)
											}
											disabled={isActive}
											sx={{
												backgroundColor: isActive
													? theme.other.getColor('surface/tertiary/default')
													: undefined,
											}}
										>
											<Group spacing={theme.spacing.xs}>
												<AIAvatar size="sm" speed="none" iconSpeed="none" />
												<Stack spacing={0}>
													<Text size="sm" weight="bold">
														{persona.name ?? 'New Agent'}
													</Text>
													<Text size="xs" color="text/secondary/default">
														{persona.description}
													</Text>
												</Stack>
											</Group>
										</Menu.Item>
									);
								})}
							</Menu.Dropdown>
						)}
					</Menu>
				)}
				{!aiPersonas && <Button variant="tertiary">Secoda AI</Button>}
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<AIShareButton promptId={AIPromptId()} key={AIPromptId()} />
				<Button onClick={handleNewConversation} variant="primary">
					New chat
				</Button>
			</NavBarRightSideWrapper>
			<NavBarSidesheetControlsWrapper>
				<IconButton
					iconName="history"
					variant="tertiary"
					tooltip={collapsed ? 'Show history' : 'Hide history'}
					highlight={!collapsed}
					onClick={() => setCollapsed(!collapsed)}
					data-testid="ai-history-toggle"
				/>
			</NavBarSidesheetControlsWrapper>
		</NavBarWrapper>
	);
}

export default observer(AIPageNavBar);
