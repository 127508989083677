import { useApiListTeams, useListCustomProperties } from '@repo/api-codegen';
import type { ItemIconType } from '@repo/common/components/ItemIcon';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { DataQualityClassification } from '@repo/common/enums/dataQualityClassification';
import type { SecodaThemeShades } from '@repo/theme/types';
import {
	includes,
	filter as lodashFilter,
	lowerCase,
	map,
	noop,
	startCase,
	uniqBy,
} from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import type { ISecodaEntity, ISlackChannel } from '../../../api';
import {
	AutomationDateOptions,
	AutomationField,
	fetchTagList,
	useCollectionList,
	useIntegrationList,
	useTagList,
	useWorkspaceNativeTypes,
} from '../../../api';
import { useExtendedUserList } from '../../../api/hooks/user/useExtendedUserList';
import { useSlackChannels } from '../../../api/hooks/workspace/useSlackChannels';
import type { User, UserGroup } from '../../../lib/models';
import { getSearchResults } from '../../../pages/SearchPage/utils';
import { getOwnerAndGroupSelectorOptions } from '../../EntityPageLayout/utils';
import {
	getFilterFieldDisplayValue,
	isCustomPropertyField,
} from './AutomationActionCard/Filter/utils';
import AutomationCardButton from './AutomationCardButton';
import { AutomationValueType } from './constants';
interface AutomationCardValueInputProps {
	field?: AutomationField;
	display?: string;
	value?: string | boolean;
	valueType: AutomationValueType;
	handleValueChange: (value: string | boolean, label?: string) => void;
	defaultOpened?: boolean;
}

function AutomationCardValueInput({
	field,
	display,
	valueType,
	value,
	handleValueChange,
	defaultOpened = false,
}: AutomationCardValueInputProps) {
	const [property, setProperty] = useState<string>('');
	const [iconType, setIconType] = useState<ItemIconType>('none');
	const [customPropertyOptions, setCustomPropertyOptions] = useState<
		SelectablePropertyItem[]
	>([]);

	const { data: teams } = useApiListTeams(
		{},
		{
			suspense: false,
			select: (res) => res.results,
		}
	);

	const { data: collections } = useCollectionList({});
	const { activeUsers, disabledUsers, userGroups } = useExtendedUserList();

	const { data: tags } = useTagList({});
	const { data: integrations } = useIntegrationList({});
	const { data: slackChannels } = useSlackChannels({});
	const channels = useMemo(
		() => (slackChannels ?? []) as unknown as ISlackChannel[],
		[slackChannels]
	);
	const { data: nativeTypes } = useWorkspaceNativeTypes({});
	const { data: customProperties } = useListCustomProperties(
		{},
		{ suspense: false }
	);

	const fetchSecodaEntities = useCallback(async () => {
		const { results: searchResults } = (await getSearchResults('')) as {
			results: ISecodaEntity[];
			totalPages: number;
		};

		const options = searchResults.map((item) => ({
			label: item.title ?? 'Untitled',
			value: item.id,
		}));
		setCustomPropertyOptions(options);
	}, []);

	const fetchSelectedTags = useCallback(async (searchId: string) => {
		const selectedTags = await fetchTagList({
			filters: {
				custom_property_id: searchId,
			},
		});

		const options = selectedTags.results.map((tag) => ({
			label: tag.name,
			value: tag.id,
		}));

		setCustomPropertyOptions(options);
	}, []);

	const valueOptions = useMemo(() => {
		let options: SelectablePropertyItem[] = [];
		if (field === AutomationField.TEAMS) {
			setProperty('team');
			setIconType('emoji');

			options =
				teams?.map((t) => ({
					label: t.name,
					value: t.id,
				})) ?? [];
		}

		if (field === AutomationField.OWNERS) {
			setProperty('owner');
			setIconType('avatar');

			options = getOwnerAndGroupSelectorOptions(
				[...activeUsers, ...disabledUsers] as unknown as User[],
				(userGroups ?? []) as unknown as UserGroup[],
				noop,
				noop
			);
		}

		if (field === AutomationField.SUBSCRIBERS) {
			setProperty('owner');
			setIconType('avatar');

			options = getOwnerAndGroupSelectorOptions(
				[...activeUsers, ...disabledUsers] as unknown as User[],
				(userGroups ?? []) as unknown as UserGroup[],
				noop,
				noop
			);
		}

		if (field === AutomationField.TAGS) {
			setProperty('tag');
			setIconType('tag');

			options =
				tags?.results.map((tag) => ({
					label: tag.name,
					value: tag.id,
					color: tag.color as SecodaThemeShades,
				})) ?? [];
		}

		if (field === AutomationField.COLLECTIONS) {
			setProperty('collection');
			setIconType('emoji');

			options =
				collections?.results?.map((collection) => ({
					label: collection.title || 'Untitled',
					value: collection.id,
					icon: collection.icon,
				})) ?? [];
		}

		if (field === AutomationField.TESTS) {
			setProperty('tests');
			setIconType('none');

			options = [
				{
					label: 'True',
					value: true,
				},
				{
					label: 'False',
					value: false,
				},
			];
		}

		if (field === AutomationField.PII) {
			setProperty('governance');
			setIconType('none');

			options = [
				{
					label: 'PII',
					value: true,
				},
				{
					label: 'Non-PII',
					value: false,
				},
			];
		}

		if (field === AutomationField.VERIFIED) {
			setProperty('verification');
			setIconType('none');

			options = [
				{
					label: 'Verified',
					value: true,
				},
				{
					label: 'Unverified',
					value: false,
				},
			];
		}

		if (field === AutomationField.PUBLISHED) {
			setProperty('status');
			setIconType('none');

			options = [
				{
					label: 'Published',
					value: true,
				},
				{
					label: 'Draft',
					value: false,
				},
			];
		}

		if (
			field &&
			[
				AutomationField.QUALITY,
				AutomationField.QUALITY_ACCURACY,
				AutomationField.QUALITY_RELIABILITY,
				AutomationField.QUALITY_STEWARDSHIP,
				AutomationField.QUALITY_USABILITY,
			].includes(field)
		) {
			setProperty('status');
			setIconType('none');

			options = [
				{
					label: 'Poor',
					value: DataQualityClassification.POOR,
				},
				{
					label: 'Moderate',
					value: DataQualityClassification.MODERATE,
				},
				{
					label: 'Good',
					value: DataQualityClassification.GOOD,
				},
				{
					label: 'Unavailable',
					value: DataQualityClassification.UNAVAILABLE,
				},
			];
		}

		if (field === AutomationField.INTEGRATION_ID) {
			setProperty('integration');
			setIconType('none');

			options =
				integrations?.results?.map((integration) => ({
					label: integration.name,
					value: integration.id,
				})) ?? [];
		}

		if (field === AutomationField.SOURCES) {
			setProperty('source');
			setIconType('none');

			options = map(
				uniqBy(
					lodashFilter(integrations?.results, (integration) =>
						includes(['dbt', 'airflow'], integration.type)
					),
					'type'
				),
				(integration) => ({
					label: startCase(integration.type),
					value: integration.id,
				})
			);
		}

		if (field === AutomationField.SLACK_CHANNELS) {
			setProperty('Slack Channel');
			setIconType('none');

			options = channels.map((channel) => ({
				label: channel.name,
				value: channel.id,
			}));
		}

		if (field === AutomationField.NATIVE_TYPE) {
			setProperty('Type');
			setIconType('none');

			options =
				nativeTypes?.map((nativeType) => ({
					id: nativeType.native_type,
					value: nativeType.native_type,
					label: nativeType.native_type_cased,
				})) ?? [];
		}

		if (
			field === AutomationField.EXTERNAL_UPDATED_AT ||
			field === AutomationField.CREATED_AT
		) {
			setProperty('Date');
			setIconType('none');

			options = [
				{
					label: '1 day ago',
					value: AutomationDateOptions.ONE_DAY_AGO,
				},
				{
					label: '7 days ago',
					value: AutomationDateOptions.SEVEN_DAYS_AGO,
				},
				{
					label: '30 days ago',
					value: AutomationDateOptions.THIRTY_DAYS_AGO,
				},
				{
					label: '60 days ago',
					value: AutomationDateOptions.SIXTY_DAYS_AGO,
				},
				{
					label: '90 days ago',
					value: AutomationDateOptions.NINETY_DAYS_AGO,
				},
				{
					label: 'Custom',
					value: AutomationDateOptions.CUSTOM,
				},
			];
		}

		if (isCustomPropertyField(field)) {
			let newCustomerPropertyOptions: SelectablePropertyItem[] = [];
			if (typeof field === 'string') {
				const [_, searchId, __] = field.split('.');
				const customProperty = customProperties?.find(
					(cp) => cp.id === searchId
				);
				setProperty(customProperty?.name || 'Custom Property');
				setIconType('none');

				if (customProperty?.value_type === 'user') {
					newCustomerPropertyOptions = getOwnerAndGroupSelectorOptions(
						[...activeUsers, ...disabledUsers] as unknown as User[],
						(userGroups ?? []) as unknown as UserGroup[],
						noop,
						noop
					);
					setCustomPropertyOptions(newCustomerPropertyOptions);
				} else if (customProperty?.value_type === 'resource') {
					fetchSecodaEntities();
				} else if (customProperty?.value_type === 'select') {
					fetchSelectedTags(searchId);
				} else if (customProperty?.value_type === 'boolean') {
					setCustomPropertyOptions([
						{
							label: 'Checked',
							value: true,
						},
						{
							label: 'Unchecked',
							value: false,
						},
					]);
				} else if (customProperty?.value_type === 'date') {
					const dateOptions = [
						{
							label: 'Custom',
							value: AutomationDateOptions.CUSTOM,
						},
					];
					setCustomPropertyOptions(dateOptions);
				}
			}
		}

		if (!isCustomPropertyField(field)) {
			setCustomPropertyOptions([]);
		}

		return options;
	}, [
		field,
		teams,
		activeUsers,
		disabledUsers,
		userGroups,
		tags?.results,
		collections?.results,
		integrations?.results,
		channels,
		nativeTypes,
		customProperties,
		fetchSelectedTags,
		fetchSecodaEntities,
	]);
	const fieldLabel = lowerCase(getFilterFieldDisplayValue(field)) || '';
	const buttonOptions =
		valueOptions.length > 0 ? valueOptions : customPropertyOptions;

	if (valueType === AutomationValueType.DROPDOWN) {
		return (
			<AutomationCardButton
				buttonLabel={display || 'Value'}
				buttonOptions={buttonOptions}
				onClick={handleValueChange}
				addFilter
				property={property}
				iconType={iconType}
				isSelected={!!display}
				type={valueType}
				value={value}
				useHighlight
				selectButtonLabel={`Select ${fieldLabel}`}
			/>
		);
	}

	if (valueType === AutomationValueType.DATE_INPUT) {
		if (typeof value === 'boolean') {
			throw new Error('Invalid value has been provided');
		}
		return (
			<AutomationCardButton
				buttonLabel={display || 'Pick a date'}
				buttonOptions={[]}
				onClick={handleValueChange}
				isSelected={!!display}
				type={valueType}
				useHighlight={false}
				value={value}
				selectButtonLabel="Select date"
				defaultOpened={defaultOpened}
			/>
		);
	}

	if (valueType === AutomationValueType.TEXT_INPUT) {
		return (
			<AutomationCardButton
				buttonLabel={display || 'Value'}
				buttonOptions={[]}
				onClick={handleValueChange}
				isSelected={!!display}
				type={valueType}
				useHighlight={false}
				value={value}
				selectButtonLabel={`Add ${fieldLabel}`}
			/>
		);
	}

	throw new Error('Invalid automation value type has been provided');
}

export default observer(AutomationCardValueInput);
