import { createStyles, Stack } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { isEmpty, isNil } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type { INotification } from '../../../api/types/models/notification';
import type { ButtonDetails } from '../../../components/EmptyState';
import { EmptyState } from '../../../components/EmptyState';
import InboxViewBody from './InboxViewBody';
import InboxViewHeader from './InboxViewHeader/InboxViewHeader';

interface IInboxViewProps {
	selected?: INotification;
	setSelectedId: (id: string) => void;
	data: INotification[];
	searchTerm: string;
	events: string;
	isLoading: boolean;
}

const useStyles = createStyles({
	container: {
		width: '100%',
		height: 'calc(100vh - 58px - 42px)',
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
	},
});

function InboxView({
	selected,
	setSelectedId,
	data,
	searchTerm,
	events,
	isLoading,
}: IInboxViewProps) {
	const { classes } = useStyles();

	const navigate = useNavigate();

	const handleClearFilters = useCallback(() => {
		navigate('/inbox');
	}, [navigate]);

	const buttons: ButtonDetails[] = useMemo(
		() => [
			{
				name: 'Clear filters',
				action: handleClearFilters,
				isPrimary: false,
				size: 'md',
			},
		],
		[handleClearFilters]
	);

	if ((!isEmpty(events) || !isEmpty(searchTerm)) && isEmpty(data)) {
		return (
			<EmptyState
				iconName="inbox"
				title="No notifications found"
				description="Try searching for something else or clearing filters"
				buttons={buttons}
				includeGoBack={false}
				size="sm"
			/>
		);
	}

	if (isEmpty(data)) {
		return (
			<EmptyState
				iconName="inbox"
				title="You're all caught up"
				description="Check back later for new notifications."
				includeGoBack={false}
				withGames
				size="sm"
			/>
		);
	}

	if (isNil(selected) && !isLoading) {
		return (
			<EmptyState
				iconName="inbox"
				title="No notification selected"
				description="Select a notification from the sidebar."
				includeGoBack={false}
				size="sm"
			/>
		);
	}

	return (
		<Stack className={classes.container} spacing={0}>
			<InboxViewHeader selected={selected} setSelectedId={setSelectedId} />
			<InboxViewBody selected={selected} isLoading={isLoading} />
		</Stack>
	);
}

export default InboxView;
