import { Timeline } from '@mantine/core';
import { useStyles } from './ActivityLogV2.styles';
import { AuditLogItem } from './AuditLogItem';
import { CommentItem } from './CommentItem/CommentItem';
import { BULLET_SIZE } from './constants';
import type { ActivityLogItem } from './types';

export interface ActivityLogV2Props {
	items: ActivityLogItem[];
	onCommentChange?: () => void;
}

export function ActivityLogV2({ items, onCommentChange }: ActivityLogV2Props) {
	const { classes } = useStyles();

	return (
		<Timeline classNames={classes} bulletSize={BULLET_SIZE} lineWidth={2}>
			{items.map((item) => {
				if (item.type === 'audit') {
					return <AuditLogItem key={item.id} item={item} />;
				}

				return (
					<CommentItem
						key={item.comment.id}
						comment={item.comment}
						onChange={onCommentChange}
					/>
				);
			})}
		</Timeline>
	);
}
