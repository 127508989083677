import { Group } from '@mantine/core';
import { useStyles } from './NavBarWrapper.styles';

interface NavBarWrapperProps {
	children: React.ReactNode;
	bg?: string;
}

export function NavBarWrapper({ children, bg }: NavBarWrapperProps) {
	const { classes } = useStyles();

	return (
		<Group className={classes.wrapper} bg={bg}>
			{children}
		</Group>
	);
}
