import { Group, Stack } from '@mantine/core';
import {
	Icon,
	Select,
	Text,
	TextArea,
	TextInput,
	Title,
} from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { forwardRef } from 'react';
import { useCreatePolicyStore } from './context';

const COMPLIANCE_FRAMEWORKS = [
	// placeholder values for now
	{ value: 'hipaa', label: 'HIPAA' },
	{ value: 'iso', label: 'ISO27001' },
	{ value: 'soc2', label: 'SOC2' },
	{ value: 'gdpr', label: 'GDPR' },
];

const SEVERITY_LEVELS = [
	// placeholder values for now
	{
		value: 'high',
		label: 'High',
		iconName: 'priorityHigh',
		iconColor: 'fill/critical/default',
	},
	{
		value: 'medium',
		label: 'Medium',
		iconName: 'priorityMedium',
		iconColor: 'fill/warning/default',
	},
	{
		value: 'low',
		label: 'Low',
		iconName: 'priorityLow',
		iconColor: 'fill/caution/default',
	},
];

interface SelectItemProps {
	iconName: 'priorityHigh' | 'priorityMedium' | 'priorityLow';
	label: string;
	iconColor:
		| 'fill/caution/default'
		| 'fill/critical/default'
		| 'fill/warning/default';
}

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
	({ label, iconName, iconColor, ...others }: SelectItemProps, ref) => (
		<div ref={ref} {...others}>
			<Group noWrap spacing="sm">
				<Icon name={iconName} color={iconColor} />
				<Text>{label}</Text>
			</Group>
		</div>
	)
);

SelectItem.displayName = 'SelectItem';

function PropertiesStack() {
	const store = useCreatePolicyStore();

	return (
		<Stack p={0} spacing="lg">
			<Title size="md">Define policy properties</Title>
			<TextInput
				label="Policy name"
				value={store.name}
				onChange={(e) => store.setName(e.target.value)}
				error={store.nameError}
			/>
			<TextArea
				label="Description"
				value={store.description}
				onChange={(e) => store.setDescription(e.target.value)}
				error={store.descriptionError}
				minRows={4}
			/>
			<Select
				label="Compliance framework"
				placeholder="Select compliance framework"
				data={COMPLIANCE_FRAMEWORKS}
				value={store.framework}
				onChange={store.setFramework}
			/>
			<Select
				label="Severity"
				placeholder="Select severity level"
				data={SEVERITY_LEVELS}
				value={store.severity}
				onChange={store.setSeverity}
				itemComponent={SelectItem}
			/>
		</Stack>
	);
}

export default observer(PropertiesStack);
