import { createStyles } from '@mantine/core';
import { DataAccessRequestContentSkeleton } from '../../components/DataAccessRequest/DataAccessRequestContent/DataAccessRequestContentSkeleton';
import { DOCUMENTATION_WIDTH } from '../../components/Documentation/constants';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';

const useStyles = createStyles((theme) => ({
	content: {
		maxWidth: DOCUMENTATION_WIDTH,
		marginTop: theme.spacing.xl,
	},
}));

export function RequestPageSkeleton() {
	const { classes } = useStyles();

	return (
		<PageLayoutOuterWrapper>
			<PageLayoutWrapper name="request-page">
				<PageLayoutContentWrapper name="request-page">
					<PageLayoutContent className={classes.content}>
						<DataAccessRequestContentSkeleton />
					</PageLayoutContent>
				</PageLayoutContentWrapper>
			</PageLayoutWrapper>
		</PageLayoutOuterWrapper>
	);
}
