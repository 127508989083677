import type { TimelineItemProps } from '@mantine/core';
import { Timeline } from '@mantine/core';
import { AvatarSkeleton, TextSkeleton } from '@repo/foundations';

export type ActivityItemSkeletonProps = TimelineItemProps;

export function ActivityItemSkeleton(props: ActivityItemSkeletonProps) {
	return (
		<Timeline.Item
			bullet={<AvatarSkeleton size="sm" />}
			title={<TextSkeleton />}
			{...props}
		/>
	);
}
