import { Group } from '@mantine/core';
import { sortBy, uniqBy } from 'lodash-es';
import { useMemo } from 'react';
import { type IComment } from '../../../api';
import { InlineEmoji } from '../../InlineEmojiPicker/InlineEmoji';
import { CommentReactionButton } from './CommentReactionButton';
import { useCommentReaction } from './useCommentReaction';

export interface CommentReactionsProps {
	comment: IComment;
}

export function CommentReactions({ comment }: CommentReactionsProps) {
	const { handleSelectEmoji } = useCommentReaction({ comment });

	const reactions = useMemo(
		() =>
			sortBy(uniqBy(comment.reactions ?? [], 'emoji'), 'emoji').filter(
				(f) => f.users.length > 0
			),
		[comment.reactions]
	);

	if (reactions.length === 0) {
		return null;
	}

	return (
		<Group spacing="xs">
			{reactions.map(({ emoji, users: emojiUsers }) => (
				<InlineEmoji
					key={emoji}
					emoji={emoji}
					users={emojiUsers}
					onToggle={handleSelectEmoji}
				/>
			))}
			<CommentReactionButton comment={comment} />
		</Group>
	);
}
