import { Group } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button } from '@repo/foundations';
import { useCreatePolicyStore } from './context';
// import { useCreatePolicy } from '../../../api';

interface CreatePolicyButtonProps {
	exitPage: () => void;
}

function CreatePolicyButton({ exitPage }: CreatePolicyButtonProps) {
	const store = useCreatePolicyStore();
	// const { mutateAsync: createPolicy } = useCreatePolicy({});

	const handleCreate = async () => {
		if (!store.isFormValid) {
			return;
		}

		try {
			// await createPolicy(store.getData());
			showNotification({
				title: 'Success',
				message: 'New policy created successfully.',
				color: 'green',
			});
			exitPage();
		} catch (error) {
			showNotification({
				message: `Failed to create policy: ${error}`,
				color: 'red',
			});
		}
	};

	return (
		<Group position="right">
			<Button variant="primary" onClick={handleCreate}>
				Create role
			</Button>
		</Group>
	);
}

export default CreatePolicyButton;
