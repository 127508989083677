import { Table } from '@mantine/core';
import type { TableProps } from 'react-virtuoso';
import type { DataTableContext } from './ViruosoRowComponent';

export function VirtuosoTableComponent<T>({
	context,
	...props
}: TableProps & { context?: DataTableContext<T> }) {
	if (!context) {
		return null;
	}

	const { horizontalSpacing, className } = context.tableContext;
	return (
		<Table horizontalSpacing={horizontalSpacing} className={className}>
			{props.children}
		</Table>
	);
}
