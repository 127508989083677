import { ActionIcon, Tooltip, useMantineTheme } from '@mantine/core';
import type { MonitorsOut } from '@repo/api-codegen';
import {
	IconAlertTriangle,
	IconCircleCheck,
	IconExclamationCircle,
} from '@tabler/icons-react';
import { every, isNil, size, some } from 'lodash-es';
import type { MouseEvent } from 'react';
import type { ILineageTableTest } from '../../../../api';
import { lineageStore } from '../../store';
import { isTestRunSuccessful } from './utils';

interface ITestBadgeProps {
	tests: ILineageTableTest[];
	monitors: MonitorsOut[];
}

type Status = 'critical' | 'warning' | 'success';
type MonitorStatus = 'error' | 'warning' | 'success' | 'inactive' | 'none';

const STATUS_CONFIG = {
	critical: {
		icon: IconExclamationCircle,
		color: 'icon/critical/default',
	},
	warning: {
		icon: IconAlertTriangle,
		color: 'icon/warning/default',
	},
	success: {
		icon: IconCircleCheck,
		color: 'icon/success/default',
	},
} as const;

const WARNING_MONITOR_STATUSES = ['incident', 'pending', 'learning'] as const;

function TestBadge({ tests, monitors }: ITestBadgeProps) {
	const theme = useMantineTheme();

	const hasValidTests = some(tests, (test) => test.latest_run !== null);
	const hasTests = !isNil(tests) && size(tests) > 0 && hasValidTests;
	const hasMonitors = !isNil(monitors) && size(monitors) > 0;

	if (!hasTests && !hasMonitors) {
		return null;
	}

	const isTestSuccessful = !some(
		tests,
		(test) =>
			!isTestRunSuccessful(test.latest_run?.status) && !isNil(test.latest_run)
	);

	const getMonitorStatus = (): MonitorStatus => {
		if (!hasMonitors) return 'none';

		if (some(monitors, (m) => m.status === 'error')) return 'error';
		if (some(monitors, (m) => m.status === 'inactive')) return 'inactive';
		if (
			some(monitors, (m) =>
				WARNING_MONITOR_STATUSES.includes(
					m.status as 'incident' | 'pending' | 'learning'
				)
			)
		)
			return 'warning';
		if (every(monitors, (m) => m.status === 'normal')) return 'success';
		return 'warning';
	};

	const getCombinedStatus = (): Status => {
		const monitorStatus = getMonitorStatus();

		if (!isTestSuccessful || monitorStatus === 'error') return 'critical';
		if (monitorStatus === 'warning' || monitorStatus === 'inactive')
			return 'warning';
		return 'success';
	};

	const getTooltipLabel = (): string => {
		if (hasTests && hasMonitors) return 'View monitors and tests';
		if (hasTests) return 'View tests';
		return 'View monitors';
	};

	const handleViewTestResults = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		lineageStore.setTestsModalOpen(true, tests, monitors);
	};

	const status = getCombinedStatus();
	const { icon: Icon, color } = STATUS_CONFIG[status];

	return (
		<Tooltip label={getTooltipLabel()} withArrow>
			<ActionIcon onClick={handleViewTestResults} size="sm">
				<Icon
					size={theme.other.iconSize.sm}
					color={theme.other.getColor(color)}
				/>
			</ActionIcon>
		</Tooltip>
	);
}

export default TestBadge;
