import { useCallback, useState } from 'react';
import type { AddedFilterResult, FilterOption, FilterValue } from '../types';

interface UseAddFilterProps {
	onAddFilter: (value: FilterValue) => AddedFilterResult;
	dropdownStandaloneOption?: FilterOption | null | undefined;
	close: () => void;
}

export function useAddFilter({
	onAddFilter,
	close,
	dropdownStandaloneOption = null,
}: UseAddFilterProps) {
	const [selectedFilterOption, setSelectedFilterOption] = useState<
		FilterOption | null | undefined
	>(dropdownStandaloneOption);

	const [filterValue, setFilterValue] = useState<FilterValue | null>(null);
	const [createdFilter, setCreatedFilter] = useState<AddedFilterResult | null>(
		null
	);

	const reset = useCallback(() => {
		setCreatedFilter(null);
		setFilterValue(null);
		setSelectedFilterOption(null);
	}, []);

	const handleAddFilter = useCallback(
		(value: FilterValue, shouldClose: boolean) => {
			if (createdFilter === null) {
				const result = onAddFilter(value);
				setCreatedFilter(result);
				setFilterValue(result.value);
			} else {
				createdFilter.changeFilter(value);
				setFilterValue(value);
			}

			if (shouldClose) {
				close();
			}
		},
		[close, createdFilter, onAddFilter]
	);

	return {
		selectedFilterOption,
		setSelectedFilterOption,
		filterValue,
		reset,
		handleAddFilter,
	};
}
