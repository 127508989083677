import { useDidUpdate } from '@mantine/hooks';
import { parseFilterValuesFromLocalStorage } from '@repo/common/components/Filter';
import type { FILTER_OPTIONS_DIVIDER } from '@repo/common/components/Filter/constants';
import type { DataTableSortStatus } from '@repo/mantine-datatable';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { FilterOption, FilterOptionType, FilterValue } from '.';
import { SearchFilterV2Store } from '.';
import { deleteParamsFromUrl, setParamsInUrl } from '../../utils/url';
import { captureError } from '../../web-tracing';
import { useFilterQueryString } from './useFilterQueryString';

interface UseFilterStoreWithPersistenceOptions {
	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[];
	// The filters preferences are stored in the local storage, with this key.
	filterLocalStorageKey?: string;
	// The sort preferences are stored in the local storage, with this key.
	sortLocalStorageKey?: string;
	// The filters are synced to the URL with this key.
	filtersQueryStringKey?: string;
	// This is used to restrict the filters that are shown in the filter bar.
	// It is used to prevent the user from adding filters that are not supported
	// by the table.
	excludeItems?: { [key in FilterOptionType]?: string[] };
}

export function useFilterStoreWithPersistence({
	filterOptions,
	filterLocalStorageKey,
	sortLocalStorageKey,
	filtersQueryStringKey,
	excludeItems,
}: UseFilterStoreWithPersistenceOptions) {
	const getFiltersFromUrl = useFilterQueryString(
		filtersQueryStringKey,
		filterOptions
	);

	const searchFilterV2Store = useMemo(() => {
		function onFilterChanged(values: FilterValue[]) {
			if (!!filterLocalStorageKey) {
				localStorage.setItem(filterLocalStorageKey, JSON.stringify(values));
			}

			if (!!filtersQueryStringKey) {
				if (values?.length > 0) {
					setParamsInUrl(filtersQueryStringKey, JSON.stringify(values));
				} else {
					deleteParamsFromUrl(filtersQueryStringKey);
				}
			}
		}

		function onSortChanged(sort?: DataTableSortStatus) {
			if (!!sortLocalStorageKey) {
				if (sort) {
					localStorage.setItem(sortLocalStorageKey, JSON.stringify(sort));
				} else {
					localStorage.removeItem(sortLocalStorageKey);
				}
			}
		}

		// load initial values from url or local storage (in that order of priority)
		let initialValues: FilterValue[] = [];
		if (!!filtersQueryStringKey) {
			initialValues = getFiltersFromUrl();
		}

		if (initialValues.length === 0 && !!filterLocalStorageKey) {
			initialValues = parseFilterValuesFromLocalStorage(filterLocalStorageKey);
		}

		let initialSort: DataTableSortStatus | undefined = undefined;
		if (!!sortLocalStorageKey) {
			try {
				const rawSortValue = localStorage.getItem(sortLocalStorageKey);
				initialSort = rawSortValue ? JSON.parse(rawSortValue) : undefined;
			} catch (error) {
				captureError(error);
				localStorage.removeItem(sortLocalStorageKey);
			}
		}

		return new SearchFilterV2Store({
			filterOptions,
			initialValues,
			preferencesLocalStorageKey: filterLocalStorageKey,
			urlParamToSyncFilters: filtersQueryStringKey,
			excludeItems,
			onFilterChanged,
			onSortChanged,
			initialSort,
		});
	}, [
		filtersQueryStringKey,
		filterLocalStorageKey,
		sortLocalStorageKey,
		filterOptions,
		excludeItems,
		getFiltersFromUrl,
	]);

	useEffect(() => {
		searchFilterV2Store.prefetchPromises();
	}, [searchFilterV2Store]);

	const [searchParams] = useSearchParams();

	useDidUpdate(() => {
		if (filtersQueryStringKey) {
			const filtersFromUrl = getFiltersFromUrl();
			if (filtersFromUrl.length > 0) {
				searchFilterV2Store.setValues(filtersFromUrl);
			}
		}
	}, [
		filtersQueryStringKey,
		getFiltersFromUrl,
		searchFilterV2Store,
		searchParams,
	]);

	return {
		searchFilterV2Store,
	};
}
