import type { Dispatch } from '@repo/secoda-editor';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import type { NodeSpec } from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import { isInTable } from 'prosemirror-tables';
import breakRule from '../rules/breaks';
import type { NodeOptions } from './Node';
import Node from './Node';

export default class HardBreak extends Node {
	get name() {
		return 'br';
	}

	get schema(): NodeSpec {
		return {
			inline: true,
			group: 'inline',
			selectable: false,
			parseDOM: [{ tag: 'br' }],
			toDOM() {
				return ['br'];
			},
		};
	}

	get rulePlugins() {
		return [breakRule];
	}

	commands({ type }: NodeOptions) {
		return () => (state: EditorState, dispatch: Dispatch) => {
			dispatch(state.tr.replaceSelectionWith(type.create()).scrollIntoView());
			return true;
		};
	}

	keys({ type }: NodeOptions) {
		return {
			'Shift-Enter': (state: EditorState, dispatch?: Dispatch) => {
				if (!isInTable(state)) {
					return false;
				}
				dispatch?.(
					state.tr.replaceSelectionWith(type.create()).scrollIntoView()
				);
				return true;
			},
		};
	}

	toMarkdown(state: MarkdownSerializerState) {
		state.write('\\n');
	}

	parseMarkdown() {
		return { node: 'br' };
	}
}
