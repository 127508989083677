import type { Dispatch } from '@repo/secoda-editor';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import { InputRule } from 'prosemirror-inputrules';
import type { ParseSpec } from 'prosemirror-markdown';
import type {
	Attrs,
	NodeSpec,
	Node as ProsemirrorNode,
} from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import type { NodeOptions } from './Node';
import Node from './Node';

export default class HorizontalRule extends Node {
	get name() {
		return 'hr';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				markup: {
					default: '---',
				},
			},
			group: 'block',
			parseDOM: [{ tag: 'hr' }],
			toDOM: (node) => [
				'hr',
				{ class: node.attrs.markup === '***' ? 'page-break' : '' },
			],
		};
	}

	commands({ type }: NodeOptions) {
		return (attrs?: Attrs) => (state: EditorState, dispatch: Dispatch) => {
			dispatch(
				state.tr.replaceSelectionWith(type.create(attrs)).scrollIntoView()
			);
			return true;
		};
	}

	keys({ type }: NodeOptions) {
		return {
			'Mod-_': (state: EditorState, dispatch?: Dispatch) => {
				dispatch?.(
					state.tr.replaceSelectionWith(type.create()).scrollIntoView()
				);
				return true;
			},
		};
	}

	inputRules({ type }: NodeOptions) {
		return [
			new InputRule(/^(?:---|___\s|\*\*\*\s)$/, (state, match, start, end) => {
				const { tr } = state;

				if (match[0]) {
					const markup = match[0].trim();
					tr.replaceWith(start - 1, end, type.create({ markup }));
				}

				return tr;
			}),
		];
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		state.write(`\n${node.attrs.markup}`);
		state.closeBlock(node);
	}

	parseMarkdown(): ParseSpec {
		return {
			node: 'hr',
			getAttrs: (tok) => ({
				markup: tok.markup,
			}),
		};
	}
}
