import { Group, Stack } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { find, includes, isNil, startCase } from 'lodash-es';
import { useMemo } from 'react';
import type { ICollection } from '../../../../api';
import { useCollectionListAll, useIntegrationList } from '../../../../api';
import type { INotification } from '../../../../api/types/models/notification';
import { SecodaEntityIcon } from '../../../../components/SecodaEntity';
import { UserAvatar } from '../../../../components/UserAvatar';
import type { Integration } from '../../../../lib/models';
import { buildResourceUrl } from '../../../../utils/navigationUtils';
import { useStyles } from './styles';

interface IResourceAddRemoveCollectionContentProps {
	selected: INotification;
}

function ResourceAddRemoveCollectionContent({
	selected,
}: IResourceAddRemoveCollectionContentProps) {
	const { classes, cx } = useStyles();

	const navigate = useNavigate();

	const { data: integrations } = useIntegrationList({});
	const { data: collections } = useCollectionListAll();

	const integration = useMemo(
		() =>
			selected.entity
				? find(integrations?.results, {
						id: selected.entity?.integration,
					})
				: null,
		[integrations?.results, selected.entity]
	) as unknown as Integration;

	const collection = useMemo(
		() => find(collections?.results, { title: selected.content }),
		[collections?.results, selected.content]
	) as unknown as ICollection;

	const handleNavigateToUser = () => {
		navigate(`/user/${selected.sender.id}`);
	};

	const handleNavigateToResource = () => {
		navigate(
			buildResourceUrl(
				selected.entity || {
					id: selected.resource_id,
					entity_type: selected.resource_type,
				}
			)
		);
	};

	const handleNavigateToCollection = (entity: ICollection) => {
		navigate(buildResourceUrl(entity));
	};

	return (
		<Stack spacing="lg">
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="user" />
					User
				</Group>
				<Group
					className={cx(classes.rightCol, classes.cursorPointer)}
					spacing="xs"
					onClick={handleNavigateToUser}
				>
					<UserAvatar user={selected.sender} size="lg" />
					<Stack spacing={0}>
						<Text className={classes.label}>
							{selected.sender.display_name}
						</Text>
						<Text className={classes.subLabel}>{`${startCase(
							selected.sender.role
						)} • ${selected.sender.email}`}</Text>
					</Stack>
				</Group>
			</Group>
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="file" />
					{`Resource ${
						includes(selected.event, 'added') ? 'added' : 'removed'
					}`}
				</Group>
				<Group
					className={cx(classes.rightCol, classes.cursorPointer)}
					spacing="xs"
					onClick={handleNavigateToResource}
				>
					<SecodaEntityIcon entity={selected.entity} />
					<Stack spacing={0}>
						<Text className={classes.label}>
							{selected.entity?.title || selected.resource_title}
						</Text>
						{!isNil(integration) && (
							<Text className={classes.subLabel}>{`${startCase(
								integration.name
							)} / ... / ${
								selected.entity?.title || selected.resource_title
							}`}</Text>
						)}
					</Stack>
				</Group>
			</Group>
			{!isNil(collection) && (
				<Group spacing="xs" align="flex-start">
					<Group className={classes.leftCol} spacing="xs">
						<Icon name="folders" />
						Collection
					</Group>
					<Group
						className={cx(classes.rightCol, classes.cursorPointer)}
						spacing="xs"
						onClick={() => handleNavigateToCollection(collection)}
					>
						<SecodaEntityIcon entity={collection} />
						<Stack spacing={0}>
							<Text className={classes.label}>{collection.title}</Text>
						</Stack>
					</Group>
				</Group>
			)}
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="clock" />
					Updated time
				</Group>
				<Group className={classes.rightCol} spacing="xs" noWrap>
					<Stack spacing={0}>
						<Text className={classes.soleLabel}>
							{dayjs(selected.created_at).format('MMMM D, YYYY h:mm A')}
						</Text>
					</Stack>
				</Group>
			</Group>
		</Stack>
	);
}

export default ResourceAddRemoveCollectionContent;
