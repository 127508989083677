import type { Dispatch } from '@repo/secoda-editor';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import { wrappingInputRule } from 'prosemirror-inputrules';
import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import toggleWrap from '../commands/toggleWrap';
import isNodeActive from '../queries/isNodeActive';
import type { NodeOptions } from './Node';
import Node from './Node';

export default class Blockquote extends Node {
	get name() {
		return 'blockquote';
	}

	get schema(): NodeSpec {
		return {
			content: 'block+',
			group: 'block',
			defining: true,
			parseDOM: [{ tag: 'blockquote' }],
			toDOM: () => ['blockquote', 0],
		};
	}

	inputRules({ type }: NodeOptions) {
		return [wrappingInputRule(/^\s*>\s$/, type)];
	}

	commands({ type }: NodeOptions) {
		return () => toggleWrap(type);
	}

	keys({ type }: NodeOptions) {
		return {
			'Ctrl->': toggleWrap(type),
			'Mod-]': toggleWrap(type),
			'Shift-Enter': (state: EditorState, dispatch?: Dispatch) => {
				if (!isNodeActive(type)(state)) {
					return false;
				}

				const { tr, selection } = state;
				dispatch?.(tr.split(selection.to));
				return true;
			},
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		state.wrapBlock('> ', null, node, () => state.renderContent(node));
	}

	parseMarkdown() {
		return { block: 'blockquote' };
	}
}
