import { Group, Tooltip } from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { startCase } from 'lodash-es';
import type { SearchResult } from '../../../api';
import { compactNumber, formatNumber } from '../../../utils/numberUtils';

interface SearchResultCardSubtitleProps {
	entity: SearchResult;
}

export function SearchResultCardSubtitle({
	entity,
}: SearchResultCardSubtitleProps) {
	const isInternalViewsHidden = entity.internal_usage === 0;

	const internalViewsLabel = `${compactNumber(entity.internal_usage, 1)} views`;
	const internalViewsTooltip = `${formatNumber(entity.internal_usage)} views on Secoda in the past 90 days`;

	const isExternalViewsHidden = entity.external_usage === 0;

	const externalViewsLabel = `${compactNumber(entity.external_usage, 1)} ${entity.entity_type === EntityType.table ? 'queries' : 'views'}`;
	let externalViewsTooltip = '';
	if (entity.entity_type === EntityType.table) {
		externalViewsTooltip = `${formatNumber(entity.external_usage)} queries ran in the last 24 hours`;
	} else if (entity.entity_type === EntityType.dashboard) {
		externalViewsTooltip = `${formatNumber(entity.external_usage)} views on ${entity.search_metadata?.product}`;
	}

	const updatedAt = entity.external_updated_at || entity.updated_at;
	const lastUpdatedLabel = dayjs(updatedAt).isBefore(
		dayjs().subtract(7, 'days')
	)
		? `Updated ${dayjs(updatedAt).format('MMM D, YYYY')}`
		: dayjs(updatedAt).fromNow();

	return (
		<Group spacing={0}>
			<Text size="xs" color="text/secondary/default" data-testid="type">
				{startCase(entity.native_type || entity.entity_type)}
			</Text>
			{!isInternalViewsHidden && (
				<>
					<Text size="xs" color="text/secondary/default">
						&nbsp;•&nbsp;
					</Text>
					<Tooltip label={internalViewsTooltip}>
						<Text size="xs" color="text/secondary/default">
							{internalViewsLabel}
						</Text>
					</Tooltip>
				</>
			)}
			{!isExternalViewsHidden && (
				<>
					<Text size="xs" color="text/secondary/default">
						&nbsp;•&nbsp;
					</Text>
					<Tooltip label={externalViewsTooltip}>
						<Text size="xs" color="text/secondary/default">
							{externalViewsLabel}
						</Text>
					</Tooltip>
				</>
			)}
			<Text size="xs" color="text/secondary/default">
				&nbsp;•&nbsp;
			</Text>
			<Text size="xs" color="text/secondary/default">
				{lastUpdatedLabel}
			</Text>
		</Group>
	);
}
