import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { isNil } from 'lodash-es';
import { deleteParamsFromUrl } from '../../../utils/url';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import {
	NOTIFICATION_NAMESPACE,
	notificationUnreadCountQueryKeyFactory,
} from './constants';
import {
	invalidateNotificationListQuery,
	updateNotificationInList,
} from './utils';

export function useMarkNotificationAsRead() {
	const mutationFn = async ({
		id,
		all = false,
		events = '',
		status = true,
	}: {
		id?: string;
		all?: boolean;
		events?: string;
		status?: boolean;
	}) => {
		if (!all && isNil(id)) {
			throw new Error('Notification ID is required');
		}

		const path = all ? 'read_all' : 'read';
		const url = getEndpoints(NOTIFICATION_NAMESPACE).byPath([path]);
		const params = all ? { events } : { id, status };

		return apiClient.post(url, {}, { params });
	};

	return useMutation({
		mutationFn,
		onSuccess: (data, variables) => {
			if (variables.all === true) {
				deleteParamsFromUrl('id');
				invalidateNotificationListQuery();

				queryClient.invalidateQueries(
					notificationUnreadCountQueryKeyFactory.all()
				);
			} else if (!isNil(variables.id)) {
				updateNotificationInList(variables.id, {
					read: isNil(variables.status) ? true : variables.status,
				});

				const notificationCountQueryData:
					| AxiosResponse<{
							count: number;
					  }>
					| undefined = queryClient.getQueryData(
					notificationUnreadCountQueryKeyFactory.all()
				);

				if (!isNil(notificationCountQueryData)) {
					queryClient.setQueryData(
						notificationUnreadCountQueryKeyFactory.all(),
						{
							...notificationCountQueryData,
							data: {
								count: isNil(variables.status)
									? notificationCountQueryData.data.count - 1
									: notificationCountQueryData.data.count + 1,
							},
						}
					);
				}
			}
		},
	});
}
