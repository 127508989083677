import { useApiGetCatalogTreeIntegrations } from '@repo/api-codegen';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Icon } from '@repo/foundations';
import type React from 'react';
import { useCallback } from 'react';
import { useEntityBreadcrumbTeam } from '../../../hooks/useEntityBreadcrumbs';
import { useFeatureFlags } from '../../../utils/featureFlags';
import IntegrationLogo from '../../IntegrationLogo';
import { DataTreeLoader } from '../components/DataTreeLoader';
import { TreeNode } from '../components/TreeNode';
import { getEntityIconByType } from '../utils';
import type { NewCatalogTreeNode } from './utils';
import {
	getCatalogNodeChildrenNew,
	handleNavigateCatalogNodeNew,
	isIntegrationRootNode,
} from './utils';

interface CatalogTreeProps {
	teamId: string;
}

const getIcon = (node: NewCatalogTreeNode) => {
	if (node.type === 'integration') {
		return <IntegrationLogo integrationId={node.integration.id} size={16} />;
	}
	if (node.type === 'inferred_database') {
		return <Icon name="database" color="icon/primary/default" />;
	}

	if (node.type === 'inferred_schema') {
		return <Icon name="schema" color="icon/primary/default" />;
	}

	return getEntityIconByType(node.entity.entity_type);
};

const getLabel = (node: NewCatalogTreeNode) => {
	if (node.type === 'integration') {
		return node.integration.name;
	}

	if (node.type === 'inferred_database') {
		return node.database;
	}

	if (node.type === 'inferred_schema') {
		return node.schema;
	}

	return node.entity.title_cased ?? node.entity.title ?? 'Untitled';
};

function NewCatalogTree({ teamId }: CatalogTreeProps) {
	const { data: integrationsData, isLoading: isLoadingIntegrations } =
		useApiGetCatalogTreeIntegrations({
			queryParams: {
				team_id: teamId,
			},
		});

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	const navigateHandler = useNavigateHandler();
	const { customTeamSchemaMapping } = useFeatureFlags();

	const nodes: NewCatalogTreeNode[] | undefined =
		integrationsData
			?.filter((integration) => isIntegrationRootNode(integration))
			.map((integration) => ({
				id: integration.id,
				hasChildren: true,
				type: 'integration',
				teamId,
				integration,
			})) || [];

	const handleNavigate = useCallback(
		(event: React.MouseEvent, node: NewCatalogTreeNode) => {
			setTeamBreadcrumb(teamId);
			handleNavigateCatalogNodeNew(event, node, navigateHandler);
		},
		[navigateHandler, setTeamBreadcrumb, teamId]
	);

	const getCatalogNodeChildren = useCallback(
		(node: NewCatalogTreeNode, page?: number) =>
			getCatalogNodeChildrenNew(node, customTeamSchemaMapping, page),
		[customTeamSchemaMapping]
	);

	if (isLoadingIntegrations) {
		return <DataTreeLoader />;
	}

	return (
		<>
			{nodes.map((node) => (
				<TreeNode
					key={node.id}
					node={node}
					getIcon={getIcon}
					level={0}
					onClick={handleNavigate}
					getLabel={getLabel}
					onLoadChildren={getCatalogNodeChildren}
				/>
			))}
		</>
	);
}

export default NewCatalogTree;
