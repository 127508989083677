import { baseQueryHooksFactory } from '../../factories';
import type {
	IChangeRequest,
	INotification,
	INotificationSettings,
} from '../../types/models/notification';
import {
	notificationChangeRequestQueryKeyFactory,
	notificationQueryKeyFactory,
	notificationSettingsQueryKeyFactory,
} from './constants';
import { useDeleteAllNotifications } from './useDeleteAllNotifications';
import { useMarkNotificationAsRead } from './useMarkNotificationAsRead';
import { useUnreadNotificationCount } from './useUnreadNotificationCount';

const {
	useNotification,
	useNotificationInfiniteList,
	useNotificationList,
	useDeleteNotification,
} = baseQueryHooksFactory<INotification, 'notification'>(
	'notification',
	notificationQueryKeyFactory
);

export {
	useDeleteAllNotifications,
	useDeleteNotification,
	useMarkNotificationAsRead,
	useNotification,
	useNotificationInfiniteList,
	useNotificationList,
	useUnreadNotificationCount,
};

const { fetchNotificationSettingsList, updateNotificationSettings } =
	baseQueryHooksFactory<INotificationSettings, 'notificationSettings'>(
		'notificationSettings',
		notificationSettingsQueryKeyFactory
	);

export { fetchNotificationSettingsList, updateNotificationSettings };

const { createChangeRequest } = baseQueryHooksFactory<
	IChangeRequest,
	'changeRequest'
>('changeRequest', notificationChangeRequestQueryKeyFactory);

export { createChangeRequest };
