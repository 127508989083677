import type { EntityType } from '../enums/entityType';
import type { IMarketplaceIntegrationSpec } from '../models/marketplace';

export interface OAuth {
	base_url: string;
	scope?: string;
	redirect_uri?: string;
	response_type?: string;
	access_type?: string;
	label?: string;
	description?: string;
	group?: string;
	client_id?: string;
}

export type OptionalOAuth = OAuth;

export enum IntegrationCategory {
	WAREHOUSE = 'warehouse',
	DATABASE = 'database',
	DASHBOARD = 'dashboard',
	ORCHESTRATOR = 'orchestrator',
	COMMUNICATION = 'communication',
	TEST = 'test',
	CUSTOM = 'custom',
	STORAGE = 'storage',
}

type IntegrationGroup =
	| 'Data warehouses'
	| 'Databases'
	| 'Data visualization'
	| 'Data pipeline'
	| 'Data quality'
	| 'Productivity'
	| 'Data transformation tools'
	| 'Data storage'
	| 'Custom';

export interface BuiltinIntegrationSpec {
	showWhitelistDisclaimer?: boolean;
	name: string;
	type: string;
	type_label?: string;
	group: IntegrationGroup;
	description: string;
	account_id?: string;
	api_key?: string;
	cookie?: string;
	src: string;
	helpArticle: string;
	documentationMarkdown: string;
	oauth?: OAuth;
	groupSettings?: Record<
		string,
		{
			isOAuth?: boolean;
			testable?: boolean;
		}
	>;
	encrypted_fields?: string[];
	categories: IntegrationCategory[];
	entity_type?: EntityType;
	resource_type?: EntityType;
	hidden?: boolean;
	isBeta?: boolean;
	is_demo?: boolean;
	previewSupport?: boolean;
	queryBlockSupport?: boolean;
	schemaSupport?: boolean;
	supportDataAccessRequest?: boolean;
	supportGoogleIdentityAwareProxy?: boolean;
	supportPushMetadata?: boolean;
	disablePullMetadata?: boolean;
	testable?: boolean;
	skipSchemaSelect?: boolean;
	teams?: string[];
	sedaSupported?: boolean;
	secodaSupported?: boolean;
}

export type SlackIntegrationSpec = BuiltinIntegrationSpec & {
	oauth: OAuth;
};

export type IntegrationSpec =
	| {
			type: 'builtin';
			value: BuiltinIntegrationSpec;
	  }
	| { type: 'marketplace'; value: IMarketplaceIntegrationSpec };

export function integrationSpecName(spec: IntegrationSpec) {
	return spec.type === 'builtin'
		? spec.value.name
		: spec.value.versions[0].name;
}

export function integrationSpecIconSrc(spec: IntegrationSpec) {
	return spec.type === 'builtin'
		? spec.value.src
		: spec.value.versions[0].icon_url;
}
