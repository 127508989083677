import type {
	DefaultProps,
	Selectors,
	SpacingValue,
	SystemProp,
} from '@mantine/core';
import { Center, createStyles } from '@mantine/core';
import { colors } from '@repo/theme/primitives';
import type { ColorNames } from '@repo/theme/utils';
import type { TablerIconsProps } from '@tabler/icons-react';
import {
	IconActivity,
	IconActivityHeartbeat,
	IconAdjustmentsCog,
	IconAdjustmentsHorizontal,
	IconAlertCircle,
	IconAlertCircleFilled,
	IconAlertTriangle,
	IconAlertTriangleFilled,
	IconAlignCenter,
	IconAlignJustified,
	IconAlignLeft,
	IconAlignRight,
	IconArchive,
	IconArrowAutofitWidth,
	IconArrowBack,
	IconArrowBackUp,
	IconArrowBigDownLines,
	IconArrowBigUpLines,
	IconArrowDown,
	IconArrowLeft,
	IconArrowRight,
	IconArrowUp,
	IconArrowUpRight,
	IconArrowsDiagonal,
	IconArrowsDiagonalMinimize2,
	IconArrowsMaximize,
	IconArrowsMinimize,
	IconArrowsMoveVertical,
	IconArrowsRightLeft,
	IconArrowsSort,
	IconArrowsSplit2,
	IconAt,
	IconBarcode,
	IconBinary,
	IconBlockquote,
	IconBold,
	IconBolt,
	IconBook,
	IconBook2,
	IconBookmark,
	IconBraces,
	IconBrandChrome,
	IconBrandSlack,
	IconBriefcase2,
	IconBuilding,
	IconBulb,
	IconBusinessplan,
	IconCalculator,
	IconCalendar,
	IconCalendarCancel,
	IconCalendarEvent,
	IconCalendarTime,
	IconChartArcs,
	IconChartBar,
	IconChartHistogram,
	IconChartLine,
	IconCheck,
	IconCheckbox,
	IconChecklist,
	IconChevronDown,
	IconChevronLeft,
	IconChevronRight,
	IconChevronUp,
	IconChevronsRight,
	IconCircle,
	IconCircleArrowUp,
	IconCircleCheck,
	IconCircleCheckFilled,
	IconCircleChevronRight,
	IconCircleDashed,
	IconCircleFilled,
	IconCircleHalf2,
	IconCirclePlus,
	IconCircleX,
	IconClick,
	IconClipboard,
	IconClipboardCheck,
	IconClock,
	IconClockEdit,
	IconClockPlay,
	IconCode,
	IconCodeCircle2,
	IconCoin,
	IconColumnInsertLeft,
	IconColumnInsertRight,
	IconColumns,
	IconColumns2,
	IconCommand,
	IconCopy,
	IconCornerDownRightDouble,
	IconCornerLeftDown,
	IconCornerLeftUp,
	IconCrown,
	IconCsv,
	IconCursorText,
	IconDatabase,
	IconDiscount2Off,
	IconDiscountCheck,
	IconDiscountCheckFilled,
	IconDna,
	IconDoorEnter,
	IconDoorExit,
	IconDots,
	IconDotsVertical,
	IconDownload,
	IconEaseInControlPoint,
	IconEaseInOutControlPoints,
	IconEaseOutControlPoint,
	IconEdit,
	IconExclamationCircle,
	IconExclamationMark,
	IconExclamationMarkOff,
	IconExternalLink,
	IconEye,
	IconEyeOff,
	IconFile,
	IconFileAnalytics,
	IconFileDescription,
	IconFileText,
	IconFileZip,
	IconFilterMinus,
	IconFlag,
	IconFlame,
	IconFloatCenter,
	IconFloatLeft,
	IconFloatRight,
	IconFocusCentered,
	IconFolder,
	IconFolders,
	IconGhost2,
	IconGlobe,
	IconGraph,
	IconGripVertical,
	IconH1,
	IconH2,
	IconH3,
	IconH4,
	IconH5,
	IconH6,
	IconHandStop,
	IconHash,
	IconHeartbeat,
	IconHierarchy,
	IconHighlight,
	IconHistory,
	IconHome,
	IconHourglassEmpty,
	IconInbox,
	IconInfoCircle,
	IconItalic,
	IconJson,
	IconKey,
	IconLayersIntersect,
	IconLayersOff,
	IconLayersSubtract,
	IconLayout,
	IconLayoutGrid,
	IconLayoutRows,
	IconLayoutSidebar,
	IconLayoutSidebarLeftCollapse,
	IconLayoutSidebarRight,
	IconLayoutSidebarRightCollapse,
	IconLayoutSidebarRightExpand,
	IconLetterCase,
	IconLifebuoy,
	IconLineDashed,
	IconLink,
	IconList,
	IconListCheck,
	IconListDetails,
	IconListNumbers,
	IconListSearch,
	IconListTree,
	IconLoader,
	IconLoader2,
	IconLock,
	IconLockOpen,
	IconLogout,
	IconMail,
	IconMapPin,
	IconMaximize,
	IconMessage,
	IconMessageCircle2,
	IconMessageCircleQuestion,
	IconMessagePlus,
	IconMessages,
	IconMinus,
	IconMoodPlus,
	IconMoon,
	IconNewSection,
	IconNotification,
	IconNumbers,
	IconPageBreak,
	IconPaint,
	IconPaperclip,
	IconPdf,
	IconPencil,
	IconPercentage,
	IconPhoto,
	IconPinned,
	IconPlayerPause,
	IconPlayerPlay,
	IconPlayerPlayFilled,
	IconPlayerSkipForward,
	IconPlayerStop,
	IconPlugConnected,
	IconPlus,
	IconPointFilled,
	IconPresentationAnalytics,
	IconProgressDown,
	IconQuestionMark,
	IconQuote,
	IconRefresh,
	IconRegex,
	IconReload,
	IconRepeat,
	IconReplace,
	IconReportSearch,
	IconRestore,
	IconRibbonHealth,
	IconRotate2,
	IconRowInsertBottom,
	IconRowInsertTop,
	IconRulerMeasure,
	IconRun,
	IconSchema,
	IconSearch,
	IconSelect,
	IconSelector,
	IconSend,
	IconSeparatorHorizontal,
	IconServerBolt,
	IconSettings,
	IconShare2,
	IconShield,
	IconShieldCheck,
	IconShieldCheckFilled,
	IconShieldLock,
	IconSortAZ,
	IconSortAscending,
	IconSortDescending,
	IconSortZA,
	IconSparkles,
	IconSpeakerphone,
	IconSql,
	IconSquareCheck,
	IconSquareRoundedCheck,
	IconStack2,
	IconStar,
	IconStarFilled,
	IconStrikethrough,
	IconSun,
	IconSwitchHorizontal,
	IconTable,
	IconTableColumn,
	IconTableRow,
	IconTag,
	IconTags,
	IconTargetArrow,
	IconTextWrap,
	IconThumbDown,
	IconThumbDownFilled,
	IconThumbUp,
	IconThumbUpFilled,
	IconTicket,
	IconTie,
	IconToggleRight,
	IconTool,
	IconTrash,
	IconTrendingUp,
	IconUnlink,
	IconUpload,
	IconUser,
	IconUserCheck,
	IconUserCircle,
	IconUserPlus,
	IconUsers,
	IconUsersGroup,
	IconViewportNarrow,
	IconViewportWide,
	IconWand,
	IconWreckingBall,
	IconWriting,
	IconX,
	IconZoomQuestion,
	IconZoomReplace,
} from '@tabler/icons-react';
import { forwardRef } from 'react';
import {
	CustomFilterIcon,
	InfoCircleFilledIcon,
	MessageFilledIcon,
	PriorityHighIcon,
	PriorityLowIcon,
	PriorityMediumIcon,
	PriorityNoneIcon,
	SmallXIcon,
	SparklesFilledIcon,
} from './customIcons';

export type IconSizes = 'md' | 'lg';
export const IconSizeMap = {
	md: 20,
	lg: 48,
};
export const IconStrokeWidthMap = {
	// We use `2.25` for `md`, which maps to `1.5` in the figma designs. This is
	// due to scaling issues.
	md: 2.25,
	lg: 1.5,
};

export const TablerIconMap = {
	sidebar: IconLayoutSidebarLeftCollapse,
	PII: IconShieldCheckFilled,
	activity: IconActivity,
	activityHeartbeat: IconActivityHeartbeat,
	adjustmentsCog: IconAdjustmentsCog,
	adjustmentsHorizontal: IconAdjustmentsHorizontal,
	alertCircle: IconAlertCircle,
	alertCircleFilled: IconAlertCircleFilled,
	alertTriangle: IconAlertTriangle,
	alertTriangleFilled: IconAlertTriangleFilled,
	alignCenter: IconAlignCenter,
	alignJustified: IconAlignJustified,
	alignLeft: IconAlignLeft,
	alignRight: IconAlignRight,
	archive: IconArchive,
	arrowAutofitWidth: IconArrowAutofitWidth,
	arrowBack: IconArrowBack,
	arrowBackUp: IconArrowBackUp,
	arrowBigDownLines: IconArrowBigDownLines,
	arrowBigUpLines: IconArrowBigUpLines,
	arrowDown: IconArrowDown,
	arrowLeft: IconArrowLeft,
	arrowRight: IconArrowRight,
	arrowUp: IconArrowUp,
	arrowUpRight: IconArrowUpRight,
	arrowsDiagonal: IconArrowsDiagonal,
	arrowsDiagonalMinimize: IconArrowsDiagonalMinimize2,
	arrowsMinimize: IconArrowsMinimize,
	arrowsMaximize: IconArrowsMaximize,
	arrowsMoveVertical: IconArrowsMoveVertical,
	arrowsRightLeft: IconArrowsRightLeft,
	arrowsSort: IconArrowsSort,
	arrowsSplit: IconArrowsSplit2,
	at: IconAt,
	barCode: IconBarcode,
	binary: IconBinary,
	blockquote: IconBlockquote,
	bold: IconBold,
	bolt: IconBolt,
	book: IconBook,
	bookmark: IconBookmark,
	braces: IconBraces,
	brandChrome: IconBrandChrome,
	brandSlack: IconBrandSlack,
	briefcase: IconBriefcase2,
	building: IconBuilding,
	bulb: IconBulb,
	businessplan: IconBusinessplan,
	calculator: IconCalculator,
	calendar: IconCalendar,
	calendarCancel: IconCalendarCancel,
	calendarEvent: IconCalendarEvent,
	calendarTime: IconCalendarTime,
	chartArcs: IconChartArcs,
	chartBar: IconChartBar,
	chartHistogram: IconChartHistogram,
	chartLine: IconChartLine,
	check: IconCheck,
	checkbox: IconCheckbox,
	checklist: IconChecklist,
	chevronDown: IconChevronDown,
	chevronLeft: IconChevronLeft,
	chevronRight: IconChevronRight,
	chevronUp: IconChevronUp,
	chevronsRight: IconChevronsRight,
	circle: IconCircle,
	circleArrowUp: IconCircleArrowUp,
	circleCheck: IconCircleCheck,
	circleCheckFilled: IconCircleCheckFilled,
	circleChevronRight: IconCircleChevronRight,
	circleDashed: IconCircleDashed,
	circleFilled: IconCircleFilled,
	circleHalf: IconCircleHalf2,
	circlePlus: IconCirclePlus,
	circleX: IconCircleX,
	click: IconClick,
	clipboard: IconClipboard,
	clipboardCheck: IconClipboardCheck,
	clock: IconClock,
	clockEdit: IconClockEdit,
	clockPlay: IconClockPlay,
	code: IconCode,
	codeCircle2: IconCodeCircle2,
	coin: IconCoin,
	columnInsertLeft: IconColumnInsertLeft,
	columnInsertRight: IconColumnInsertRight,
	columns2: IconColumns2,
	columns: IconColumns,
	command: IconCommand,
	copy: IconCopy,
	cornerDownRightDouble: IconCornerDownRightDouble,
	cornerLeftDown: IconCornerLeftDown,
	cornerLeftUp: IconCornerLeftUp,
	crown: IconCrown,
	csv: IconCsv,
	cursorText: IconCursorText,
	database: IconDatabase,
	discountCheck: IconDiscountCheck,
	dna: IconDna,
	documentation: IconBook2,
	doorEnter: IconDoorEnter,
	doorExit: IconDoorExit,
	dots: IconDots,
	dotsVertical: IconDotsVertical,
	download: IconDownload,
	edit: IconEdit,
	exclamationCircle: IconExclamationCircle,
	exclamationMark: IconExclamationMark,
	exclamationMarkOff: IconExclamationMarkOff,
	externalLink: IconExternalLink,
	eye: IconEye,
	eyeOff: IconEyeOff,
	file: IconFile,
	fileAnalytics: IconFileAnalytics,
	fileDescription: IconFileDescription,
	fileText: IconFileText,
	fileZip: IconFileZip,
	filter: CustomFilterIcon,
	filterMinus: IconFilterMinus,
	flag: IconFlag,
	flame: IconFlame,
	floatCenter: IconFloatCenter,
	floatLeft: IconFloatLeft,
	floatRight: IconFloatRight,
	focusCentered: IconFocusCentered,
	folder: IconFolder,
	folders: IconFolders,
	ghost: IconGhost2,
	globe: IconGlobe,
	graph: IconGraph,
	gripVertical: IconGripVertical,
	h1: IconH1,
	h2: IconH2,
	h3: IconH3,
	h4: IconH4,
	h5: IconH5,
	h6: IconH6,
	handStop: IconHandStop,
	hash: IconHash,
	heartbeat: IconHeartbeat,
	hierarchy: IconHierarchy,
	highlight: IconHighlight,
	history: IconHistory,
	home: IconHome,
	hourglassEmpty: IconHourglassEmpty,
	inbox: IconInbox,
	infoCircle: IconInfoCircle,
	infoCircleFilled: InfoCircleFilledIcon,
	italic: IconItalic,
	json: IconJson,
	key: IconKey,
	layersOff: IconLayersOff,
	layersIntersect: IconLayersIntersect,
	layersSubtract: IconLayersSubtract,
	layout: IconLayout,
	layoutGrid: IconLayoutGrid,
	layoutRows: IconLayoutRows,
	layoutSideBarLeftCollapse: IconLayoutSidebarLeftCollapse,
	layoutSidebar: IconLayoutSidebar,
	layoutSidebarRight: IconLayoutSidebarRight,
	layoutSidebarRightCollapse: IconLayoutSidebarRightCollapse,
	layoutSidebarRightExpand: IconLayoutSidebarRightExpand,
	letterCase: IconLetterCase,
	lifebuoy: IconLifebuoy,
	lineDashed: IconLineDashed,
	link: IconLink,
	list: IconList,
	listCheck: IconListCheck,
	listDetails: IconListDetails,
	listNumbers: IconListNumbers,
	listSearch: IconListSearch,
	listTree: IconListTree,
	loader: IconLoader,
	loader2: IconLoader2,
	lock: IconLock,
	lockOpen: IconLockOpen,
	logout: IconLogout,
	mail: IconMail,
	mapPin: IconMapPin,
	maximize: IconMaximize,
	message: IconMessage,
	messageCircle: IconMessageCircle2,
	messageCircleQuestion: IconMessageCircleQuestion,
	messageFilled: MessageFilledIcon,
	messagePlus: IconMessagePlus,
	messages: IconMessages,
	minus: IconMinus,
	moodPlus: IconMoodPlus,
	moon: IconMoon,
	newSection: IconNewSection,
	notPII: IconShield,
	notVerified: IconDiscount2Off,
	notification: IconNotification,
	numbers: IconNumbers,
	pageBreak: IconPageBreak,
	paint: IconPaint,
	paperclip: IconPaperclip,
	pdf: IconPdf,
	pencil: IconPencil,
	percentage: IconPercentage,
	photo: IconPhoto,
	pinned: IconPinned,
	playerPause: IconPlayerPause,
	playerPlay: IconPlayerPlay,
	playerPlayFilled: IconPlayerPlayFilled,
	playerSkipForward: IconPlayerSkipForward,
	playerStop: IconPlayerStop,
	plugConnected: IconPlugConnected,
	plus: IconPlus,
	pointFilled: IconPointFilled,
	presentationAnalytics: IconPresentationAnalytics,
	priorityHigh: PriorityHighIcon,
	priorityLow: PriorityLowIcon,
	priorityMedium: PriorityMediumIcon,
	priorityNone: PriorityNoneIcon,
	progressDown: IconProgressDown,
	questionMark: IconQuestionMark,
	quote: IconQuote,
	refresh: IconRefresh,
	regex: IconRegex,
	reload: IconReload,
	repeat: IconRepeat,
	replace: IconReplace,
	reportSearch: IconReportSearch,
	restore: IconRestore,
	ribbonHealth: IconRibbonHealth,
	rotate: IconRotate2,
	rowInsertBottom: IconRowInsertBottom,
	rowInsertTop: IconRowInsertTop,
	rulerMeasure: IconRulerMeasure,
	run: IconRun,
	schema: IconSchema,
	search: IconSearch,
	select: IconSelect,
	selector: IconSelector,
	send: IconSend,
	separatorHorizontal: IconSeparatorHorizontal,
	serverBolt: IconServerBolt,
	settings: IconSettings,
	share: IconShare2,
	shieldCheck: IconShieldCheck,
	shieldCheckFilled: IconShieldCheckFilled,
	shieldLockUnfilled: IconShieldLock,
	shield: IconShield,
	smallX: SmallXIcon,
	sortAZ: IconSortAZ,
	sortZA: IconSortZA,
	sparkles: IconSparkles,
	sparklesFilled: SparklesFilledIcon,
	speakerphone: IconSpeakerphone,
	sql: IconSql,
	squareCheck: IconSquareCheck,
	squareRoundedCheck: IconSquareRoundedCheck,
	sortAscending: IconSortAscending,
	sortDescending: IconSortDescending,
	stack: IconStack2,
	star: IconStar,
	starFilled: IconStarFilled,
	strikethrough: IconStrikethrough,
	sun: IconSun,
	switchHorizontal: IconSwitchHorizontal,
	table: IconTable,
	tableColumn: IconTableColumn,
	tableRow: IconTableRow,
	tag: IconTag,
	tags: IconTags,
	targetArrow: IconTargetArrow,
	textWrap: IconTextWrap,
	thumbDown: IconThumbDown,
	thumbDownFilled: IconThumbDownFilled,
	thumbUp: IconThumbUp,
	thumbUpFilled: IconThumbUpFilled,
	ticket: IconTicket,
	tie: IconTie,
	toggleRight: IconToggleRight,
	tool: IconTool,
	trash: IconTrash,
	trendingUp: IconTrendingUp,
	unlink: IconUnlink,
	upload: IconUpload,
	user: IconUser,
	userCheck: IconUserCheck,
	userCircle: IconUserCircle,
	userPlus: IconUserPlus,
	users: IconUsers,
	usersGroup: IconUsersGroup,
	verified: IconDiscountCheckFilled,
	verifiedUnfilled: IconDiscountCheck,
	viewportNarrow: IconViewportNarrow,
	viewportWide: IconViewportWide,
	wand: IconWand,
	wreckingBall: IconWreckingBall,
	writing: IconWriting,
	x: IconX,
	zoomQuestion: IconZoomQuestion,
	zoomReplace: IconZoomReplace,
	easeInControlPoint: IconEaseInControlPoint,
	easeOutControlPoint: IconEaseOutControlPoint,
	easeInOutControlPoints: IconEaseInOutControlPoints,
} as const;

export type IconNames = keyof typeof TablerIconMap;
export const allIconNames = Object.keys(TablerIconMap) as IconNames[];

function safeGetColor(
	getColor: (c: ColorNames) => string,
	color?: ColorNames
): string | undefined {
	if (!getColor) {
		// Something weird is happening here. `theme.other.getColor` is sometimes
		// undefined, despite the typesystem, so we guard against this case.
		return 'black';
	}

	if (!color) {
		// If no color is passed we return undefined so that we can have the
		// component rely on CSS colors
		return undefined;
	}

	// Sometimes the type system appers to not catch instances when were are
	// passing an invalid color here.
	try {
		const colorValue = getColor(color);
		return colorValue;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn(error);
		return colors.gray[8];
	}
}

type IIconStyleProps = {
	fillColor?: ColorNames;
};

const useStyles = createStyles((theme, { fillColor }: IIconStyleProps) => ({
	icon: {
		'& path': fillColor
			? {
					fill: theme.other.getColor(fillColor),
				}
			: {},
	},
}));

type IconStylesNames = Selectors<typeof useStyles>;

export interface IconProps
	extends Omit<
			DefaultProps<IconStylesNames, TablerIconsProps>,
			'display' | 'opacity'
		>,
		IIconStyleProps {
	/** Unique name of the icon. */
	name: IconNames;
	/** The color of the icon. Defaults the `icon/primary/default` */
	color?: ColorNames;
	/** The height and width of the icon. Defaults the 20px */
	size?: IconSizes;
	iconPadding?: SystemProp<SpacingValue>;
	iconWidth?: SystemProp<SpacingValue>;
	iconHeight?: SystemProp<SpacingValue>;
}

export const Icon = forwardRef<HTMLDivElement, IconProps>(
	(
		{
			iconWidth,
			iconHeight,
			iconPadding = 2,
			classNames,
			styles,
			className,
			name,
			color,
			fillColor,
			size,
			...others
		},
		ref
	) => {
		const { classes, cx, theme } = useStyles(
			{ fillColor },
			{
				name: 'Icon',
				classNames,
				styles,
			}
		);

		const IconToRender = TablerIconMap[name];

		const fallbackColor = colors.gray[8];
		const iconColorFromProp = safeGetColor(theme.other.getColor, color);

		// This component consists of an box around a component.
		const iconSize = IconSizeMap[size ?? 'md'];
		const strokeWidth = IconStrokeWidthMap[size ?? 'md'];

		return (
			<Center
				p={iconPadding}
				w={iconWidth ?? iconSize}
				h={iconHeight ?? iconSize}
				ref={ref}
			>
				<IconToRender
					size={iconSize - 4}
					color={iconColorFromProp}
					className={cx(classes.icon, className, { color: fallbackColor })}
					strokeWidth={strokeWidth}
					{...others}
				/>
			</Center>
		);
	}
);

Icon.displayName = 'Icon';
