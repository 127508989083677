import axios from 'axios';
import { authHeaders, getEndpoints } from '../../common';
import { createQueryKeys } from '../../factories';

export const INTEGRATION_SLACK_NAMESPACE = ['integration', 'slack'];

export const integrationSlackQueryKeyFactory = createQueryKeys(
	INTEGRATION_SLACK_NAMESPACE
);

export async function channelTest(channel: string) {
	return axios.get(
		getEndpoints(INTEGRATION_SLACK_NAMESPACE).byPath(['channel_test']),
		{
			...authHeaders(),
			params: {
				channel,
			},
		}
	);
}
