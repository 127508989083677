import { createMockableHook } from '@repo/common/utils/createMockableHook';
import { useMemo } from 'react';
import { useAuthUser } from '../api';
import { useFeatureAccess } from '../api/hooks/workspace/useFeatureAccess';

function useAiEnabledInternal() {
	const { workspace } = useAuthUser();
	const { aiAccess, isLoading } = useFeatureAccess();

	const data = useMemo(
		() => ({
			needsUpgrade: !isLoading && !aiAccess, // if workspace does not have access to AI
			enableAi: !!aiAccess && !!workspace?.ai_search, // if workspace as access to AI and it's turned on
			isLoading,
		}),
		[aiAccess, isLoading, workspace?.ai_search]
	);

	return data;
}

export const [useAiEnabled, MockUseAiEnabled] =
	createMockableHook(useAiEnabledInternal);
