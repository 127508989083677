import { Box } from '@mantine/core';
import '../Loader.css';

interface OvalLoaderProps {
	size?: 'xs' | 'sm' | 'md' | 'lg';
}

export function OvalLoader({ size = 'sm' }: OvalLoaderProps) {
	return (
		<Box
			component="span"
			className={`${size === 'lg' ? 'ovalLoader--lg' : 'ovalLoader'}`}
		/>
	);
}
