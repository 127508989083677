import { ActionIcon, Box, Flex } from '@mantine/core';
import type { Filter } from '@repo/api-codegen';
import { IconEmoji } from '@repo/common/components/IconEmoji/IconEmoji';
import { EntityType } from '@repo/common/enums/entityType';
import { Icon, Text } from '@repo/foundations';
import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useCatalogInfiniteList } from '../../../api/hooks/resourceCatalog';
import { useParamsIdSuffixUuid } from '../../../utils/hook/utils';
import { SmallLoadingSpinner } from '../../LoadingSpinner';
import { TREE_INDENT } from '../constants';
import type { CommonEntityTreeNode } from '../utils';
import { LoadMore } from './LoadMore';
import { useTreeNodeStyle } from './TreeNode.styles';

const defaultGetIcon = (node: CommonEntityTreeNode) => {
	if (node.entity.icon) {
		return <IconEmoji value={node.entity.icon} />;
	}
	if (node.entity.entity_type === EntityType.document) {
		return <Icon name="file" color="icon/primary/default" />;
	}
	if (node.entity.entity_type === EntityType.dictionary_term) {
		return <Icon name="book" color="icon/primary/default" />;
	}
	if (node.entity.entity_type === EntityType.glossary) {
		return <Icon name="book" color="icon/primary/default" />;
	}
	return null;
};

export type TreeNodeBase = {
	hasChildren: boolean;
	id: string;
};

export interface EntityTreeNodeProps {
	node: CommonEntityTreeNode;
	level: number;
	getLabel: (node: CommonEntityTreeNode) => React.ReactNode;
	getIcon?: (node: CommonEntityTreeNode) => React.ReactNode;
	onClick: (e: React.MouseEvent, node: CommonEntityTreeNode) => void;
	childFilterOperands?: (node: CommonEntityTreeNode) => Filter[];
}

export function TreeNodeDisplay({
	node,
	level,
	getLabel,
	getIcon,
	onClick,
	open,
	isFetchingChildren,
	toggle,
	isSelected,
}: {
	node: CommonEntityTreeNode;
	level: number;
	getLabel: (node: CommonEntityTreeNode) => React.ReactNode;
	getIcon?: (node: CommonEntityTreeNode) => React.ReactNode;
	onClick: (e: React.MouseEvent, node: CommonEntityTreeNode) => void;
	open: boolean;
	isFetchingChildren: boolean;
	toggle: (e: React.MouseEvent) => void;
	isSelected: boolean;
}) {
	const { classes, theme } = useTreeNodeStyle({
		open: open,
		selected: isSelected,
	});

	return (
		<Box
			role="button"
			className={classes.button}
			pl={(level + 1) * TREE_INDENT - theme.other.space[1]}
			onClick={(e) => onClick(e, node)}
			data-testid={`tree-node-${node.id}`}
		>
			<Flex direction="row" align="center" justify="space-between" w="100%">
				<Flex align="center" style={{ flex: '0 0 auto' }}>
					<Box className={classes.toggleContainer}>
						{node.hasChildren && !isFetchingChildren && (
							<ActionIcon
								onClick={toggle}
								data-testid={`tree-node-${node.id}-collapsable`}
								className={classes.chevronButton}
							>
								<Icon name="chevronRight" className={classes.chevron} />
							</ActionIcon>
						)}
						{node.hasChildren && isFetchingChildren && (
							<SmallLoadingSpinner size="xs" />
						)}
					</Box>
					<Flex align="center">
						<Flex
							mx={theme.other.space[1]}
							w={theme.other.space[5]}
							justify="center"
						>
							{getIcon ? getIcon(node) : defaultGetIcon(node)}
						</Flex>
						<Text
							size="sm"
							weight="semibold"
							color="text/primary/default"
							lineClamp={1}
							style={{
								textOverflow: 'ellipsis',
							}}
						>
							{getLabel(node)}
						</Text>
					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
}

/**
 * This component is used to render an entity tree. For each node it will do a catalog query to get the children
 * and render them
 */
export function EntityTreeNode({
	node,
	level,
	getLabel,
	getIcon,
	onClick,
	childFilterOperands,
}: EntityTreeNodeProps) {
	const paramsId = useParamsIdSuffixUuid();
	const [open, setOpen] = useState(false);

	const filters = useMemo(() => {
		if (childFilterOperands) {
			return childFilterOperands(node);
		}
		return [
			{
				field: 'entity_type',
				operator: 'exact',
				value: node.entity?.entity_type,
			},
			{
				field: 'parent_id',
				operator: 'exact',
				value: node.entity?.id,
			},
		];
	}, [childFilterOperands, node]);

	const {
		data: results,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
	} = useCatalogInfiniteList({
		filters: {
			filter: {
				operator: 'and',
				operands: filters,
			} as Filter,
			page_size: 50,
			sort: {
				field: 'title',
				order: 'asc',
			},
			prefetch_children_count: true,
		},
		options: {
			enabled: open,
		},
	});

	const isSelected = useMemo(() => {
		if ('entity' in node) {
			return paramsId === node.id;
		}
		return false;
	}, [node, paramsId]);

	const toggle = useCallback(
		(e: React.MouseEvent) => {
			// Always stop propagation. The parent will cause a navigation and completely re-render the page.
			e.stopPropagation();
			setOpen(!open);
		},
		[open]
	);

	const shouldRenderLoadMore = open && (hasNextPage || isFetchingNextPage);

	return (
		<>
			<TreeNodeDisplay
				node={node}
				level={level}
				getLabel={getLabel}
				getIcon={getIcon}
				onClick={onClick}
				open={open}
				isFetchingChildren={isFetching}
				toggle={toggle}
				isSelected={isSelected}
			/>
			{open &&
				results?.pages
					.flatMap((page) => page)
					.map((entity) => (
						<EntityTreeNode
							key={entity.id}
							node={{
								entity: entity,
								id: entity.id,
								hasChildren:
									Boolean(entity.has_child_of_same_type) ||
									entity.children_count > 0,
							}}
							level={level + 1}
							onClick={onClick}
							getLabel={getLabel}
							childFilterOperands={childFilterOperands}
							getIcon={getIcon}
						/>
					))}
			{shouldRenderLoadMore && (
				<LoadMore
					level={level + 1}
					onClick={fetchNextPage}
					isLoading={isFetchingNextPage}
				/>
			)}
		</>
	);
}
