import type { ESQueryModel, QueryListOut } from '@repo/api-codegen';
import { useApiGetEntityQueries } from '@repo/api-codegen';
import { useMemo } from 'react';
import type { IBaseModel } from '../../api';
import { FilterOptionType } from '../Filter';
import {
	AverageRunTimeRender,
	ReadOnlyQueryFrequentUsersRender,
	SQLRender,
	TotalRunsRender,
	TotalRunTimeRender,
} from '../TableV2/render';
import type { ExtendedDataTableColumn } from '../TableV2/types.ts';

interface QueryFilters {
	search_term?: string;
	filter?: unknown;
	sort?: string;
}

export const useColumns = (): ExtendedDataTableColumn<
	ESQueryModel & IBaseModel
>[] =>
	useMemo(
		() => [
			{
				accessor: 'sql',
				title: 'SQL',
				render: SQLRender,
				sortable: false,
			},
			{
				accessor: 'frequent_users',
				title: 'Users',
				render: ReadOnlyQueryFrequentUsersRender,
				sortable: false,
				filterOptionType: FilterOptionType.OWNERS,
			},
			{
				accessor: 'average_runtime',
				title: 'Average runtime',
				render: AverageRunTimeRender,
				sortable: true,
				textAlignment: 'right',
			},
			{
				accessor: 'total_runtime',
				title: 'Total runtime',
				render: TotalRunTimeRender,
				sortable: true,
				textAlignment: 'right',
				filterOptionType: FilterOptionType.RUN_DATE,
			},
			{
				accessor: 'total_runs',
				title: 'Total runs',
				render: TotalRunsRender,
				sortable: true,
				textAlignment: 'right',
			},
		],
		[]
	);

export const useActions = () => [];

export function useEntityQueryList(
	entityId: string,
	onSuccess: (data: QueryListOut) => void
) {
	return function useList({
		page = 1,
		filters = {} as QueryFilters,
		options = {},
	}) {
		return useApiGetEntityQueries(
			{
				pathParams: {
					entityId,
				},
				queryParams: {
					page,
					search_term: filters.search_term,
					filters: filters.filter ? JSON.stringify(filters.filter) : null,
					sort_by: filters.sort ? JSON.stringify(filters.sort) : '{}',
					page_size: 20,
				},
			},
			{
				...options,
				onSuccess,
			}
		);
	};
}
