import { Skeleton } from '@mantine/core';
import { MessageBody } from './MessageBody';

export function MessageGroupSkeleton() {
	return (
		<>
			<MessageBody avatar={<Skeleton height={32} circle />}>
				<Skeleton width="100%" height={40} radius="xl" mt="xs" />
			</MessageBody>
			<MessageBody avatar={<Skeleton height={32} circle />}>
				<Skeleton width="100%" height={40} radius="xl" mt="xs" />
			</MessageBody>
		</>
	);
}
