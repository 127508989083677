import { useFeatureFlags } from '../../utils/featureFlags/useFeatureFlags';
import WorkspaceActivityLog from '../ActivityLog/WorkspaceActivityLog';
import AuditLog from './AuditLog';

function AuditLogWrapper() {
	const { feActivityLog } = useFeatureFlags();

	if (feActivityLog) {
		return <AuditLog />;
	}

	return <WorkspaceActivityLog />;
}

export default AuditLogWrapper;
