import { createStyles, Modal } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import type { ReactNode } from 'react';
import { lineageStore } from './store';

interface ILineageGraphContainerProps {
	children: ReactNode;
}

const useStyles = createStyles((theme) => ({
	body: {
		height: '100vh',
	},
}));

function LineageGraphContainer({ children }: ILineageGraphContainerProps) {
	const { classes } = useStyles();

	const exitFullscreen = () => {
		lineageStore.setIsFullscreen(false);
	};

	if (lineageStore.isFullscreen) {
		return (
			<Modal
				classNames={{ body: classes.body }}
				opened
				onClose={exitFullscreen}
				fullScreen
				withCloseButton={false}
			>
				{children}
			</Modal>
		);
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}

const ObservedLineageGraphContainer = observer(LineageGraphContainer);
export default ObservedLineageGraphContainer;
