import { createStyles, Group, UnstyledButton } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { type ForwardedRef, forwardRef } from 'react';
import { FilterOperator } from '../types';
import { FilterTargetLabel } from './FilterTargetLabel';
import type { OperatorMenuProps } from './OperatorMenu';
import { OperatorMenu } from './OperatorMenu';

const useStyles = createStyles(
	(theme, { hasClear }: { hasClear: boolean }) => ({
		root: {
			border: `1px solid ${theme.other.getColor('border/primary/default')}`,
			borderRadius: theme.radius.sm,
			padding: `0 0 0 ${theme.spacing.sm}`,
			gap: 0,

			button: {
				outline: 'none !important',
			},
		},
		detailedRoot: {
			gap: 0,
		},
		detailedLabel: {
			paddingRight: theme.spacing['2xs'],
		},
		operatorMenu: {
			padding: `${theme.spacing['5xs']} ${theme.spacing['2xs']}`,
			lineHeight: theme.spacing.lg,
		},
		target: {
			backgroundColor: theme.other.getColor('fill/primary/default'),
			padding: `${theme.spacing['5xs']} ${theme.spacing['2xs']}`,
			borderTopRightRadius: !hasClear ? theme.radius.sm : undefined,
			borderBottomRightRadius: !hasClear ? theme.radius.sm : undefined,

			'&:hover, &:focus': {
				backgroundColor: theme.other.getColor('fill/primary/hover'),
			},

			'&:active': {
				backgroundColor: theme.other.getColor('fill/primary/active'),
			},
		},
		detailedValue: {
			fontSize: theme.fontSizes.xs,
			color: theme.other.getColor('text/primary/default'),
			lineHeight: theme.spacing.lg,
		},
		clearButton: {
			padding: `${theme.spacing['5xs']} ${theme.spacing.xs} ${theme.spacing['5xs']} 0`,
			borderTopRightRadius: theme.radius.sm,
			borderBottomRightRadius: theme.radius.sm,
			backgroundColor: theme.other.getColor('fill/primary/default'),
			color: theme.other.getColor('icon/primary/default'),

			'&:hover, &:focus': {
				backgroundColor: theme.other.getColor('fill/primary/hover'),
				color: theme.other.getColor('icon/primary/hover'),
			},

			'&:active': {
				backgroundColor: theme.other.getColor('fill/primary/active'),
				color: theme.other.getColor('icon/primary/active'),
			},
		},
	})
);

export interface FilterTargetDetailedProps {
	label: string;
	valueLabel: string | null;
	isMenuOpen?: boolean;
	onClear?: () => void;
	onToggle: () => void;
	operatorMenuProps: Omit<OperatorMenuProps, 'className'>;
}

export const FilterTargetDetailed = forwardRef(
	(
		{
			valueLabel,
			isMenuOpen,
			label,
			onClear,
			onToggle,
			operatorMenuProps,
		}: FilterTargetDetailedProps,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const { classes } = useStyles({
			hasClear: !!onClear,
		});

		const canShowTarget = operatorMenuProps?.selected
			? ![FilterOperator.isSet, FilterOperator.isNotSet].includes(
					operatorMenuProps.selected
				)
			: true;

		return (
			<Group data-testid="filter-target-detailed" className={classes.root}>
				<Group className={classes.detailedRoot}>
					<FilterTargetLabel
						label={label}
						showChevron={false}
						className={classes.detailedLabel}
					/>
					<OperatorMenu
						{...operatorMenuProps}
						className={classes.operatorMenu}
					/>
					{canShowTarget && (
						<UnstyledButton
							ref={ref}
							onClick={onToggle}
							className={classes.target}
						>
							<Group spacing="2xs">
								<Text className={classes.detailedValue}>
									{valueLabel ?? '(no selection)'}
								</Text>
								{!onClear && (
									<Icon
										name={isMenuOpen ? 'chevronUp' : 'chevronDown'}
										iconPadding={0}
									/>
								)}
							</Group>
						</UnstyledButton>
					)}
				</Group>
				{!!onClear && (
					<UnstyledButton
						onClick={onClear}
						className={classes.clearButton}
						data-testid="filter-target-remove"
					>
						<Icon name="x" />
					</UnstyledButton>
				)}
			</Group>
		);
	}
);
FilterTargetDetailed.displayName = 'FilterTargetDetailed';
