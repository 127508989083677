import { Divider, Group, Stack } from '@mantine/core';
import {
	AvatarSkeleton,
	Text,
	TextSkeleton,
	TitleSkeleton,
} from '@repo/foundations';
import { ActivityLogV2Skeleton } from '../../ActivityLogV2/ActivityLogV2Skeleton';

export function DataAccessRequestContentSkeleton() {
	return (
		<Stack spacing="xl">
			<Stack spacing="xs">
				<TitleSkeleton width={200} />
				<TextSkeleton width={200} />
			</Stack>
			<Stack spacing="xs">
				<Group spacing="xs" noWrap align="baseline">
					<TextSkeleton width={128} />
					<TextSkeleton width={50} />
				</Group>
				<Group spacing="xs" noWrap align="baseline">
					<TextSkeleton width={128} />
					<TextSkeleton width={50} />
				</Group>
				<Group spacing="xs" noWrap align="center">
					<TextSkeleton width={128} />
					<Group spacing="2xs" noWrap>
						<AvatarSkeleton size="xs" />
						<TextSkeleton width={128} />
					</Group>
				</Group>
			</Stack>
			<Divider />
			<Stack spacing="xl">
				<Text size="md" fw={500}>
					Activity
				</Text>
				<ActivityLogV2Skeleton />
			</Stack>
		</Stack>
	);
}
