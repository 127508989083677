import {
	Box,
	createStyles,
	Popover,
	Skeleton,
	UnstyledButton,
} from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { isEmpty } from 'lib0/object';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useAuthUser } from '../../../../api';
import { isViewer } from '../../../../utils/authorization/roles';
import { sideBarStore } from '../store';
import { workspaceSelectorStore } from './store';
import { WorkspaceLogo } from './WorkspaceLogo';
import { WorkspaceSelectorDropdown } from './WorkspaceSelectorDropdown';

interface WorkspaceSelectorStyleProps {
	collapsed: boolean;
}

const useStyles = createStyles(
	(theme, { collapsed }: WorkspaceSelectorStyleProps) => ({
		dropdown: {
			width: theme.other.space[80],
			padding: 0,
			borderRadius: theme.other.borderRadius.md,
			boxShadow: theme.shadows.md,
		},
		targetButton: {
			display: 'flex',
			flex: '0 1 auto',
			gap: theme.spacing['2xs'],
			alignItems: 'center',
			borderRadius: theme.other.borderRadius.sm,

			height: theme.other.space[7],
			maxWidth: collapsed ? 'unset' : '100%',
			padding: `0 ${theme.other.space[1]}px`,

			backgroundColor: theme.other.getColor('fill/transparent/default'),
			':hover': {
				backgroundColor: theme.other.getColor('fill/transparent/hover'),
			},
			':active': {
				backgroundColor: theme.other.getColor('fill/transparent/selected'),
			},
		},
		targetButtonSkeleton: {
			height: theme.other.space[7],
			maxWidth: `calc(100% - ${theme.other.space[7]}px)`,
		},
	})
);

export const WorkspaceSelector = observer(() => {
	const { user } = useAuthUser();
	const { classes, theme } = useStyles({
		collapsed: sideBarStore.collapsed || false,
	});
	const [opened, setOpened] = useState(false);

	useEffect(() => {
		workspaceSelectorStore.load();
	}, []);

	const disabled = !workspaceSelectorStore.account;

	const buttonMaxWidth = sideBarStore.collapsed
		? 'unset'
		: `calc(100% - ${theme.other.space[isViewer(user) ? 16 : 8]}px)`;

	if (!workspaceSelectorStore.activeWorkspace) {
		return (
			<Skeleton animate={false} className={classes.targetButtonSkeleton} />
		);
	}

	return (
		<Popover
			classNames={{
				dropdown: classes.dropdown,
			}}
			position="bottom-start"
			shadow="md"
			withArrow={false}
			offset={{ mainAxis: 2 }}
			transitionProps={{
				transition: 'fade',
			}}
			disabled={disabled}
			onClose={() => setOpened(false)}
			opened={opened}
		>
			<Popover.Target>
				<UnstyledButton
					className={classes.targetButton}
					data-testid="workspace-selector-target-button"
					onClick={() => setOpened(!opened)}
					maw={buttonMaxWidth}
				>
					<Box style={{ display: 'flex', flex: '0 0 auto' }}>
						<WorkspaceLogo
							logo={
								!isEmpty(workspaceSelectorStore.activeWorkspace.logo)
									? (workspaceSelectorStore.activeWorkspace.logo as string)
									: '/images/logos/cfsecoda.webp'
							}
							size={20}
						/>
					</Box>
					{!sideBarStore.collapsed && (
						<Box
							style={{
								display: 'flex',
								flex: '0 1 auto',
								minWidth: 0,
							}}
						>
							<Text size="sm" weight="bold" truncate>
								{workspaceSelectorStore.activeWorkspace.name}
							</Text>
							<Icon name="chevronDown" color="icon/secondary/default" />
						</Box>
					)}
				</UnstyledButton>
			</Popover.Target>
			<Popover.Dropdown>
				<WorkspaceSelectorDropdown
					workspaces={workspaceSelectorStore.account?.workspaces || []}
					user={user}
					setOpened={setOpened}
				/>
			</Popover.Dropdown>
		</Popover>
	);
});
