/**
 * Hook to manage feature access flags for the workspace.
 * Features are stored in local storage and synced with the server.
 * For on-premise installations, all features are automatically enabled.
 */
export function useFeatureAccess() {
	// Convert snake_case to camelCase for consistent API
	return {
		policiesAccess: true,
		monitoringAccess: true,
		automationsAccess: true,
		aiAccess: true,
		apiAccess: true,
		analyticsAccess: true,
		samlAccess: true,
		tunnelAccess: true,
		groupAccess: true,
		dqsAccess: true,
		isLoading: false,
	};
}
